import "../components/swalStyle/customStyle.css";
import { getInstance } from "./base_api";
import Swal from "sweetalert2";

const instance = getInstance();

export const getDataAbout = async (queryparams, language = "en") => {
    try {
        const headers = {
            language: language // Set the language here
        };
        const response = await instance.get(`/api/landing/aboutus`, {
            headers: headers,
            params: { ...queryparams }
        });
        if (response.status === 200) {
            return response;
        } else {
            return null;
        }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: error,
            confirmButtonText: "OK",
        }).then((result) => {});
    }
};