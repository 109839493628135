import React, { useEffect } from 'react';
import './ShareSocial.css'
import facebookIcon from '../../assets/images/ic-facebook.png';

const useFacebookSDK = () => {
  // useEffect(() => {
  //   if (!window.FB) {
  //     window.fbAsyncInit = function() {
  //       window.FB.init({
  //         appId: 'your-app-id', // Replace with your Facebook app id
  //         cookie: true,
  //         xfbml: true,
  //         version: 'v13.0'
  //       });
  //     };

  //     (function(d, s, id) {
  //       var js, fjs = d.getElementsByTagName(s)[0];
  //       if (d.getElementById(id)) { return; }
  //       js = d.createElement(s); js.id = id;
  //       js.src = "https://connect.facebook.net/en_US/sdk.js";
  //       fjs.parentNode.insertBefore(js, fjs);
  //     }(document, 'script', 'facebook-jssdk'));
  //   }
  // }, []);
};

const FacebookShare = ({ url }) => {
    // useFacebookSDK();

    // const shareOnFacebook = () => {
    //     if (window.FB) {
    //         window.FB.ui({
    //             method: 'share',
    //             href: url,
    //         }, function(response) {
    //             if (response && !response.error_message) {
    //                 console.log('Posting completed.');
    //             } else {
    //                 console.log('Error while posting.');
    //             }
    //         });
    //         } else {
    //             console.log('Facebook SDK not initialized.');
    //         }
    // };

// return <button className='btn-share' onClick={shareOnFacebook}>
//             <img src={facebookIcon} className='ic-social' alt='ic-fb' />
//     </button>;
// https://facebook.com/sharer/sharer.php?kid_directed_site=0&u=https://dev-asp.verkoffice.com/api/shares/e428aab325a811b34a7a3b273deb3241
// `https://facebook.com/sharer/sharer.php?kid_directed_site=0&u={encodeURIComponent(url)}`

  return <a className='btn-share' target='_blank' rel='noopener noreferrer' href={`https://facebook.com/sharer/sharer.php?kid_directed_site=0&u=${encodeURIComponent(url)}`}>
            <img src={facebookIcon} className='ic-social' alt='ic-fb' />
          </a>
};

export default FacebookShare;