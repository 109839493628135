import axios from "axios";
export const getInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // withCredentials: true
    insecureHTTPParser: true,
    // headers: {
    //   'Sec-Fetch-Site': 'cross-site'
    // }
    //   common: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // },
  });
  return instance;
};