import React, { useEffect, useState, useContext} from 'react'
import Header from '../../template/Header'
import './Home.css'
import HeadingSection from "../../components/headingSection/headingSection"
import Landing360 from "../../components/landing360View/lading360View"
import LandingContent from "../../components/landingContent/landingContent"
import CardSlider from "../../components/cardSlide/Slide"
import { contacts } from "./constants"
import { useMediaQueryHook } from "../../hooks/mediaQueryHook"
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Footer from "../../components/footer/footer"
import ContactForm from '../../components/contactForm/ContactForm'
import { getDataHome } from '../../api/home_api'
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import { useTranslation } from 'react-i18next'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';
import { isVideoFile } from '../../utils/functions';
import MultiLangHtml from '../../components/MultiLangeHtml';

function Home() {
  const { viewportSize } = useMediaQueryHook()
  const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true

  const [txtDescAllSeasons, setTxtDescAllSeasons] = useState('');
  const [txtDescOfficeBuilding, setTxtDescOfficeBuilding] = useState('');
  const [imagesOffice, setImagesOffice] = useState([]);
  const [txtDescRetail, setTxtDescRetail] = useState('');
  const [imagesRetail, setImagesRetail] = useState([]);
  const [txtDescCondominium, setTxtDescCondominium] = useState('');
  const [imagesCondo, setImagesCondo] = useState([]);
  const [txtDescConrad, setTxtDescConrad] = useState('');
  const [imagesConrad, setImagesConrad] = useState([]);
  const [txtDescCareer, setTxtDescCareer] = useState('');
  const [imagesCareer, setImagesCareer] = useState([]);
  const [aspVirtualLink, setAspVirtualLink] = useState();
  const [conradLink, setConradLink] = useState();

  const { t } = useTranslation()
  const [txtDescCust, setTxtDescCust] = useState();
  const [txtDescClub, setTxtDescClub] = useState();
  const [txtDescShuttle, setTxtDescShuttle] = useState();
  const [txtDescCar, setTxtDescCar] = useState();
  const { lang } = useContext(ChangeLanguageContext);
  const firstBanner = [{
    original: "https://dev-asp.verkoffice.com/assets/images/banner-home.png",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    description1: "RECOGNIZED",
    description2: "AS THE PROMINENT",
    description3: "BUSINESS ADDRESS",
    isVideo: "",
  }]
  const [heroBanner, setHeroBanner] = useState(firstBanner);

  const [listDataNews, setListDataNews] = useState([
    {
      _id: '',
      imgItem: '',
      txtTitle: '',
      effectiveDate: '',
      endDate: '',
      txtDetail: '',
      shareFB: '',
      shareLine: '',
      shareX: '',
      link: '',
    },
  ]);

  const [vrMode, setVrMode] = useState(false);

  useEffect(() => {
    // Event listener to handle scroll and toggle VR mode
    const handleScroll = () => {
    const clickVrElement = document.querySelector('.click-vr');
    const iframe = document.querySelector('iframe');
    if (clickVrElement && iframe) {
        const { top, bottom } = clickVrElement.getBoundingClientRect();
        if (top >= 0 && bottom <= window.innerHeight) {
        // Click VR element is fully visible in the viewport
        // setVrMode(true);
        } else {
        // Click VR element is not fully visible in the viewport
        setVrMode(false);
        }
    }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
}, []);

const handleClickVr = () => {
    // Toggle VR mode on click
    setVrMode(!vrMode);
};

  const renderItem = (item) => {
    return (
      <div className='image-gallery-item'>
        {item.isVideo ? (
          <div className="video-wrapper">
            <video controls className='im-banner'>
              <source src={item.original} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <img src={item.original} alt={item.original} className='im-banner' />
        )}
        <div className='image-gallery-description'>
          <p className='mb-1 mb-md-2 fontBold font-size09 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description1}}></div></p>
          <p className='mb-2 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description2}}></div></p>
          <p className='mb-1 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description3}}></div></p>
          <p className='mb-2 bt-line-green'></p>
        </div>
      </div>
    );
  }; 

  const fetchingData = async (language) => {
    try {
      const result = await getDataHome({}, language); // Pass the language parameter here
      const banners = result?.data?.data?.banner?.data.map((item) => ({
        original: item.data,
        thumbnail: item.data,
        description1: item.supertext,
        description2: item.description,
        description3: '',
        isVideo: isVideoFile(item.data),
      }));
      let bannerConcat = firstBanner.concat(banners);
      setHeroBanner(bannerConcat);
      setTxtDescAllSeasons(result?.data?.data?.content?.asp_description);
      setTxtDescOfficeBuilding(result?.data?.data?.content?.offfice_description);
      setTxtDescRetail(result?.data?.data?.content?.retail_description);
      setTxtDescCondominium(result?.data?.data?.content?.condo_description);
      setTxtDescConrad(result?.data?.data?.content?.conrad_description);
      setTxtDescCareer(result?.data?.data?.content?.career_description);
      setImagesOffice(result?.data?.data?.content?.office_image[0]);
      setImagesRetail(result?.data?.data?.content?.retail_image[0]);
      setImagesCondo(result?.data?.data?.content?.condo_image[0]);
      setImagesConrad(result?.data?.data?.content?.conrad_image[0]);
      setImagesCareer(result?.data?.data?.content?.career_image[0]);
      setAspVirtualLink(result?.data?.data?.content?.asp_virtual_link);
      setConradLink(result?.data?.data?.content?.conrad_link);
      setTxtDescCust(result?.data?.data?.content?.cscare_description)
      setTxtDescClub(result?.data?.data?.content?.club_description);
      setTxtDescShuttle(result?.data?.data?.content?.shuttle_description);
      setTxtDescCar(result?.data?.data?.content?.carpark_description);
      const infoList = result?.data?.data?.content?.news;
            if (infoList && infoList.length > 0) {
              const updatedInfoListDatas = infoList.map(detail => {
                  return {
                      _id: detail._id,
                      imgItem: detail.col1_imgs?.length>0?detail.col1_imgs[0]:'',
                      txtTitle: detail.col1_title,
                      effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                      endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                      txtDetail: detail.col1_description,
                      shareFB: detail.fb_sharelink,
                      shareLine: detail.line_sharelink,
                      shareX: detail.twitter_sharelink,
                      link: detail.link,
                      shareLineText: detail?.shareLineText,
                      shareXtext: detail?.shareXtext,
                      shareText: detail?.shareXtext,
                  };
              });
              setListDataNews(updatedInfoListDatas);
          } else {
                // Handle case when detail_list is empty
                // You may want to set an empty array or handle it differently
                setListDataNews([]);
            }
    } catch (error) {
      console.log('found error', error);
    }
  };
  

  useEffect(() => {
    fetchingData(lang.startsWith('en') ? 'en' : 'th');
  }, [lang]);
  

  return (
    <div>
      <style>
        {`
            ul.menu li a#home {
              color: #2D8929;
              text-decoration: underline;
            }
            .video-wrapper video {
              height: 600px;
            }
            a.cont:nth-child(1), a.cont:nth-child(2) {
              border-right: 1px solid #cdcdcd;
              width: auto;
              height: auto;
              padding-right: 13%;
            }
            .font-size10 {
                font-size: 4rem !important;
                font-size: clamp(2rem, 4vw, 4rem) !important;
            }
            .font-size13 {
                font-size: 5rem !important;
                font-size: clamp(2.35rem, 5vw, 5rem) !important;
            }
            @media (max-width: 1200px) {
                .content-left h2, .content-right h2 {
                    text-align: unset !important;
                }
            }
            @media screen and (max-width: 392px) {
              a.cont:nth-child(1), a.cont:nth-child(2) {
                padding-right: 7% !important;
              }
            }
            @media screen and (max-width: 330px) {
              a.cont:nth-child(1), a.cont:nth-child(2) {
                padding-right: 3% !important;
              }
            }
        `}
      </style>
      <Header />
      <div className="container-fluid w-100">
        <div className="row">
          <div className="col-12 px-0">
            <div className="image-gallery-wrapper">
              <ImageGallery
                items={heroBanner}
                autoPlay={true}
                showThumbnails={false}
                showFullscreenButton={false}
                showBullets={true}
                showNav={false}
                renderItem={renderItem}
                slideDuration={5000}
              />
            </div>
          </div>
        </div>
      </div>
      <ScrollAnimation animateIn='fadeInUp'
        initiallyVisible={true}>
          <HeadingSection title={t('home_title')} subTitle={t('home_subtitle')} customize={
            { paddingTop: '5rem' }
          }
          classCss="pdt-hd pdb-hd" 
          subTitleDetail="d-block"
          />
          <div className="container-fluid default-font p-0">
            <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0 overflow-hidden">
              <div className="col-12 col-md-6 font-size02">
                <div dangerouslySetInnerHTML={{ __html: txtDescAllSeasons}}></div>
              </div>
              <div className="col-12 pt-3 px-0">
                  <h1 className='mt-5 mb-3 fontBold font-size06'>
                    {t('visual_title')}
                  </h1>
              </div>
              <div className="col-12 pt-3 px-0 position-relative click-vr" onClick={handleClickVr}>
                  {vrMode ? null : <div className='box-bg'></div>}
                  {vrMode ? null : <img src="assets/images/cursor-custom.png" className='cursor-click' alt=''/>}
                  <iframe src={aspVirtualLink} title="Iframe Example" className='w-100 h-800'></iframe>
              </div>
            </div>
          </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp'
        initiallyVisible={true}>
      <HeadingSection title={t('home_business_title')} subTitle={t('home_business_subtitle')}
        customize={{ paddingTop: '3rem', paddingBottom: '2rem' }}
        classCss="pdt-hd pdb-hd"
        subTitleDetail="d-block"
      />
      <div className='container-fluid '>
        <div className="row h-100 ">
          <Landing360
            path={'/offices'}
            target={''}
            image={imagesOffice}
            title={t('home_officeuppercase_title')}
            subTitle={t('home_officeuppercase_subtitle')}
            detail={<MultiLangHtml translationKey={txtDescOfficeBuilding}/> }
          />
          <Landing360
            path={'/retailCenter'}
            target={''}
            image={imagesRetail}
            title={t('home_retail_title')}
            subTitle={t('home_retail_subtitle')}
            detail={<MultiLangHtml translationKey={txtDescRetail}/> }
            alignContentLeft={notContentLeftInMobile}
          />
          <Landing360
            path={'/condominium'}
            target={''}
            image={imagesCondo}
            title={t('home_condo_title')}
            subTitle={t('home_condo_subtitle')}
            detail={<MultiLangHtml translationKey={txtDescCondominium}/> }
          />
          <Landing360
            path={conradLink}
            target={'blank'}
            image={imagesConrad}
            title={t('home_conrand_title')}
            subTitle={t('home_conrand_subtitle')}
            detail={<MultiLangHtml translationKey={txtDescConrad}/> }
            alignContentLeft={notContentLeftInMobile}
          />
        </div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn'
        animatePreScroll={false}>
      <HeadingSection title={t('home_ourservice_title')} subTitle={t('home_ourservice_subtitle')} customize={{ paddingTop: '3rem', paddingBottom: '2rem' }} classCss="pdt-hd pdb-hd" subTitleDetail="d-block"/>
      <div className='container-fluid'>
        <LandingContent
          image={`/assets/images/section/section_2_1.png`}
          title={t('home_ourservice_cscare')}
          detail={<MultiLangHtml translationKey={txtDescCust}/> }
          path={'/ourServices?type=customer'}
          alignContentLeft={false}
          showLink={true}
        />
        <LandingContent
          image={`/assets/images/section/section_2_2.png`}
          title={t('home_ourservice_club')}
          detail={<MultiLangHtml translationKey={txtDescClub}/> }
          path={'/ourServices?type=club'}
          alignContentLeft={notContentLeftInMobile}
          showLink={true}
        />
        <LandingContent
          image={`/assets/images/section/section_2_3.png`}
          title={t('home_ourservice_shuttle')}
          detail={<MultiLangHtml translationKey={txtDescShuttle}/> }          
          path={'/ourServices?type=shuttle'}
          showLink={true}
        />
        <LandingContent
          image={`/assets/images/section/section_2_4.png`}
          title={t('home_ourservice_carpark')}
          detail={<MultiLangHtml translationKey={txtDescCar}/> } 
          path={'/ourServices?type=carpark'}
          alignContentLeft={notContentLeftInMobile}
          showLink={true}
        />
      </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn'>
      <HeadingSection title={t('home_news_event_title')} subTitle={t('home_news_event_subtitle')} customize={{ paddingTop: '3rem', paddingBottom: '3rem' }} classCss="pdt-hd pdb-hd" subTitleDetail="d-block"/>
      <div className='container-fluid pb-5'>
        <div className="row">
          <div className="col-12">
            <CardSlider
              data={listDataNews}
            />
          </div>
        </div>
      </div>
      <div className="">
        <Landing360
          path={'/careers'}
          target={''}
          image={imagesCareer}
          title={t('home_careers_title')}
          subTitle={t('home_careers_subtitle')}
          detail={<MultiLangHtml translationKey={txtDescCareer}/> }
          customLinkName="WORK WITH US"
        />
      </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn'>
      <div className="container my-3 my-md-5">
        <div className="row px-3 position-relative zIndex">
          <HeadingSection title={t('home_contact_address_title')} subTitle={t('home_contact_address_subtitle')}
            position={'start'}
            customize={{
              paddingBottom: '2rem',
              paddingTop: '2rem'
            }}
            classCss="pdt-hd pdb-hd"
            subTitleDetail="d-block"
          />
          <div className="col-12 col-xl-6 mt-0 mt-md-3">
            {lang=='th'?
              <div className='font-size12 fontReg'>
                <p className='mb-0 font-size02 fontLight'>บริษัท ออล ซีซั่นส์ พร็อพเพอร์ตี้ จำกัด</p>
                <p className='mb-0 font-size02 fontLight'>ชั้น19, อาคาร เอ็ม.ไทย ทาวเวอร์, ออล ซีซั่นส์ เพลส,</p>
                <p className='mb-0 font-size02 fontLight'>87 ถนนวิทยุ, แขวงลุมพินี, เขตปทุมวัน, กรุงเทพมหานคร 10330</p>
              </div>
              :
              <div className='font-size12 fontReg'>
                <p className='mb-0 font-size02 fontLight'>All Seasons Property Co., Ltd.</p>
                <p className='mb-0 font-size02 fontLight'>19th Floor, M. Thai Tower, All Seasons Place,</p>
                <p className='mb-0 font-size02 fontLight'>87 Wireless Road, Phatumwan, Bangkok 10330, Thailand</p>
              </div>
            }
          </div>
          <div className="col-12 col-xl-6 d-flex justify-content-between mt-3 flex-wrap">
            {
              contacts.map((item, index) => {
                return (
                  <a className="text-decoration-none cont position-relative mt-3 d-flex flex-column justify-content-between align-items-center text-dark fontReg font-size03" href={`${item.path}`} key={index}>
                      {item.icon}
                      <div className='d-flex flex-column align-items-center fontMed font-size03 mt-2'>
                          {item.text}
                      </div>
                  </a>
                )
              })
            }
          </div>
        </div>
        <div className="container mt-5">
          <ContactForm/>
        </div>
      </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn'>
      <div className="container-fluid default-font mb-6">
        <div className="row">
          <div className="col-12 px-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.660033591625!2d100.5461540148303!3d13.739020890355711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29edea46fc619%3A0x28af520b8b01ba1c!2sAll%20Seasons%20Place!5e0!3m2!1sen!2sth!4v1610598745222!5m2!1sen!2sth" width="100%" height={450}
              allowFullScreen aria-hidden="false" tabIndex={0} title='map'/>
          </div>
        </div>
      </div>
      </ScrollAnimation>
      <div>
        <ScrollToTopButton />
      </div>
      <Footer />
    </div>
  )
}

export default Home;