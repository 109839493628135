import React, { useState } from 'react';

const minMonth = 1;
const maxMonth = 12;

function MonthPicker({ onMonthChange }) {
    const [selectedMonth, setSelectedMonth] = useState("MM"); // Default is "MM"
  
    const onHandleChange = (evt) => {
      const newSelectedMonth = evt.target.value;
      setSelectedMonth(newSelectedMonth);
      // Call the callback function passed from the parent with the updated state
      onMonthChange(newSelectedMonth);
    };
  
    const options = [];
    for (let i = minMonth; i <= maxMonth; i++) {
      const month = i < 10 ? `0${i}` : `${i}`; // Pad single digit months with leading zero
      options.push(<option key={month} value={month}>{month}</option>);
    }
  
    return (
      <div>
        <style>
            {`
                .date-style {
                    width: 100px;
                    background-color: #fff !important;
                    color: #000000 !important;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;
                    height: 42px !important;
                    border: 1px solid #D1D3D4 !important;
                    border-radius: 10px !important;
                    padding: 3px 5px !important;
                    font-family: 'db_heaventregular' !important;
                    font-size: 0.9rem !important;
                    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
                }
            `}
        </style>
        <select value={selectedMonth} onChange={onHandleChange} className='date-style'>
          <option value="MM">MM</option>
          {options}
        </select>
      </div>
    );
}

export default MonthPicker;