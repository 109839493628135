import React from 'react';
import { useTranslation } from 'react-i18next';

const MultiLangHtml = ({ translationKey }) => {
  const { t } = useTranslation();

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: t(translationKey).replace(/\n/g, '<br />'),
      }}
    />
  );
};

export default MultiLangHtml;
