import React, { useRef } from "react";
import Slider from "react-slick";
import "./slick.css";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";

const CenterMode = React.memo(({ data }) => {
    const sliderRef = useRef();
    const { viewportSize } = useMediaQueryHook();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: viewportSize?.width <= 768 ? 1 : 2,
        slidesToScroll: 1,
        arrows: false,
        centerMode: viewportSize?.width > 1204,
        centerPadding: viewportSize?.width > 1204 ? 0: 0,
    };

    const next = () => {
        sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="slider-container default-font">
            <style>
                {`
                    .box-next {
                        margin-left: 25px;
                    }
                    .box-pev {
                        margin-right: 25px;
                    }
                    .test2 .slick-list .slick-track .slick-slide {
                        padding: 0 0 !important;
                    }
                `}
            </style>
            {data.length > 1 ? (
                <Slider ref={sliderRef} {...settings} className="test2">
                    {data.map((item, index) => (
                        <div key={index} className="w-100 slide-item position-relative" style={{ position: 'relative', userSelect: 'none' }}>
                            <div className="slide">
                                <div className="promo-item position-relative">
                                    <img src={item.images} className="img-style-cv01" alt="img" />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                data.map((item, index) => (
                    <div key={index} className="w-100" style={{ position: 'relative', userSelect: 'none' }}>
                        <div className="row">
                            <div className="col-12">
                                <img src={item.images} className="cover02" alt="img" />
                            </div>
                        </div>
                    </div>
                ))
            )}
            {data.length > 1 && (
                <>
                    <div className="box-next">
                        <svg onClick={prev} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                            <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                            <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="box-pev">
                        <svg onClick={next} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                            <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                            <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    );
})

export default CenterMode;