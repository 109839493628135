import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "./slick.css"
import { Link, useNavigate } from "react-router-dom"
import { useMediaQueryHook } from "../../hooks/mediaQueryHook"
import LinesEllipsis from 'react-lines-ellipsis'
import FacebookShare from '../../pages/ShareSocial/FacebookShare';
import TwitterShare from '../../pages/ShareSocial/TwitterShare';
import LineShare from '../../pages/ShareSocial/LineShare';
import LinkCopy from '../../pages/ShareSocial/LinkCopy';
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ChangeLanguageContext } from "../../contexts/changeLanguageContext";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

function CenterMode({ data }) {
    const { lang } = useContext(ChangeLanguageContext)
    const { t } = useTranslation()

    const sliderRef = useRef();
    const { viewportSize } = useMediaQueryHook()
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Hide the arrow navigation
        centerMode: viewportSize?.width <= 1204 ? false : true,
        centerPadding: viewportSize?.width <= 1204 ? 0 : 220,
    };


    const next = () => {
        sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current.slickPrev();
    };

    const navigate = useNavigate();
    const handleSeeMore = (id) => {
        navigate("/newsEvent");
    };

    const navigateToDetail = (id) => {
        return `/newsEventDetail?id=${id}`;
    };

    return (
        <div className="slider-container default-font">
            {
                data.length > 1 ?
                    <Slider ref={sliderRef} {...settings}>
                        {
                            data.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="w-100"
                                        style={{ position: 'relative', userSelect: 'none' }}
                                    >
                                        <div className="row align-items-center">
                                            
                                                <div className="col-12 col-xl-6 px-0 px-lg-2">
                                                    <ScrollAnimation animateIn="fadeInLeft" delay={100}>
                                                        <img src={item.imgItem} className="img-style-card02" alt=""/>
                                                    </ScrollAnimation>
                                                </div>
                                                <div className="col-12 col-xl-6 px-0 px-lg-4 text-dark">
                                                    <ScrollAnimation animateIn="fadeInRight" delay={200}>
                                                        <div className="detail">
                                                            <div className="h-100 w-100 d-flex flex-column align-items-start p-3 position-relative">
                                                                <p className="text-secondary mb-3 fontLight font-size02">
                                                                    News
                                                                </p>
                                                                <h5 className="text-dark mb-3 fontMed font-size04">
                                                                    {item.txtTitle}
                                                                </h5>
                                                                <div className="mb-3 font-size02 fontLight w-100">
                                                                    <div className="multi-line-ellipsis"><div dangerouslySetInnerHTML={{ __html: item.txtDetail}}></div></div>
                                                                </div>
                                                                <p className="mb-3 fontLight font-size02">
                                                                    {`${item.effectiveDate} - ${item.endDate} `}
                                                                </p>
                                                                <div className="d-flex justify-content-start align-items-end w-90 style-card-detail01">
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <div className='d-flex flex-row align-items-center'>
                                                                            <font className="font-size03 fontBold colorBlack text-underline">
                                                                                SHARE
                                                                            </font>
                                                                            <div className='ms-3 w-100 d-flex align-items-end'>
                                                                                    <FacebookShare url={item.link} />
                                                                            </div>
                                                                            <div className='ms-2 w-100'>
                                                                                    <TwitterShare url={item.link} text={item.shareText} />
                                                                            </div>
                                                                            <div className='ms-2 w-100'>
                                                                                    <LineShare url={item.link} text={item.shareText} />
                                                                            </div>
                                                                            <div className='ms-2 w-100'>
                                                                                    <LinkCopy url={item.link}></LinkCopy>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Link to={navigateToDetail(item._id)} className="readmore font-size12 fontLight">
                                                                                CONTINUE READING{' > '}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </ScrollAnimation>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </Slider> : data.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="w-100"
                                style={{ position: 'relative', userSelect: 'none' }}
                            >
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <img src={item} width={'100%'} alt="" />
                                    </div>
                                    <div className="col-12 col-xl-6 text-dark bg-light-white d-flex flex-column align-items-start p-xl-3 p-5">
                                        <p className="text-secondary fontLight font-size02">
                                            News
                                        </p>
                                        <h5 className="text-dark mb-3 fontMed font-size04">
                                            {item.txtTitle}
                                        </h5>
                                        <div className="mb-3 font-size02 fontLight w-100">
                                            <div className="multi-line-ellipsis">{item.txtDetail}</div>
                                        </div>
                                        <p className="mb-3 fontLight font-size02">
                                            {item.effectiveDate}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
            }
            {
                data.length > 1 &&
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-3 d-flex justify-content-start mt-3">
                            <svg onClick={prev} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            &nbsp;&nbsp;
                            <svg onClick={next} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="col-9 d-flex justify-content-end mt-3 ps-0 ps-md-2">
                            <button className="btn border d-flex rounded-pill align-items-center px-1 px-md-3" onClick={() => handleSeeMore()}>
                                <div className="fontMed font-size02 px-1 px-lg-3">
                                   {t('home_news_event_seemore')}
                                </div>
                                <div className="ms-lg-4 ms-2">
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.499 26.5802C20.6566 26.5802 26.459 20.7778 26.459 13.6202C26.459 6.46255 20.6566 0.660156 13.499 0.660156C6.34141 0.660156 0.539062 6.46255 0.539062 13.6202C0.539062 20.7778 6.34141 26.5802 13.499 26.5802Z" fill="black"/>
                                        <path d="M12.8594 9.68018L16.7994 13.6202L12.8594 17.5602" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

export default CenterMode;
