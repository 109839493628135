import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './Careers.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row, InputGroup, Form, Button } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataCareers, postDataCareers } from '../../api/careers_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { useLocation } from 'react-router-dom';
import Dropdown from "../../components/dropdowns/Dropdown"
import UploadFilePDF from "../../components/uploads/UploadFilePDF";
import UploadProfile from '../../components/uploads/UploadProfile';
import ReCAPTCHA from "react-google-recaptcha";
import Year from '../../components/datePicker/Year';
import DayPicker from '../../components/datePicker/DayPicker';
import MonthPicker from '../../components/datePicker/MonthPicker';
import Swal from "sweetalert2";
import { DataDropdownDegree,DataDropdownDegreeTH } from "../../components/dataMockup/DataMockDropdown";
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function ApplicationForm() {
    const { viewportSize } = useMediaQueryHook();
    const location = useLocation();
    
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { t } = useTranslation();
    const { lang } = useContext(ChangeLanguageContext);
    const [active, setActive] = useState(true);
    const [active01, setActive01] = useState(true);
    const [selectedEduc, setSelecteEduc] = useState("");
    const [dataDropdownEduc, setDataDropdownEduc] = useState([]);
    const [selectedDegree, setSelecteDegree] = useState("");
    const [dataDropdownDegree, setDataDropdownDegree] = useState([]);
    const [selectedSpecifyType, setSelectedSpecifyType] = useState("");
    const [dataDropdownSpecifyType, setDataDropdownSpecifyType] = useState([]);
    const [images1, setImages1] = useState([]);
    const [imagesFromApi1, setImagesFromApi1] = useState([]);
    const [images2, setImages2] = useState([]);
    const [imagesFromApi2, setImagesFromApi2] = useState([]);
    const [clickCheck, setClickCheck] = useState("");
    const [isCaptcha, setIsCaptcha] = useState(false);


    const [inputForm, setInputForm] = useState({
        educationalStatus: 0,
        jobDegree: 0,
        nationality: 0,
        specifyType: 0,
    });

    function setinput(inputname, value) {
        setInputForm((prev) => {
            return {
                ...prev,
                [inputname]: value,
            };
        });
    }

    const [valuefromparent1, setValuefromparent1] = useState("Select");

    const ChangesetValuefromparent1 = (text) =>  {
        setValuefromparent1(text);
    }

    const [valuefromparent2, setValuefromparent2] = useState("Select");

    const ChangesetValuefromparent2 = (text) =>  {
        setValuefromparent2(text);
    }

    const [valuefromparent3, setValuefromparent3] = useState("Select");

    const ChangesetValuefromparent3 = (text) =>  {
        setValuefromparent3(text);
    }

    const handleEducChange = (value) => {
        setSelecteEduc(value);
    };

    const handleDegreeChange = (value) => {
        setSelecteDegree(value);
    };

    const handleSpecifyTypeChange = (value) => {
        setSelectedSpecifyType(value);
    };

    const handleSelectedImages1 = (images) => {
        setImages1(images);
    };
    const handleSelectedRemoveImg1 = (data) => {
        // setDataStClear(data);
        setImages1(data);
    };

    const handleSelectedImages2 = (images) => {
        setImages2(images);
    };
    const handleSelectedRemoveImg2 = (data) => {
        // setDataStClear(data);
        setImages2(data);
    };

    const [dataAppForm, setDataAppForm] = useState({
        fullName: '',
        gender: '',
        dateBirth: '',
        tel: '',
        email: '',
        address: '',
        educInst: '',
        major: ''
    });

    const [selectedDay, setSelectedDay] = useState('DD');
    const [selectedMonth, setSelectedMonth] = useState("MM");
    const [selectedYear, setSelectedYear] = useState("YYYY");

    const handleDayChange = (newDay) => {
      setSelectedDay(newDay);
    };

    const handleMonthChange = (newMonth) => {
        setSelectedMonth(newMonth)
    }

    const handleYearChange = (newYear) => {
        setSelectedYear(newYear)
    }

    // https://dev-asp.verkoffice.com/applicationForm?applyJobId=667831270b09a60013d3fe0b
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    async function alertValidate(title) {
        const res = await Swal.fire({
            iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
            title: title,
        });
        if (res.isConfirmed) {
            return;
        } else {
        return;
        }
    }
    const submitData = async () =>{
        try {
            if (isCaptcha) {
                if (validateEmail(dataAppForm.email)) {
                    const formatedBodyToServer = {
                        nationality: active === false ? 0 : 1,
                        fullname: dataAppForm.fullName,
                        gender: active01 === false ? 0 : 1,
                        dateofbirth: `${selectedDay}/${selectedMonth}/${selectedYear}`,
                        phonenumber: dataAppForm.tel,
                        address: dataAppForm.address,
                        email: dataAppForm.email,
                        major: dataAppForm.major,
                        specify_type: selectedSpecifyType,
                        degree: selectedDegree,
                        job_id: selectedSpecifyType,
                        education_institute: dataAppForm.educInst,
                        img: images1 ?? null,
                        cv: images2 ?? null,
                    }
                    if (
                        !formatedBodyToServer.fullname ||
                        !formatedBodyToServer.specify_type ||
                        !formatedBodyToServer.dateofbirth ||
                        formatedBodyToServer.phonenumber.length !== 10 ||
                        !formatedBodyToServer.email ||
                        !formatedBodyToServer.job_id ||
                        !formatedBodyToServer.degree ||
                        !formatedBodyToServer.img.length > 0 ||
                        !formatedBodyToServer.cv.length > 0 
                    ) {
                      await alertValidate("กรุณากรอกข้อมูลให้ครบถ้วน");
                    } else {
                        await Swal.fire({
                            title: "Confirm Data Save",
                            text: "ยืนยันการบันทึกข้อมูล",
                            iconHtml: '<img src="https://img5.pic.in.th/file/secure-sv1/ic-save.png">',
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: "CONFIRM",
                            cancelButtonText: "CANCEL",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              (async () => {
                                const response = await postDataCareers(formatedBodyToServer, lang=='en-US'?'en':lang);
                                  if (response?.status == 200) {
                                    Swal.fire({
                                      icon: "success",
                                      title: "Saved",
                                      text: "บันทึกเรียบร้อยแล้ว",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        window.location.reload();
                                      }
                                    });
                                  }
                                  else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "Error",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          window.location.reload();
                                        }
                                      });
                                  }
                              })();
                            }
                          });
                    }
                }
                else {
                    alertValidate("กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                }
            } else {
                await Swal.fire({
                    title: "Please Click Capca",
                    text: "กรุณากดยืนยัน capca",
                    iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {});
            }
            
        } catch (error) {
            console.log('found error ', error)
        }
    }
    

    const handleClose = () => {
        window.location.reload();
    };

    const fetchingData = async (language) => {
        try {
            let applyJobId = new URLSearchParams(window.location.search).get("applyJobId")
            const response = await getDataCareers({}, language);
            if (response.status == 200) {
                let selectedJobIdx = response.data.data.jobsPosition.findIndex(item => item._id == applyJobId)
                let dataSpecifyType =  [{ value: "", text: "Select" }]
                
                response.data.data.jobsPosition.forEach((item, i) => {
                        return dataSpecifyType.push({
                            value: item._id,
                            text: item.name,
                        });
                });

                if(selectedJobIdx != -1) setSelectedSpecifyType(response.data.data.jobsPosition[selectedJobIdx]._id)
                setDataDropdownSpecifyType(dataSpecifyType);
            }
        } catch (error) {
            console.log(error);
        }
      };
    
      useEffect(() => {
        
        fetchingData(lang=='en-US'?'en':lang)
      }, [lang])

  return (
    <div>
        <style>
            {`
                ul.menu li a#careers {
                color: #3AF823;
                text-decoration: underline;
                }
                .select {
                    border: 1px solid #D1D3D4 !important;
                    border-radius: 10px !important;
                }
                .ic-remove-img {
                    position:absolute !important;
                    top:30% !important;
                    left: 31%; !important;
                }
                .date-style {
                    width: 100% !important;
                }
                @media screen and (max-width: 1500px) {
                    .w-35 {
                        width: 55% !important;
                    }
                }
                @media (max-width: 1200px) {
                    .w-35 {
                        width: 75% !important;
                    }
                }
                @media (max-width: 600px) {
                    .px-10, .px-20 {
                        padding: 0 2rem;
                    }
                }
            `}
        </style>
        <Header />
        <div className="container-fluid w-100">
            <div className="row">
                <div className="col-12 px-0">
                    <div className="image-gallery-wrapper">
                        <div className="position-relative">
                            <img src="/assets/images/careers/banner.png" alt='banner' className="banner-title" />
                            <div className="txt-banner">
                            <p className="fontBold font-size10 colorWhite mb-0">{t('career_title')}</p>
                            <p className="bd-bottom-banner"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollAnimation animateIn='fadeInUp'
                initiallyVisible={true}
                animateOnce={true}
                >
                <HeadingSection 
                    title={t('app_form_head_title')} 
                    subTitle={t('app_form_head_subtitle')}
                    customize={{ paddingTop: '3rem' }}
                    classCss="pdt-hd"
                    subTitleDetail="d-block"
                />
                </ScrollAnimation>
                <div className='px-20'>
                    <ScrollAnimation animateIn='fadeInUp'
                    initiallyVisible={true}
                    animateOnce={true}
                    >
                    <Row className='box-title-form mt-4 mb-0 mt-md-5 mb-md-3 mx-0'>
                        <Col xs={12} className='d-flex align-items-center'>
                            <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.6305 22.4801H3.09048C1.93048 22.4801 0.980469 21.5401 0.980469 20.3701V6.73013C0.980469 5.57013 1.93048 4.62012 3.09048 4.62012H22.6305C23.7905 4.62012 24.7404 5.56013 24.7404 6.73013V20.3701C24.7404 21.5301 23.8005 22.4801 22.6305 22.4801ZM3.09048 6.63013C3.03048 6.63013 2.98044 6.68011 2.98044 6.74011V20.3801C2.98044 20.4401 3.03048 20.4901 3.09048 20.4901H22.6305C22.6905 20.4901 22.7404 20.4401 22.7404 20.3801V6.74011C22.7404 6.68011 22.6905 6.63013 22.6305 6.63013H3.09048Z" fill="black"/>
                                <path d="M16.8698 6.54044C16.3198 6.54044 15.8698 6.09042 15.8698 5.54042V3.05043C15.8698 2.99043 15.8198 2.94043 15.7598 2.94043H9.94983C9.88983 2.94043 9.83984 2.99043 9.83984 3.05043V5.54042C9.83984 6.09042 9.38984 6.54044 8.83984 6.54044C8.28984 6.54044 7.83984 6.09042 7.83984 5.54042V3.05043C7.83984 1.89043 8.78983 0.94043 9.94983 0.94043H15.7598C16.9198 0.94043 17.8698 1.88043 17.8698 3.05043V5.54042C17.8698 6.09042 17.4198 6.54044 16.8698 6.54044Z" fill="black"/>
                                <path d="M10.6906 12.6709H2.39062C2.11062 12.6709 1.89062 12.4509 1.89062 12.1709C1.89062 11.8909 2.11062 11.6709 2.39062 11.6709H10.6906C10.9706 11.6709 11.1906 11.8909 11.1906 12.1709C11.1906 12.4509 10.9706 12.6709 10.6906 12.6709Z" fill="black"/>
                                <path d="M22.9797 12.6709H14.6797C14.3997 12.6709 14.1797 12.4509 14.1797 12.1709C14.1797 11.8909 14.3997 11.6709 14.6797 11.6709H22.9797C23.2597 11.6709 23.4797 11.8909 23.4797 12.1709C23.4797 12.4509 23.2597 12.6709 22.9797 12.6709Z" fill="black"/>
                                <path d="M14.2388 15.44H11.4688C11.1887 15.44 10.9688 15.22 10.9688 14.94V10.79C10.9688 10.51 11.1887 10.29 11.4688 10.29H14.2388C14.5188 10.29 14.7388 10.51 14.7388 10.79V14.94C14.7388 15.22 14.5188 15.44 14.2388 15.44ZM11.9788 14.44H13.7488V11.29H11.9788V14.44Z" fill="black"/>
                            </svg>
                            <font className='fontMed font-size02 colorBlack ms-3'>
                                {t('app_form_title')} <span className=' colorRed'>*</span>
                            </font>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Row className='mt-md-4 mt-2'>
                                <Col xs={12}>
                                    <label className="fontReg font-size02 colorBlack01">
                                        {t('app_form_nat_label')} <span className=' colorRed'>*</span>
                                    </label>
                                </Col>
                            </Row>
                            <Form>
                                {["radio"].map((type) => (
                                    <div key={`inline3-${type}`} className="mt-2 mb-md-3 mb-1">
                                        <Form.Check
                                            className="fontReg font-size02 colorBlack01 checked"
                                            inline
                                            label={t("app_form_nat_thai")}
                                            name="group3"
                                            type={type}
                                            id={`inline3-${type}-1`}
                                            checked={active}
                                            onChange={(e) => setActive(true)}
                                        />
                                        <Form.Check
                                            className="fontReg font-size02 colorBlack01"
                                            inline
                                            label={t("app_form_nat_foreign")}
                                            name="group3"
                                            type={type}
                                            id={`inline3-${type}-2`}
                                            checked={!active}
                                            onChange={(e) => setActive(false)}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row className='mt-md-4 mt-2'>
                                <Col xs={12}>
                                    <label className="fontReg font-size02 colorBlack01">
                                        {t("app_form_job_interest")} <span className=' colorRed'>*</span>
                                    </label>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12}>
                                    <Dropdown
                                        options={dataDropdownSpecifyType}
                                        setinput={setinput}
                                        inputname={"specifyType"}
                                        ChangesetValuefromparent={ChangesetValuefromparent1}
                                        valuefromparent={dataDropdownSpecifyType.find((item => item.value == selectedSpecifyType)) ? dataDropdownSpecifyType.find((item => item.value === selectedSpecifyType)).text : valuefromparent1}
                                        onChange={handleSpecifyTypeChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='box-title-form mt-5 mb-3 mx-0'>
                        <Col xs={12} className='d-flex align-items-center'>
                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.93671 11.0197C6.28671 11.0197 4.13672 8.60977 4.13672 5.63977C4.13672 2.66977 6.28671 0.259766 8.93671 0.259766C11.5867 0.259766 13.7367 2.66977 13.7367 5.63977C13.7367 8.60977 11.5867 11.0197 8.93671 11.0197ZM8.93671 2.25977C7.39671 2.25977 6.13672 3.77977 6.13672 5.63977C6.13672 7.49977 7.39671 9.01974 8.93671 9.01974C10.4767 9.01974 11.7367 7.49977 11.7367 5.63977C11.7367 3.77977 10.4767 2.25977 8.93671 2.25977Z" fill="black"/>
                                <path d="M16.1684 23.3998H1.53837C1.00837 23.3998 0.578368 22.9898 0.538368 22.4698L0.218361 17.7498C0.0983609 16.0098 1.44837 14.0098 3.16837 13.3798C6.83837 12.0598 10.8784 12.0698 14.5384 13.3798C16.2684 14.0198 17.6184 16.0198 17.4884 17.7598L17.1684 22.4798C17.1284 23.0098 16.6984 23.4098 16.1684 23.4098V23.3998ZM2.47837 21.3998H15.2384L15.4984 17.6098C15.5484 16.8298 14.8384 15.6098 13.8584 15.2498C10.6584 14.0998 7.07838 14.0998 3.85838 15.2498C2.89838 15.6098 2.16837 16.8298 2.22837 17.6098L2.48838 21.3998H2.47837Z" fill="black"/>
                            </svg>
                            <font className='fontMed font-size02 colorBlack ms-3'>
                                {t('app_form_person_info')} <span className=' colorRed'>*</span>
                            </font>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <UploadProfile
                                limit={1}
                                onSelectedImages={handleSelectedImages1}
                                images={imagesFromApi1}
                                txtDetail={"jpg. limit size 5MB"}
                                removeImg={handleSelectedRemoveImg1}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-md-4 mt-2'>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_fullname')} <span className=' colorRed'>*</span>
                            </label>
                            <input
                                type="text"
                                className="input-default fontLight font-size02 mt-2"
                                id="Full_name"
                                placeholder={t('app_form_fullname')}
                                value={dataAppForm.fullName}
                                onChange={(event) =>
                                    setDataAppForm({
                                        ...dataAppForm,
                                        fullName: event.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col xs={12} md={6} className='mt-md-0 mt-2'>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_gender')}
                            </label>
                            <Form>
                                {["radio"].map((type) => (
                                    <div key={`inline4-${type}`} className="mt-2 mb-md-3 mb-1">
                                        <Form.Check
                                            className="fontReg font-size02 colorBlack01 checked"
                                            inline
                                            label={t('app_form_gender_male')}
                                            name="group4"
                                            type={type}
                                            id={`inline4-${type}-1`}
                                            checked={active01}
                                            onChange={(e) => setActive01(true)}
                                        />
                                        <Form.Check
                                            className="fontReg font-size02 colorBlack01"
                                            inline
                                            label={t('app_form_gender_female')}
                                            name="group4"
                                            type={type}
                                            id={`inline4-${type}-2`}
                                            checked={!active01}
                                            onChange={(e) => setActive01(false)}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </Col>
                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}
                    animateOnce={true}
                    >
                    <Row className='mt-md-4 mt-2'>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                               {t('app_form_dob')} <span className=' colorRed'>*</span>
                            </label>
                            <div className='d-flex flex-row mt-md-4 mt-2'>
                                <Row className='w-100'>
                                    <Col xs={4}><span className="me-2"><DayPicker onDayChange={handleDayChange} /></span></Col>
                                    <Col xs={4}><span className="me-2"><MonthPicker onMonthChange={handleMonthChange}/></span></Col>
                                    <Col xs={4}><span><Year onYearChange={handleYearChange}/></span></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_phone')}  <span className=' colorRed'>*</span>
                            </label>
                            <input
                                type="text"
                                className="input-default fontLight font-size02 mt-md-4 mt-2"
                                id="tel"
                                placeholder="000-000-0000"
                                value={dataAppForm.tel}
                                onChange={(event) => {
                                    const phoneNumber = event.target.value.replace(/\D/g, ''); // Replace non-numeric characters with an empty string
                                    setDataAppForm({
                                        ...dataAppForm,
                                        tel: phoneNumber,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}
                    animateOnce={true}
                    >
                    <Row className='mt-md-4 mt-2'>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_email')}  <span className=' colorRed'>*</span>
                            </label>
                            <input
                                type="text"
                                className="input-default fontLight font-size02"
                                id="email"
                                placeholder={t('app_form_email')}
                                value={dataAppForm.email}
                                onChange={(event) =>
                                    setDataAppForm({
                                        ...dataAppForm,
                                        email: event.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col xs={12} md={6} className='mt-2 mt-md-0'>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_addr')} 
                            </label>
                            <input
                                type="text"
                                className="input-default fontLight font-size02"
                                id="address"
                                placeholder= {t('app_form_addr')} 
                                value={dataAppForm.address}
                                onChange={(event) =>
                                    setDataAppForm({
                                        ...dataAppForm,
                                        address: event.target.value,
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}
                    animateOnce={true}
                    >
                    <Row>
                        <Col xs={12} md={6} className='mt-4'>
                            <label className="fontReg font-size02 colorBlack01">
                            {t('career_cvupload')} <span className=' colorRed'>*</span>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <UploadFilePDF
                                limit={1}
                                onSelectedFile={handleSelectedImages2}
                                selectFiles={imagesFromApi2}
                                txtDetail={"jpg.,pdf., limit size 5MB"}
                                removeImg={handleSelectedRemoveImg2}
                            />
                        </Col>
                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}
                    animateOnce={true}
                    >
                    <Row className='box-title-form mt-5 mb-2 mb-md-3 mx-0'>
                        <Col xs={12} className='d-flex align-items-center'>
                            <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5906 12.49C12.4606 12.49 12.3206 12.46 12.1906 12.41L0.990601 7.53001C0.620601 7.37001 0.390625 7.00997 0.390625 6.60997C0.390625 6.20997 0.630601 5.84992 0.990601 5.68992L12.1906 0.810039C12.4406 0.700039 12.7306 0.700039 12.9906 0.810039L24.1906 5.68992C24.5606 5.84992 24.7906 6.20997 24.7906 6.60997C24.7906 7.00997 24.5606 7.37001 24.1906 7.53001L12.9906 12.41C12.8606 12.47 12.7306 12.49 12.5906 12.49ZM3.89062 6.59996L12.5906 10.39L21.2906 6.59996L12.5906 2.81004L3.89062 6.59996Z" fill="black"/>
                                <path d="M12.5889 17.1097C9.55893 17.1097 6.52891 16.1998 3.81891 14.3798C3.53891 14.1898 3.37891 13.8798 3.37891 13.5498V8.21973C3.37891 7.66973 3.82891 7.21973 4.37891 7.21973C4.92891 7.21973 5.37891 7.66973 5.37891 8.21973V13.0098C9.86891 15.7998 15.3089 15.7998 19.7989 13.0098V8.21973C19.7989 7.66973 20.2489 7.21973 20.7989 7.21973C21.3489 7.21973 21.7989 7.66973 21.7989 8.21973V13.5498C21.7989 13.8798 21.6289 14.1898 21.3589 14.3798C18.6589 16.1998 15.6189 17.1097 12.5889 17.1097Z" fill="black"/>
                                <path d="M24.3398 14.3905C24.0598 14.3905 23.8398 14.1705 23.8398 13.8905V6.56055C23.8398 6.28055 24.0598 6.06055 24.3398 6.06055C24.6198 6.06055 24.8398 6.28055 24.8398 6.56055V13.8905C24.8398 14.1705 24.6198 14.3905 24.3398 14.3905Z" fill="black"/>
                                <path d="M24.3398 14.8896C23.7898 14.8896 23.3398 14.4396 23.3398 13.8896V10.3896C23.3398 9.83965 23.7898 9.38965 24.3398 9.38965C24.8898 9.38965 25.3398 9.83965 25.3398 10.3896V13.8896C25.3398 14.4396 24.8898 14.8896 24.3398 14.8896Z" fill="black"/>
                            </svg>
                            <font className='fontMed font-size02 colorBlack ms-3'>
                                {t('app_form_edu')}
                            </font>
                        </Col>
                    </Row>
                    <Row className='mt-md-4 mt-2'>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_degree')} <span className=' colorRed'>*</span>
                            </label>
                            <Dropdown
                                options={lang=='th'? DataDropdownDegreeTH : DataDropdownDegree}
                                setinput={setinput}
                                inputname={"jobDegree"}
                                ChangesetValuefromparent={ChangesetValuefromparent3}
                                valuefromparent={valuefromparent3}                                
                                onChange={handleDegreeChange}
                            />
                        </Col>
                        <Col xs={12} md={6} className='mt-md-0 mt-2'>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_major')}
                            </label>
                            <input
                                type="text"
                                className="input-default fontLight font-size02"
                                id="major"
                                placeholder= {t('app_form_major')}
                                value={dataAppForm.major}
                                onChange={(event) =>
                                    setDataAppForm({
                                        ...dataAppForm,
                                        major: event.target.value,
                                    })
                                }
                            />
                        </Col>
                    </Row>

                    <Row className='mt-md-4 mt-2'>
                        <Col xs={12} md={6}>
                            <label className="fontReg font-size02 colorBlack01">
                                {t('app_form_edu_inst')}
                            </label>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12} md={6}>
                            <input
                                type="text"
                                className="input-default fontLight font-size02"
                                id="educ_inst"
                                placeholder= {t('app_form_edu_inst')}
                                value={dataAppForm.educInst}
                                onChange={(event) =>
                                    setDataAppForm({
                                        ...dataAppForm,
                                        educInst: event.target.value,
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    <div className='row mt-4'>
                        <div className='col-12 p-0 d-flex justify-content-center'>
                            {/* <div class="g-recaptcha" data-sitekey="6LeCNK8pAAAAADermNkS4Ra755AK94gGiDOIGlMB"></div> */}
                            <ReCAPTCHA
                                sitekey="6LeCNK8pAAAAADermNkS4Ra755AK94gGiDOIGlMB"
                                onChange={(value)=>{
                                    if(value){
                                        setIsCaptcha(true)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='mt-5 mb-3'>
                        <Row>
                            <Col xs={6} className='d-flex justify-content-end'>
                                <Button className='btn-cancel01 fontMed font-size02' onClick={()=>handleClose()}>{t('app_form_reset')}</Button>
                            </Col>
                            <Col xs={6}>
                                <Button className='btn-agree01 fontMed font-size02' onClick={() => submitData()}>{t('app_form_send')}</Button>
                            </Col>
                        </Row>
                    </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
        <div>
            <ScrollToTopButton />
        </div>
        <Footer />
    </div>
  )
}

export default ApplicationForm;