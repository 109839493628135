import "../components/swalStyle/customStyle.css";
import { getInstance } from "./base_api";
import Swal from "sweetalert2";

const instance = getInstance();

export const getDataCareers = async (queryparams, language = "en") => {
    try {
        const headers = {
            language: language // Set the language here
        };
        const response = await instance.get(`/api/landing/job`, {
            headers: headers,
            params: { ...queryparams }
        });
        if (response.status === 200) {
            return response;
        } else {
            return null;
        }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: error,
            confirmButtonText: "OK",
        }).then((result) => {});
    }
};

export const postDataCareers = async (body, language = "en") => {
    try {
        const formData = new FormData()
        formData.append('fullname', body.fullname)
        formData.append('nationality', body.nationality)
        formData.append('gender', body.gender)
        formData.append('dateofbirth', body.dateofbirth)
        formData.append('phonenumber', body.phonenumber)
        formData.append('address', body.address)
        formData.append('email', body.email)
        formData.append('education_status', body.education_status)
        formData.append('degree', body.degree)
        formData.append('major', body.major)
        formData.append('job_id', body.job_id)
        formData.append('education_institute', body.education_institute)
        formData.append('is_receive_news', body.is_receive_news)
        if (body.cv.length > 0) {
            if(body.cv[0].file?.file){
                const blob = new Blob([body.cv[0].file?.file], { type: body.cv[0].file?.file.type });
                const parts = body.cv[0].file?.src.split('/')
                const fileName = parts[parts.length - 1];
                const currentTime = new Date();
                const file = new File([blob], fileName, {
                    type: body.cv[0].file?.file.type,
                    lastModified: currentTime.getTime(),
                    lastModifiedDate: currentTime,
                    size: body.cv[0].file.size
                  });

                formData.append('cv', file)
            }
            else{
                formData.append('cv', body.cv[0].file)
            }
        }
        else{
            formData.append('cv', null)
        }

        if (body.img.length > 0) {
            if(body.img[0].file?.file){
                const blob = new Blob([body.img[0].file?.file], { type: body.img[0].file?.file.type });
                const parts = body.img[0].file?.src.split('/')
                const fileName = parts[parts.length - 1];
                const currentTime = new Date();
                const file = new File([blob], fileName, {
                    type: body.img[0].file?.file.type,
                    lastModified: currentTime.getTime(),
                    lastModifiedDate: currentTime,
                    size: body.cv[0].file.size
                  });

                formData.append('img', file)
            }
            else{
                formData.append('img', body.img[0].file)
            }
        }
        else{
            formData.append('img', null)
        }

        const headers = {
            language: language // Set the language here
        };
        const response = await instance.post(`/api/landing/job`,formData, {
            headers: headers,
        });
        if (response?.status === 200) {
            return response;
        } else {
            return null;
        }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: error.response.data.message,
            confirmButtonText: "ตกลง",
        }).then((result) => {});
    }
};