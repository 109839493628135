import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './Bidding.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Button, Col, Row } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataBidding } from '../../api/bidding_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link, useNavigate } from 'react-router-dom';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import { useTranslation } from "react-i18next"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function Bidding() {
  const { viewportSize } = useMediaQueryHook();
  const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
  const { lang } = useContext(ChangeLanguageContext);
  const { t } = useTranslation();

  const [dataId, setDataId] = useState(null);
  const [activeTab, setActiveTab] = useState('bidInfo');
  const [headBanner, setHeadBanner] = useState({
    original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    description1: "OPEN TENDERING PROJECT",
    isVideo: "image",
  });

  const handleTabClick = (tab) => {
      setActiveTab(tab);
  };

  const [listDatasInfo, setListDatasInfo] = useState([
    {
      _id: '',
      txtName: '',
      effectiveDate: '',
      endDate: '',
      txtDetail: '',
      typeName: '',
      isOpen: false // Initially set to false for all items
    },
    {
      txtName: '',
      effectiveDate: '',
      endDate: '',
      txtDetail: '',
      typeName: '',
      isOpen: false // Initially set to false for all items
    },
  ]);
  const [listDatasWinning, setListDatasWinning] = useState([
    {
      txtName: '',
      effectiveDate: '',
      endDate: '',
      txtDetail: '',
      typeName: '',
      isOpen: false // Initially set to false for all items
    },
    {
      txtName: '',
      effectiveDate: '',
      endDate: '',
      txtDetail: '',
      typeName: '',
      isOpen: false // Initially set to false for all items
    },
  ]);

  const navigate = useNavigate();

  const handleSeeMoreDetail = (id) => {
    navigate(("/biddingDetail?id=") + id);
  };

  const toggleDescription01 = (index) => {
    const newListDatas = [...listDatasInfo];
    newListDatas[index] = {
      ...newListDatas[index],
      isOpen: !newListDatas[index].isOpen
    };
    setListDatasInfo(newListDatas)
  };

  const toggleDescription02 = (index) => {
    const newListDatas = [...listDatasWinning];
    newListDatas[index] = {
      ...newListDatas[index],
      isOpen: !newListDatas[index].isOpen
    };
    setListDatasWinning(newListDatas)
  };

  const [tableParam, setTableParam] = useState({
    page_number:1,
    data_per_page:5,
  }); //กำหนด column เริ่มต้นในการ sort
  const [tableParamInfo, setTableParamInfo] = useState({
    page_number:1,
    data_per_page:5,
  }); //กำหนด column เริ่มต้นในการ sort
  const [totalNumber, setTotalNumber] = useState(0)
  useEffect(()=>{
    fetchingData(lang=='en-US'?'en':lang)
  },[])

  
  const formatDateBasedOnLanguage = (date, language) => {
    return language == 'th' ? formatDateTH(date) : formatDate(date);
  };
    
  const fetchingData = async (language) => {
      try {
        //info get 
          const infoDefault = {
            page_number:1,
            data_per_page:5,
            type:'info'
          }
          const result = await getDataBidding(infoDefault, language);
          setDataId(result?.data?.data?._id)
          const bannerData = result?.data?.data?.banner?.data;
            if (bannerData) {
                const banners = {
                    original: bannerData.data,
                    thumbnail: bannerData.data,
                    isVideo: bannerData.format || "image",
                };
                setHeadBanner(banners);
            } else {
                console.error('Banner data is undefined');
            }
          const infoList = result?.data?.data?.bidding_list;
          if (infoList && infoList.length > 0) {
              const updatedInfoListDatas = infoList.map(detail => ({
                  _id:detail._id,
                  txtName: detail.name,
                  effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                  endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                  txtDetail: detail.description,
                  typeName: detail.type,
              }));
              setListDatasInfo(updatedInfoListDatas);
          } else {
              // Handle case when detail_list is empty
              // You may want to set an empty array or handle it differently
              setListDatasInfo([]);
          }
        //end info get
        //get winning
        const winningDefault = {
          page_number:1,
          data_per_page:5,
          type:'winning'
        }
        const result2 = await getDataBidding(winningDefault, language);
        setDataId(result2?.data?.data?._id)
        const infoList2 = result2?.data?.data?.bidding_list;
        if (infoList2 && infoList2.length > 0) {
            const updatedInfoListDatas2 = infoList2.map(detail => ({
                _id:detail._id,
                txtName: detail.name,
                effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                txtDetail: detail.description,
                typeName: detail.type,
            }));
            setListDatasWinning(updatedInfoListDatas2);
        } else {
            // Handle case when detail_list is empty
            // You may want to set an empty array or handle it differently
            setListDatasWinning([]);
        }
        //end winning get
      } catch (error) {
          console.log('found error', error);
      }
  };

  const handleSeeMore = async (type, language) => {
    try {
      let param = {
        
      };
      if(type=='winning')
      {
        param = {
        page_number: tableParam.page_number, // Increment page number for pagination
        data_per_page: tableParam.data_per_page + 5,
        type:type??'winning'
        };
        setTableParam((prevTableParam) => ({
          ...prevTableParam,
          data_per_page: tableParam.data_per_page+5,
          }));  
      }
      else
      {
        param = {
        page_number: tableParamInfo.page_number, // Increment page number for pagination
        data_per_page: tableParamInfo.data_per_page + 5,
        type:type??'winning'
        };
        setTableParamInfo((prevTableParam) => ({
          ...prevTableParam,
          data_per_page: tableParamInfo.data_per_page+5,
          }));  
      }

      // Now call getDataBidding with the correct param
      const result = await getDataBidding(param,language );

      setTotalNumber(result.data.data.meta.total);
  
      // Assuming the result contains additional data to append to the existing list
      const infoList = result?.data?.data?.bidding_list;
      if (infoList && infoList.length > 0) {
        const updatedInfoListDatas = infoList.map(detail => ({
          _id:detail._id,
          txtName: detail.name,
          effectiveDate: lang == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
          endDate: lang == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
          txtDetail: detail.description,
          typeName: detail.type,
        }));
        // Append the new data to the existing list
        if(type=='info')
          setListDatasInfo(updatedInfoListDatas);
        else
          setListDatasWinning(updatedInfoListDatas);

      } else {
        // Handle case when detail_list is empty
        // You may want to set an empty array or handle it differently
        // Here, we are not modifying the existing list
      }
    } catch (error) {
      console.log('found error', error);
    }
  };

  useEffect(() => {
    
    fetchingData(lang=='en-US'?'en':lang);
  }, [lang])

  return (
    <div>
      <style>
        {`
            ul.menu li a#about_us {
              color: #2D8929;
              text-decoration: underline;
            }
            .main-heading h2 {
              width: 11% !important;
          }
        `}
      </style>
      <Header />
      <div className="container-fluid w-100">
        <div className="row">
          <div className="col-12 px-0">
            <div className="image-gallery-wrapper">
              <div className="position-relative">
                <img src={headBanner.original} alt='banner' className="banner-title" />
                <div className="txt-banner">
                  <p className="fontBold font-size10 colorWhite mb-0 w-fit-content">{t('biding_title')}</p>
                  <p className="bd-bottom-banner"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollAnimation animateIn='fadeInUp'
        initiallyVisible={true}>
        <div className='button-container'>
          <Row className='mt-5 px-10'>
            <Col xs={6} className='d-flex justify-content-end'>
              <Button 
                className={`btn-bidInfo btn-bidding01 fontMed font-size05 ${activeTab === 'bidInfo' ? 'active' : ''}`}
                onClick={() => handleTabClick('bidInfo')}>
                {t('biding_title')}
              </Button>
            </Col>
            <Col xs={6}>
              <Button 
                className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'bidWinn' ? 'active' : ''}`}
                onClick={() => handleTabClick('bidWinn')}>
                {t('bidding_winning_info')}
              </Button>
            </Col>
          </Row>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp'
        initiallyVisible={true}>
        <div className="info-container">
                {activeTab === 'bidInfo' && (
                    <div className="bid-info">
                        {listDatasInfo.length > 0 && ( <div className="mx-10 mt-4">
                          <HeadingSection title={t('biding_head_title')} subTitle={t('biding_sub_title')}
                              position={'start'}
                              customize={{
                                paddingBottom: '2rem',
                                paddingTop: '2rem'
                              }}
                              classCss="pdt-hd pdb-hd"
                              subTitleDetail="d-block"
                          />
                        </div>) }
                        <div className="px-10 mb-5">
                          {  listDatasInfo.length <= 0 ? <>
                                <ScrollAnimation animateIn='fadeIn'
                                animatePreScroll={false}>
                                <div className='sty-col1 m-2 pb-5'>
                                    <div className="col-12 col-xl-12">
                                        <div className='row justify-content-center align-items-center' >
                                            {/* <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{t('news_news_empty_data')}</p> */}
                                            <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{ t('bidding_info_empty_data')}</p>
                                        </div>
                                    </div>
                                </div>
                                </ScrollAnimation>
                          </>:
                          <>{listDatasInfo.map((data, index) => (
                            <div key={index}>
                                <div className="list-detail">
                                  <Row>
                                      <Col xs={8} sm={9} md={10}>
                                          <font className="font-size02 colorBlack01 fontLight">
                                              {index + 1 < 10 ? `0${index + 1}` : index + 1}. 
                                              <span className="fontBold font-size05"> {data.txtName}</span>
                                          </font>
                                      </Col>
                                      <Col xs={4} sm={3} md={2} className="d-flex justify-content-start justify-content-lg-end align-items-center p-0 p-lg-2">
                                        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M20.8904 18.6702H1.75037C1.23037 18.6702 0.820312 18.2501 0.820312 17.7401V2.51013C0.820312 2.00013 1.24037 1.58008 1.75037 1.58008H20.8904C21.4104 1.58008 21.8203 2.00013 21.8203 2.51013V17.7401C21.8203 18.2501 21.4004 18.6702 20.8904 18.6702ZM2.68042 16.8002H19.9603V3.44019H2.68042V16.8002Z" fill="black"/>
                                          <path d="M17.309 4.99048C16.789 4.99048 16.3789 4.57055 16.3789 4.06055V0.950439C16.3789 0.440439 16.799 0.0205078 17.309 0.0205078C17.819 0.0205078 18.2389 0.440439 18.2389 0.950439V4.06055C18.2389 4.57055 17.819 4.99048 17.309 4.99048Z" fill="black"/>
                                          <path d="M5.43005 4.99048C4.91005 4.99048 4.5 4.57055 4.5 4.06055V0.950439C4.5 0.440439 4.92005 0.0205078 5.43005 0.0205078C5.94005 0.0205078 6.35999 0.440439 6.35999 0.950439V4.06055C6.35999 4.57055 5.94005 4.99048 5.43005 4.99048Z" fill="black"/>
                                          <path d="M20.8401 8.01025H1.88013C1.62013 8.01025 1.41016 7.80028 1.41016 7.54028C1.41016 7.28028 1.62013 7.07031 1.88013 7.07031H20.8401C21.1001 7.07031 21.3102 7.28028 21.3102 7.54028C21.3102 7.80028 21.1001 8.01025 20.8401 8.01025Z" fill="black"/>
                                          <path d="M6.11072 9.90039H4.30078V11.7103H6.11072V9.90039Z" fill="black"/>
                                          <path d="M9.23962 9.90039H7.42969V11.7103H9.23962V9.90039Z" fill="black"/>
                                          <path d="M12.3685 9.90039H10.5586V11.7103H12.3685V9.90039Z" fill="black"/>
                                          <path d="M15.5015 9.90039H13.6914V11.7103H15.5015V9.90039Z" fill="black"/>
                                          <path d="M18.6304 9.90039H16.8203V11.7103H18.6304V9.90039Z" fill="black"/>
                                          <path d="M6.11072 12.8105H4.30078V14.6205H6.11072V12.8105Z" fill="black"/>
                                          <path d="M9.23962 12.8105H7.42969V14.6205H9.23962V12.8105Z" fill="black"/>
                                          <path d="M12.3685 12.8105H10.5586V14.6205H12.3685V12.8105Z" fill="black"/>
                                          <path d="M15.5015 12.8105H13.6914V14.6205H15.5015V12.8105Z" fill="black"/>
                                          <path d="M18.6304 12.8105H16.8203V14.6205H18.6304V12.8105Z" fill="black"/>
                                        </svg>
                                        <font className="font-size02 colorBlack01 fontLight ms-1">{data.endDate}</font>
                                      </Col>
                                  </Row>
                                  <Row className="mt-4">
                                      <Col xs={9} md={11} className="ps-5 d-flex align-items-center">
                                          <svg width="23" height="23" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.883 25.3391C6.07297 25.3391 0.542969 19.7991 0.542969 12.9991C0.542969 6.19915 6.08296 0.65918 12.883 0.65918C19.683 0.65918 25.223 6.19915 25.223 12.9991C25.223 19.7991 19.683 25.3391 12.883 25.3391ZM12.883 2.76917C7.24297 2.76917 2.65295 7.35915 2.65295 12.9991C2.65295 18.6391 7.24297 23.2291 12.883 23.2291C18.523 23.2291 23.103 18.6391 23.103 12.9991C23.103 7.35915 18.513 2.76917 12.883 2.76917Z" fill="black"/>
                                            <path d="M12.8803 14.0498C12.3003 14.0498 11.8203 13.5797 11.8203 12.9897V5.35974C11.8203 4.77974 12.2903 4.2998 12.8803 4.2998C13.4703 4.2998 13.9403 4.76974 13.9403 5.35974V12.9897C13.9403 13.5697 13.4703 14.0498 12.8803 14.0498Z" fill="black"/>
                                            <path d="M12.8803 3.45935C12.3003 3.45935 11.8203 2.98941 11.8203 2.39941V1.70935C11.8203 1.12935 12.2903 0.649414 12.8803 0.649414C13.4703 0.649414 13.9403 1.11935 13.9403 1.70935V2.39941C13.9403 2.97941 13.4703 3.45935 12.8803 3.45935Z" fill="black"/>
                                            <path d="M12.8803 25.3392C12.3003 25.3392 11.8203 24.8693 11.8203 24.2793V23.5892C11.8203 23.0092 12.2903 22.5293 12.8803 22.5293C13.4703 22.5293 13.9403 22.9992 13.9403 23.5892V24.2793C13.9403 24.8593 13.4703 25.3392 12.8803 25.3392Z" fill="black"/>
                                            <path d="M24.1602 14.0497H23.4702C22.8902 14.0497 22.4102 13.5796 22.4102 12.9896C22.4102 12.3996 22.8802 11.9297 23.4702 11.9297H24.1602C24.7402 11.9297 25.2202 12.3996 25.2202 12.9896C25.2202 13.5796 24.7502 14.0497 24.1602 14.0497Z" fill="black"/>
                                            <path d="M2.28125 14.0497H1.59125C1.01125 14.0497 0.53125 13.5796 0.53125 12.9896C0.53125 12.3996 1.00125 11.9297 1.59125 11.9297H2.28125C2.86125 11.9297 3.34125 12.3996 3.34125 12.9896C3.34125 13.5796 2.87125 14.0497 2.28125 14.0497Z" fill="black"/>
                                            <path d="M17.694 5.1887C17.604 5.1887 17.514 5.16864 17.434 5.11864C17.184 4.96864 17.094 4.64867 17.234 4.39867L17.544 3.85863C17.694 3.60863 18.014 3.51869 18.264 3.66869C18.514 3.81869 18.604 4.13866 18.454 4.38866L18.144 4.9287C18.044 5.0987 17.874 5.19859 17.684 5.19859L17.694 5.1887Z" fill="black"/>
                                            <path d="M7.76434 22.399C7.67434 22.399 7.58433 22.3789 7.50433 22.3289C7.25433 22.1789 7.16432 21.8589 7.30432 21.6089L7.61431 21.069C7.76431 20.809 8.08431 20.7289 8.33431 20.8689C8.58431 21.0189 8.67432 21.3389 8.52432 21.5889L8.21432 22.1289C8.11432 22.2989 7.94433 22.399 7.75433 22.399H7.76434Z" fill="black"/>
                                            <path d="M21.7535 18.6483C21.6635 18.6483 21.5735 18.6282 21.4935 18.5782L20.9535 18.2683C20.7035 18.1183 20.6135 17.7983 20.7535 17.5483C20.9035 17.2983 21.2235 17.2082 21.4735 17.3482L22.0135 17.6583C22.2635 17.8083 22.3535 18.1283 22.2035 18.3783C22.1035 18.5483 21.9335 18.6483 21.7435 18.6483H21.7535Z" fill="black"/>
                                            <path d="M4.55432 8.70875C4.46432 8.70875 4.37434 8.6888 4.29434 8.6388L3.75433 8.32874C3.50433 8.17874 3.41432 7.85877 3.55432 7.60877C3.69432 7.35877 4.02432 7.26882 4.27432 7.40882L4.81433 7.71876C5.06433 7.86876 5.15434 8.18885 5.01434 8.43885C4.91434 8.60885 4.74432 8.70875 4.55432 8.70875Z" fill="black"/>
                                            <path d="M8.07256 5.18895C7.89256 5.18895 7.71254 5.08893 7.61254 4.91893L7.30254 4.37889C7.16254 4.12889 7.24255 3.79892 7.50255 3.65892C7.75255 3.50892 8.07255 3.59886 8.22255 3.84886L8.53255 4.3889C8.68255 4.6389 8.59255 4.96887 8.34255 5.10887C8.26255 5.15887 8.17254 5.17894 8.08254 5.17894L8.07256 5.18895Z" fill="black"/>
                                            <path d="M18.011 22.3986C17.831 22.3986 17.6509 22.3086 17.5509 22.1386L17.2409 21.5986C17.0909 21.3486 17.1809 21.0186 17.4309 20.8786C17.6809 20.7286 18.0009 20.8187 18.1509 21.0687L18.4609 21.6086C18.6109 21.8586 18.5209 22.1886 18.2709 22.3286C18.1909 22.3786 18.0909 22.3986 18.0009 22.3986H18.011Z" fill="black"/>
                                            <path d="M4.01256 18.6494C3.83256 18.6494 3.65254 18.5494 3.55254 18.3794C3.41254 18.1294 3.49255 17.7995 3.75255 17.6595L4.29256 17.3494C4.54256 17.2094 4.86256 17.2895 5.01256 17.5495C5.15256 17.7995 5.07255 18.1294 4.81255 18.2694L4.27254 18.5794C4.19254 18.6294 4.10256 18.6494 4.01256 18.6494Z" fill="black"/>
                                            <path d="M21.2244 8.7086C21.0444 8.7086 20.8644 8.61859 20.7644 8.44859C20.6144 8.19859 20.7044 7.86862 20.9544 7.72862L21.4944 7.41869C21.7444 7.26869 22.0744 7.35863 22.2144 7.60863C22.3644 7.85863 22.2744 8.1886 22.0244 8.3286L21.4844 8.63866C21.4044 8.68866 21.3144 8.7086 21.2244 8.7086Z" fill="black"/>
                                            <path d="M9.6825 17.249C9.4125 17.249 9.1425 17.1491 8.9325 16.9391C8.5225 16.5291 8.5225 15.8591 8.9325 15.4391L12.0025 12.369C12.4125 11.959 13.0825 11.959 13.5025 12.369C13.9125 12.779 13.9125 13.449 13.5025 13.869L10.4325 16.9391C10.2225 17.1491 9.9525 17.249 9.6825 17.249Z" fill="black"/>
                                          </svg>
                                          <font className="font-size02 colorBlack01 fontLight ms-3">
                                          {t('startDate')} {data.effectiveDate}
                                          </font>
                                      </Col>
                                      <Col xs={3} md={1} className="d-flex justify-content-end">
                                        <div id="toggle-button" className="inner-circle font-size08" onClick={() => toggleDescription01(index)}>
                                          {data.isOpen ? '-' : '+'}
                                        </div>
                                      </Col>
                                      {data.isOpen && (
                                        <div className="list-detail style-qual">
                                          <Row className="ms-3 w-95">
                                            <Col xs={12}><font className="font-size02 colorBlack01 fontBold">Briefing</font></Col>
                                          </Row>
                                          <Row className="ms-3 w-95">
                                            <Col xs={12} className="font-size02"><font className="font-size02 colorBlack01 fontLight" dangerouslySetInnerHTML={{__html:data.txtDetail}}/></Col>
                                          </Row>
                                          <Row className="ms-3 w-95 mt-4">
                                            <Col xs={12}>
                                              <Button className="btn-apply font-size02 fontBold" onClick={() => handleSeeMoreDetail(data._id)}>See More</Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      )}
                                  </Row>
                                </div>
                            </div>
                          ))}
                          <Row className="mt-4">
                            <Col xs={12} className="d-flex justify-content-center align-items-center">
                            <Button className="btn-apply font-size02 fontMed" onClick={()=>handleSeeMore('info',lang=='en-US'?'en':lang )}>
                                SEE MORE&nbsp;
                                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.7491 26.8896C20.9067 26.8896 26.709 21.0873 26.709 13.9297C26.709 6.77208 20.9067 0.969727 13.7491 0.969727C6.59147 0.969727 0.789062 6.77208 0.789062 13.9297C0.789062 21.0873 6.59147 26.8896 13.7491 26.8896Z" fill="black"/>
                                  <path d="M13.1094 9.99023L17.0494 13.9302L13.1094 17.8704" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                              </Button>
                            </Col>
                          </Row></>
                          }
                        </div>
                    </div>
                )}
                {activeTab === 'bidWinn' && (
                    <div className="bid-winn">
                        {listDatasInfo.length > 0   && (<div className="mx-10 mt-4">
                          <HeadingSection title={t('bidingWin_head_title')} subTitle={t('bidingWin_sub_title')}
                              position={'start'}
                              customize={{
                                paddingBottom: '2rem',
                                paddingTop: '2rem'
                              }}
                              classCss="pdt-hd pdb-hd"
                              subTitleDetail="d-block"
                          />
                        </div>)}
                        
                        <div className="px-10 mb-5">
                          {listDatasWinning.length <= 0 ? (
                            <>
                              {
                                <ScrollAnimation animateIn='fadeIn'
                                animatePreScroll={false}>
                                <div className='sty-col1 m-5 pb-5'>
                                    <div className="col-12 col-xl-12">
                                        <div className='row justify-content-center align-items-center' >
                                            {/* <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{t('news_news_empty_data')}</p> */}
                                            <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{ t('bidding_wining_empty_data')}</p>
                                        </div>
                                    </div>
                                </div>
                                </ScrollAnimation>
                              }
                              </>

                          ) : (
                          <>
                            { listDatasWinning.map((data, index) => (
                              <div key={index}>
                                  <div className="list-detail">
                                    <Row>
                                        <Col xs={8} sm={9} md={10}>
                                            <font className="font-size02 colorBlack01 fontLight">
                                                {index + 1 < 10 ? `0${index + 1}` : index + 1}. 
                                                <span className="fontBold font-size05"> {data.txtName}</span>
                                            </font>
                                        </Col>
                                        <Col xs={4} sm={3} md={2} className="d-flex justify-content-end align-items-center">
                                          <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.8904 18.6702H1.75037C1.23037 18.6702 0.820312 18.2501 0.820312 17.7401V2.51013C0.820312 2.00013 1.24037 1.58008 1.75037 1.58008H20.8904C21.4104 1.58008 21.8203 2.00013 21.8203 2.51013V17.7401C21.8203 18.2501 21.4004 18.6702 20.8904 18.6702ZM2.68042 16.8002H19.9603V3.44019H2.68042V16.8002Z" fill="black"/>
                                            <path d="M17.309 4.99048C16.789 4.99048 16.3789 4.57055 16.3789 4.06055V0.950439C16.3789 0.440439 16.799 0.0205078 17.309 0.0205078C17.819 0.0205078 18.2389 0.440439 18.2389 0.950439V4.06055C18.2389 4.57055 17.819 4.99048 17.309 4.99048Z" fill="black"/>
                                            <path d="M5.43005 4.99048C4.91005 4.99048 4.5 4.57055 4.5 4.06055V0.950439C4.5 0.440439 4.92005 0.0205078 5.43005 0.0205078C5.94005 0.0205078 6.35999 0.440439 6.35999 0.950439V4.06055C6.35999 4.57055 5.94005 4.99048 5.43005 4.99048Z" fill="black"/>
                                            <path d="M20.8401 8.01025H1.88013C1.62013 8.01025 1.41016 7.80028 1.41016 7.54028C1.41016 7.28028 1.62013 7.07031 1.88013 7.07031H20.8401C21.1001 7.07031 21.3102 7.28028 21.3102 7.54028C21.3102 7.80028 21.1001 8.01025 20.8401 8.01025Z" fill="black"/>
                                            <path d="M6.11072 9.90039H4.30078V11.7103H6.11072V9.90039Z" fill="black"/>
                                            <path d="M9.23962 9.90039H7.42969V11.7103H9.23962V9.90039Z" fill="black"/>
                                            <path d="M12.3685 9.90039H10.5586V11.7103H12.3685V9.90039Z" fill="black"/>
                                            <path d="M15.5015 9.90039H13.6914V11.7103H15.5015V9.90039Z" fill="black"/>
                                            <path d="M18.6304 9.90039H16.8203V11.7103H18.6304V9.90039Z" fill="black"/>
                                            <path d="M6.11072 12.8105H4.30078V14.6205H6.11072V12.8105Z" fill="black"/>
                                            <path d="M9.23962 12.8105H7.42969V14.6205H9.23962V12.8105Z" fill="black"/>
                                            <path d="M12.3685 12.8105H10.5586V14.6205H12.3685V12.8105Z" fill="black"/>
                                            <path d="M15.5015 12.8105H13.6914V14.6205H15.5015V12.8105Z" fill="black"/>
                                            <path d="M18.6304 12.8105H16.8203V14.6205H18.6304V12.8105Z" fill="black"/>
                                          </svg>
                                          <font className="font-size02 colorBlack01 fontLight ms-1">{data.endDate}</font>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs={9} md={11} className="ps-5 d-flex align-items-center">
                                            <svg width="23" height="23" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M12.883 25.3391C6.07297 25.3391 0.542969 19.7991 0.542969 12.9991C0.542969 6.19915 6.08296 0.65918 12.883 0.65918C19.683 0.65918 25.223 6.19915 25.223 12.9991C25.223 19.7991 19.683 25.3391 12.883 25.3391ZM12.883 2.76917C7.24297 2.76917 2.65295 7.35915 2.65295 12.9991C2.65295 18.6391 7.24297 23.2291 12.883 23.2291C18.523 23.2291 23.103 18.6391 23.103 12.9991C23.103 7.35915 18.513 2.76917 12.883 2.76917Z" fill="black"/>
                                              <path d="M12.8803 14.0498C12.3003 14.0498 11.8203 13.5797 11.8203 12.9897V5.35974C11.8203 4.77974 12.2903 4.2998 12.8803 4.2998C13.4703 4.2998 13.9403 4.76974 13.9403 5.35974V12.9897C13.9403 13.5697 13.4703 14.0498 12.8803 14.0498Z" fill="black"/>
                                              <path d="M12.8803 3.45935C12.3003 3.45935 11.8203 2.98941 11.8203 2.39941V1.70935C11.8203 1.12935 12.2903 0.649414 12.8803 0.649414C13.4703 0.649414 13.9403 1.11935 13.9403 1.70935V2.39941C13.9403 2.97941 13.4703 3.45935 12.8803 3.45935Z" fill="black"/>
                                              <path d="M12.8803 25.3392C12.3003 25.3392 11.8203 24.8693 11.8203 24.2793V23.5892C11.8203 23.0092 12.2903 22.5293 12.8803 22.5293C13.4703 22.5293 13.9403 22.9992 13.9403 23.5892V24.2793C13.9403 24.8593 13.4703 25.3392 12.8803 25.3392Z" fill="black"/>
                                              <path d="M24.1602 14.0497H23.4702C22.8902 14.0497 22.4102 13.5796 22.4102 12.9896C22.4102 12.3996 22.8802 11.9297 23.4702 11.9297H24.1602C24.7402 11.9297 25.2202 12.3996 25.2202 12.9896C25.2202 13.5796 24.7502 14.0497 24.1602 14.0497Z" fill="black"/>
                                              <path d="M2.28125 14.0497H1.59125C1.01125 14.0497 0.53125 13.5796 0.53125 12.9896C0.53125 12.3996 1.00125 11.9297 1.59125 11.9297H2.28125C2.86125 11.9297 3.34125 12.3996 3.34125 12.9896C3.34125 13.5796 2.87125 14.0497 2.28125 14.0497Z" fill="black"/>
                                              <path d="M17.694 5.1887C17.604 5.1887 17.514 5.16864 17.434 5.11864C17.184 4.96864 17.094 4.64867 17.234 4.39867L17.544 3.85863C17.694 3.60863 18.014 3.51869 18.264 3.66869C18.514 3.81869 18.604 4.13866 18.454 4.38866L18.144 4.9287C18.044 5.0987 17.874 5.19859 17.684 5.19859L17.694 5.1887Z" fill="black"/>
                                              <path d="M7.76434 22.399C7.67434 22.399 7.58433 22.3789 7.50433 22.3289C7.25433 22.1789 7.16432 21.8589 7.30432 21.6089L7.61431 21.069C7.76431 20.809 8.08431 20.7289 8.33431 20.8689C8.58431 21.0189 8.67432 21.3389 8.52432 21.5889L8.21432 22.1289C8.11432 22.2989 7.94433 22.399 7.75433 22.399H7.76434Z" fill="black"/>
                                              <path d="M21.7535 18.6483C21.6635 18.6483 21.5735 18.6282 21.4935 18.5782L20.9535 18.2683C20.7035 18.1183 20.6135 17.7983 20.7535 17.5483C20.9035 17.2983 21.2235 17.2082 21.4735 17.3482L22.0135 17.6583C22.2635 17.8083 22.3535 18.1283 22.2035 18.3783C22.1035 18.5483 21.9335 18.6483 21.7435 18.6483H21.7535Z" fill="black"/>
                                              <path d="M4.55432 8.70875C4.46432 8.70875 4.37434 8.6888 4.29434 8.6388L3.75433 8.32874C3.50433 8.17874 3.41432 7.85877 3.55432 7.60877C3.69432 7.35877 4.02432 7.26882 4.27432 7.40882L4.81433 7.71876C5.06433 7.86876 5.15434 8.18885 5.01434 8.43885C4.91434 8.60885 4.74432 8.70875 4.55432 8.70875Z" fill="black"/>
                                              <path d="M8.07256 5.18895C7.89256 5.18895 7.71254 5.08893 7.61254 4.91893L7.30254 4.37889C7.16254 4.12889 7.24255 3.79892 7.50255 3.65892C7.75255 3.50892 8.07255 3.59886 8.22255 3.84886L8.53255 4.3889C8.68255 4.6389 8.59255 4.96887 8.34255 5.10887C8.26255 5.15887 8.17254 5.17894 8.08254 5.17894L8.07256 5.18895Z" fill="black"/>
                                              <path d="M18.011 22.3986C17.831 22.3986 17.6509 22.3086 17.5509 22.1386L17.2409 21.5986C17.0909 21.3486 17.1809 21.0186 17.4309 20.8786C17.6809 20.7286 18.0009 20.8187 18.1509 21.0687L18.4609 21.6086C18.6109 21.8586 18.5209 22.1886 18.2709 22.3286C18.1909 22.3786 18.0909 22.3986 18.0009 22.3986H18.011Z" fill="black"/>
                                              <path d="M4.01256 18.6494C3.83256 18.6494 3.65254 18.5494 3.55254 18.3794C3.41254 18.1294 3.49255 17.7995 3.75255 17.6595L4.29256 17.3494C4.54256 17.2094 4.86256 17.2895 5.01256 17.5495C5.15256 17.7995 5.07255 18.1294 4.81255 18.2694L4.27254 18.5794C4.19254 18.6294 4.10256 18.6494 4.01256 18.6494Z" fill="black"/>
                                              <path d="M21.2244 8.7086C21.0444 8.7086 20.8644 8.61859 20.7644 8.44859C20.6144 8.19859 20.7044 7.86862 20.9544 7.72862L21.4944 7.41869C21.7444 7.26869 22.0744 7.35863 22.2144 7.60863C22.3644 7.85863 22.2744 8.1886 22.0244 8.3286L21.4844 8.63866C21.4044 8.68866 21.3144 8.7086 21.2244 8.7086Z" fill="black"/>
                                              <path d="M9.6825 17.249C9.4125 17.249 9.1425 17.1491 8.9325 16.9391C8.5225 16.5291 8.5225 15.8591 8.9325 15.4391L12.0025 12.369C12.4125 11.959 13.0825 11.959 13.5025 12.369C13.9125 12.779 13.9125 13.449 13.5025 13.869L10.4325 16.9391C10.2225 17.1491 9.9525 17.249 9.6825 17.249Z" fill="black"/>
                                            </svg>
                                            <font className="font-size02 colorBlack01 fontLight ms-3">
                                            {t('startDate')} {data.effectiveDate} 
                                            </font>
                                        </Col>
                                        <Col xs={3} md={1} className="d-flex justify-content-end">
                                          <div id="toggle-button" className="inner-circle font-size08" onClick={() => toggleDescription02(index)}>
                                            {data.isOpen ? '-' : '+'}
                                          </div>
                                        </Col>
                                        {data.isOpen && (
                                          <div className="list-detail style-qual">
                                            <Row className="ms-3 w-95">
                                              <Col xs={12}><font className="font-size02 colorBlack01 fontBold">Briefing</font></Col>
                                            </Row>
                                            <Row className="ms-3 w-95">
                                              <Col xs={12} className="font-size02"><font className="font-size02 colorBlack01 fontLight" dangerouslySetInnerHTML={{__html: data.txtDetail }} /></Col>
                                            </Row>
                                            <Row className="ms-3 w-95 mt-4">
                                              <Col xs={12}>
                                                <Button className="btn-apply font-size02 fontBold" onClick={() => handleSeeMoreDetail(data._id)}>See More</Button>
                                              </Col>
                                            </Row>
                                          </div>
                                        )}
                                    </Row>
                                  </div>
                              </div>
                            ))}

                            <Row className="mt-4">
                              <Col xs={12} className="d-flex justify-content-center align-items-center">
                                <Button className="btn-apply font-size02 fontMed" onClick={()=>handleSeeMore('winning', lang=='en-US'?'en':lang)}>
                                  SEE MORE&nbsp;
                                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7491 26.8896C20.9067 26.8896 26.709 21.0873 26.709 13.9297C26.709 6.77208 20.9067 0.969727 13.7491 0.969727C6.59147 0.969727 0.789062 6.77208 0.789062 13.9297C0.789062 21.0873 6.59147 26.8896 13.7491 26.8896Z" fill="black"/>
                                    <path d="M13.1094 9.99023L17.0494 13.9302L13.1094 17.8704" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </Button>
                              </Col>
                            </Row>
                          </>
                          )}
                        </div>
                    </div>
                )}
        </div>
        </ScrollAnimation>
      </div>
      <div>
            <ScrollToTopButton />
      </div>
      <Footer />
    </div>
  )
}

export default Bidding;