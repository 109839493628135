import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "./slick.css";
import { Link } from "react-router-dom";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";

function CenterMode({ data }) {
    const sliderRef = useRef();
    const sliderRef2 = useRef();
    const sliderRef3 = useRef();
    const { viewportSize } = useMediaQueryHook();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false, // Hide the arrow navigation
        centerMode: viewportSize?.width <= 1204 ? false : true,
        centerPadding: viewportSize?.width <= 1204 ? 0 : 0,
    };

    const next = () => {
        sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current.slickPrev();
    };

    const next2 = () => {
        sliderRef2.current.slickNext();
    };

    const prev2 = () => {
        sliderRef2.current.slickPrev();
    };

    const next3 = () => {
        sliderRef3.current.slickNext();
    };

    const prev3 = () => {
        sliderRef3.current.slickPrev();
    };

    return (
        <>       

        {/* start col2 */}
        <div className="slider-container default-font">
            <style>
                {`
                    .slider-container {
                        position: relative !important;
                    }
                    .slick-track {
                        display: flex;
                        flex-direction: row;
                    }                    
                `}
            </style>
            {
                data.length > 1 ?
                        
                        <>
                        {data[1].images.length>1?
                        <> 
                        <Slider ref={sliderRef2} {...settings}>
                        {data[1].images.map((imgSrc, imgIndex) => (

                        <div
                            key={1}
                            className="w-100"
                            style={{ position: 'relative', userSelect: 'none' }}
                        >
                            <div className="slide position-relative">
                                <div className="promo-item position-relative">
                                        <img key={imgIndex} src={imgSrc} width={'100%'} alt={`img-${imgIndex}`} />
                                </div>
                                <div className="container">
                               
                                </div>
                            </div>
                        </div>
                            ))}
                        </Slider>
                        <div className="promo-content1 w-100">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <font className="fontLight font-size02"><div dangerouslySetInnerHTML={{ __html: data[1].txtDesc}}></div></font>
                                </div>
                            </div>
                        </div>
                        </>
                        :<>
                            <div
                                key={1}
                                className="w-100"
                                style={{ position: 'relative', userSelect: 'none' }}
                            >
                                <div className="slide position-relative">
                                    <div className="promo-item position-relative">
                                            <img key={0} src={data[1].images[0]} width={'100%'} alt={`img-${0}`} />
                                    </div>
                                    <div className="container">
                                
                                    </div>
                                </div>
                            </div>
                            <div className="promo-content1 w-100">
                            <div className="row mt-3 mb-4">
                                <div className="col-12">
                                    <font className="fontLight font-size02"><div dangerouslySetInnerHTML={{ __html: data[1].txtDesc}}></div></font>
                                </div>
                            </div>
                        </div>
                        </>
                        }
                        </>
                   
                   : null 
            }
            {
                data[1].images.length > 1 &&
                <div className="container">
                     <div className="row">
                                    <div className="col-12 col-md-12 col-xl-6 d-flex justify-content-center mt-3">
                                        <div className="box-arr01">
                                            <svg onClick={prev2} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="box-arr02">
                                            <svg onClick={next2} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                     </div>
                </div>
            }
        </div>
        {/* end col2 */}

        {/* start col3 */}
        <div className="slider-container default-font">           
            {
                data.length > 2 ?
                        
                        <>
                        {data[2].images.length>1?
                        <> 
                        <Slider ref={sliderRef3} {...settings}>
                        {data[2].images.map((imgSrc, imgIndex) => (

                        <div
                            key={2}
                            className="w-100"
                            style={{ position: 'relative', userSelect: 'none' }}
                        >
                            <div className="slide position-relative">
                                <div className="promo-item position-relative">
                                        <img key={imgIndex} src={imgSrc} width={'100%'} alt={`img-${imgIndex}`} />
                                </div>
                                <div className="container">
                               
                                </div>
                            </div>
                        </div>
                            ))}
                        </Slider>
                        <div className="promo-content1 w-100">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <font className="fontLight font-size02"><div dangerouslySetInnerHTML={{ __html: data[2].txtDesc}}></div></font>
                                </div>
                            </div>
                        </div>
                        </>
                        :<>
                            <div
                                key={2}
                                className="w-100"
                                style={{ position: 'relative', userSelect: 'none' }}
                            >
                                <div className="slide position-relative">
                                    <div className="promo-item position-relative">
                                            <img key={0} src={data[2].images[0]} width={'100%'} alt={`img-${0}`} />
                                    </div>
                                    <div className="container">
                                
                                    </div>
                                </div>
                            </div>
                            <div className="promo-content1 w-100">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <font className="fontLight font-size02"><div dangerouslySetInnerHTML={{ __html: data[2].txtDesc}}></div></font>
                                </div>
                            </div>
                        </div>
                        </>
                        }
                        </>
                   
                   : null 
            }
            {
                data[2].images.length > 1 &&
                <div className="container">
                     <div className="row">
                                    <div className="col-12 col-md-12 col-xl-6 d-flex justify-content-center mt-3">
                                        <div className="box-arr01">
                                            <svg onClick={prev3} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="box-arr02">
                                            <svg onClick={next3} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                     </div>
                </div>
            }
        </div>
        {/* end col3 */}
        </>
    );
}

export default CenterMode;