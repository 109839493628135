export const formatDate = (isoDateTime) => {
  const date = new Date(isoDateTime);
    
  // Array of month abbreviations
  const monthAbbreviations = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  // Extract the date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = monthAbbreviations[date.getMonth()]; // Get the month abbreviation
  const year = String(date.getFullYear()).slice(0); // Get the full year

  // Format the date as "DD/MMM/YYYY"
  const formattedDate = `${day} ${month} ${year}`;
    
  return formattedDate;
};

export const formatDateTH = (isoDateTime) => {
  const date = new Date(isoDateTime);
    
  // Array of month abbreviations
  const monthAbbreviations = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

  // Extract the date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = monthAbbreviations[date.getMonth()]; // Get the month abbreviation
  const year = String(date.getFullYear()).slice(0); // Get the full year

  // Format the date as "DD/MMM/YYYY"
  const formattedDate = `${day} ${month} ${year}`;
    
  return formattedDate;
};