import React from 'react';
import './ShareSocial.css'
import lineIcon from '../../assets/images/ic-line.png';

const LineShare = ({ url, text }) => {
  const shareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

  return (
    <a href={shareUrl} target="_blank" rel="noopener noreferrer">
        <img src={lineIcon} className='ic-social' alt='ic-line' />
    </a>
  );
};

export default LineShare;