import "../components/swalStyle/customStyle.css";
import { getInstance } from "./base_api";
import Swal from "sweetalert2";

const instance = getInstance();

export const submitEnquiry = async (queryparams, language = "en", body) => {
    try {
        const headers = {
            language: language // Set the language here
        };
      const response = await instance.post(`/api/landing/enquiry`, body, {
        headers: headers,
        params: { ...queryparams }
      });
  
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        confirmButtonText: "OK",
      }).then((result) => {});
    }
};