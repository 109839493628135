import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './Contact.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataContact } from '../../api/contact_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import { contacts } from "../Home/constants"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

import ContactForm from '../../components/contactForm/ContactForm'
import { useTranslation } from 'react-i18next';

function ContactUs() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;

    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext);
    
    const [headBanner, setHeadBanner] = useState({
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "CONTACT US",
        isVideo: "image",
    });
    const [inputForm, setInputForm] = useState({
        txtAllSeason: "All Seasons Property Co., Ltd.",
        txtAddress1: "19th Floor, M. Thai Tower, All Seasons Place,",
        txtAddress2: "87 Wireless Road, Phatumwan, Bangkok 10330, Thailand",
        fax: "",
        tel:"",
        phone: "",
        message: "",
        email: ""
    });

    const [mapImg, setMapImg] = useState();

    const [listDatas, setListDatas] = useState([
        {
            icon: <svg width={70} height={68} viewBox="0 0 70 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M55.886 17.4642V25.8814M55.886 17.4642H42.8056C41.4956 17.4642 40.4398 16.3876 40.4398 15.0956V2M55.886 17.4642L40.4398 2M55.886 51.7791V63.9938C55.886 65.3054 54.8107 66.3624 53.5202 66.3624H16.0385C14.7285 66.3624 13.6727 65.2858 13.6727 63.9938V51.7791M55.886 51.7791H67.5392V26.3904H2V51.7791H13.6532L13.7705 43.4402H55.7492L55.886 51.7791ZM13.6727 26.0772V4.36857C13.6727 3.05705 14.7481 2 16.0385 2H40.4398M21.3372 57.8277H48.202M21.3372 51.3484H48.202" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            ,
            text: "66 (0) 2625-1899",
            path: "tel:+6626251899"
        },
        {
            icon: <svg width={69} height={68} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.6577 61.7036C42.9006 61.0772 47.202 59.5895 50.0958 56.6924C53.6934 53.0906 56.4894 47.0028 56.4894 41.5414V22.9843C56.4894 17.5229 53.6934 12.0028 50.0958 8.40101C46.4982 4.79922 40.9844 2 35.5294 2H32.8116C27.3565 2 21.8428 4.79922 18.2452 8.40101C14.6475 12.0028 11.8516 17.5229 11.8516 22.9843V41.5414" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.2091 41.8155C6.06687 41.424 2 36.8435 2 31.245C2 25.6466 6.06687 21.0661 11.2091 20.6746M57.0983 41.8351C62.2405 41.4436 66.3074 36.863 66.3074 31.2646C66.3074 25.6662 62.2405 21.0856 57.0983 20.6941" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M34.1455 57.26C36.6286 57.26 38.6425 59.2762 38.6425 61.7622C38.6425 64.2483 36.6286 66.2645 34.1455 66.2645C31.6623 66.2645 29.6484 64.2483 29.6484 61.7622C29.6484 59.2762 31.6623 57.26 34.1455 57.26Z" stroke="#3EA33A" strokeWidth="3.44" strokeMiterlimit="22.93" />
            </svg>
            ,
            text: "66 (0) 2625-1800",
            path: "tel:+6626251800"
        },
        {
            icon: <svg width={68} height={68} viewBox="0 0 68 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M66.2878 2V41.3261H2V2M66.2878 2H2M66.2878 2L34.1439 28.3479L2 2M65.6035 40.3669L42.219 22.632M2.66478 40.3669L26.0493 22.632" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
            </svg>,
            text: "info@asp.th.com",
            path: "mailto:info@asp.th.com"
        },
    ]);

    const fetchingData = async (language) => {
        try {
            const result = await getDataContact({}, language);
            setMapImg(result?.data?.data?.contentdata?.map_img);
            const bannerData = result?.data?.data?.banner?.data;
            if (bannerData) {
                const banners = {
                    original: bannerData.data,
                    thumbnail: bannerData.data,
                    isVideo: bannerData.format || "image",
                };
                setHeadBanner(banners);
            } else {
                console.error('Banner data is undefined');
            }
            let listContact =  [...listDatas]
            listContact[0].text = result?.data?.data?.contentdata?.fax??listContact[0].text
            listContact[1].text = result?.data?.data?.contentdata?.tel??listContact[1].text
            listContact[2].text = result?.data?.data?.contentdata?.email??listContact[2].text
            setListDatas(listContact);

            const inputData = result?.data?.data?.contentdata;
            if (inputData) {
                const inputAddr = {
                    txtAddress1: inputData.contact_addr,
                    fax: inputData.fax,
                    email: inputData.email,
                    tel: inputData.tel,
                    phone: inputData.mobile
                };
                setInputForm(inputAddr);
            } else {
                console.error('setInputForm data is undefined');
            }
            
        } catch (error) {
            console.log('found error', error);
        }
    };
    
    useEffect(() => {
        
        fetchingData(lang=='en-US'?'en':lang)
    }, [lang])

  return (
    <div>
        <style>
            {`
                ul.menu li a#contact_us {
                color: #3AF823;
                text-decoration: underline;
                }
            `}
        </style>
        <Header />
        <div className="container-fluid w-100">
            <div className="row">
                <div className="col-12 px-0">
                    <div className="image-gallery-wrapper">
                        <div className="position-relative">
                            <img src={headBanner.original} alt='banner' className="banner-title" />
                            <div className="txt-banner">
                            <p className="fontBold font-size10 colorWhite mb-0">{t('contact_us')}</p>
                            <p className="bd-bottom-banner"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn='fadeInUp'
                initiallyVisible={true}>
            <div className='row'>
                <HeadingSection title={t('contact_us_title')} subTitle={t('contact_us_subtitle')}
                    position={'center'}
                    customize={{
                        paddingBottom: '2rem',
                        paddingTop: '2rem',
                    }}
                    classCss="pdt-hd pdb-hd"
                    subTitleDetail="d-block"
                />
            </div>
            
                <div className='px-10 mb-5 mx-0 row'>
                    <div className="col-12 col-xl-6 mt-3">
                        {lang=='th'?
                            <div className='fontBold font-size02'>
                                <p>บริษัท ออล ซีซั่นส์ พร็อพเพอร์ตี้ จำกัด</p>
                            </div>
                            :
                            <div className='fontBold font-size02'>
                                <p>
                                All Seasons Property Co., Ltd.
                                </p>
                            </div>
                        }
                        <p className='fontBold font-size02'><div dangerouslySetInnerHTML={{ __html: inputForm.txtAllSeason}}></div></p>
                        <p className='fontReg font-size03'><div dangerouslySetInnerHTML={{ __html: inputForm.txtAddress1}}></div></p>
                        {/* <p className='fontReg font-size03'>{inputForm.txtAddress2}</p> */}
                        <p className='fontReg font-size03'>Office : {inputForm.tel}</p>
                        <p className='fontReg font-size03'>Fax : {inputForm.fax}</p>
                        <p className='fontReg font-size03'>Customer Hotline : {inputForm.phone}</p>
                        <p className='fontReg font-size03'>E-mail : {inputForm.email}</p>
                    </div>
                    <div className="col-12 col-xl-6 d-flex justify-content-between mt-3 flex-wrap align-items-center contact-list-sty">
                        {
                        listDatas.map((item, index) => {
                            return (
                            <a className="text-decoration-none cont position-relative mt-3 d-flex flex-column justify-content-between align-items-center text-dark fontReg font-size03" href={`${item.path}`} key={index}>
                                {item.icon}
                                <div className='d-flex flex-column align-items-center fontMed font-size03'>
                                {item.text}
                                </div>
                            </a>
                            )
                        })
                        }
                    </div>
                </div>
                </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp'
            animatePreScroll={false}>
            <div className="px-10 mb-5 mx-0">
                <ContactForm/>
            </div>
            <div className='row'>
                <div className='col-12 sty-map fontReg'><Link to={mapImg} as={Link} target='blank' >IMAGE MAP</Link></div>
            </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp'
            animatePreScroll={false}>
            <div className="container-fluid default-font">
                <div className="row mt-5">
                    <div className="col-12 px-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.660033591625!2d100.5461540148303!3d13.739020890355711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29edea46fc619%3A0x28af520b8b01ba1c!2sAll%20Seasons%20Place!5e0!3m2!1sen!2sth!4v1610598745222!5m2!1sen!2sth" width="100%" height={450}
                        allowFullScreen aria-hidden="false" tabIndex={0} title="map"/>
                    </div>
                </div>
            </div>
            </ScrollAnimation>
        </div>
        <div>
            <ScrollToTopButton />
        </div>
        <div className='mt-6'>
            <Footer />
        </div>
    </div>
  )
}

export default ContactUs;