import React, { useContext, useEffect, useState } from 'react'
import './ContactForm.css'
import Dropdown from "../dropdowns/Dropdown"
import { DataDropdownTest } from "../dataMockup/DataMockDropdown";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { getDataContact,sendDataContact } from '../../api/contact_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';

function ContactForm() {
    const [selectedContact, setSelecteContact] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [clickCheck, setClickCheck] = useState("");
    const [isCaptcha, setIsCaptcha] = useState(false);
    const [DataDropCont, setDataDropCont] = useState([]);
    const [inputForm, setInputForm] = useState({
        contact: 0,
        username: "",
        email: "",
        phone: "",
        message: "",
    });

    function setinput(inputname, value) {
        setInputForm((prev) => {
            return {
                ...prev,
                [inputname]: value,
            };
        });
    }
    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext);

    const [valuefromparent1, setValuefromparent1] = useState(<span>{t('contact_us_select')}<span style={{ color: 'red' }}> *</span></span>);

    const ChangesetValuefromparent1 = (text) =>  {
        setValuefromparent1(text);
    }

    const handleRoleChange = (value) => {
        setSelecteContact(value);
    };

    const onChangeInputName = (e) => {
        if (e) {
            setinput("username", e.target.value);
            setUsername(e.target.value);
        }
    };

    const onChangeMessage = (e) => {
        if (e) {
            setinput("message", e.target.value);
        }
    };

    const onChangeInputEmail =  (e) => {
        if (e) {
            setinput("email", e.target.value);
            setEmail(e.target.value);
        }
    }

    const onChangeInputPhone =  (e) => {
        if (e) {
            setinput("phone", e.target.value);
            setPhone(e.target.value);
        }
    }

    const getContDropDown = async () => {
        try {
            const response = await getDataContact({}, lang);
            if (response.status == 200) {
                let dataCont = [{ value: "", text: "Please select a contact form *" }];
                response.data.data.contentdata.email_list.forEach((item, i) => {
                    return dataCont.push({
                        value: item.type,
                        text: item.name,
                    });
                });
                setDataDropCont(dataCont);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    async function alertValidate(title) {
        const res = await Swal.fire({
            iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
            title: title,
        });
        if (res.isConfirmed) {
            return;
        } else {
        return;
        }
    }

    const submitData = async () =>{
        try {
            if (isCaptcha) {
                if (validateEmail(inputForm.email)) {
                    const formatedBodyToServer = {
                        name: inputForm.username,
                        phone: inputForm.phone,
                        email: inputForm.email,
                        type: selectedContact,
                        message: inputForm.message
                    }
                    if (
                        !formatedBodyToServer.name ||
                        formatedBodyToServer.phone.length !== 10 ||
                        !formatedBodyToServer.email ||
                        !formatedBodyToServer.type  
                    ) {
                      await alertValidate("กรุณากรอกข้อมูลให้ครบถ้วน");
                    } else {
                        await Swal.fire({
                            title: "Confirm Data Save",
                            text: "ยืนยันการบันทึกข้อมูล",
                            iconHtml: '<img src="https://img5.pic.in.th/file/secure-sv1/ic-save.png">',
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: "CONFIRM",
                            cancelButtonText: "CANCEL",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              (async () => {
                                const response = await sendDataContact(formatedBodyToServer, lang=='en-US'?'en':lang);
                                  if (response?.status == 200) {
                                    Swal.fire({
                                      icon: "success",
                                      title: "Saved",
                                      text: "บันทึกเรียบร้อยแล้ว",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        window.location.reload();
                                      }
                                    });
                                  }
                                  else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "Error",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          window.location.reload();
                                        }
                                      });
                                  }
                              })();
                            }
                          });
                    }
                }
                else {
                    alertValidate("กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                }
            } else {
                await Swal.fire({
                    title: "Please Click Capca",
                    text: "กรุณากดยืนยัน capca",
                    iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                }).then((result) => {});
            }
            
        } catch (error) {
            console.log('found error ', error)
        }
    }

    useEffect(() => {
        getContDropDown(lang=='en-US'?'en':lang)
    }, [lang])

  return (
    <>
        <div className='row ms-2 ms-md-0'>
            <div className='col-12 p-0'>
                <font className='fontBold font-size06 colorBlack txt-underline'>{t('contact_us_form_title')}</font>
            </div>
            <div className='row'>
                <div className='col-12 col-md-6 ps-0 pe-0 pe-md-4'>
                    <Dropdown
                        options={DataDropCont}
                        setinput={setinput}
                        inputname={"contact"}
                        ChangesetValuefromparent={ChangesetValuefromparent1}
                        valuefromparent={valuefromparent1}
                        onChange={handleRoleChange}
                    />
                </div>
                <div className='col-12 col-md-6 pe-0 ps-0 ps-md-4'>
                    <input
                        type="text"
                        className="inp01 fontLight font-size03"
                        id="username"
                        // placeholder="Your Name *"
                        placeholder={t('contact_us_form_name') + ' *'}
                        value={username}
                        onChange={onChangeInputName}
                    />
                </div>
            </div>
            <div className='row mt-0 mt-md-4'>
                <div className='col-12 col-md-6 ps-0 pe-0 pe-md-4'>
                    <input
                        type="text"
                        className="inp01 fontLight font-size03"
                        id="email"
                        // placeholder="Your E-mail *"
                        placeholder={t('contact_us_form_email') + ' *'}
                        value={email}
                        onChange={onChangeInputEmail}
                    />
                </div>
                <div className='col-12 col-md-6 pe-0 ps-0 ps-md-4'>
                    <input
                        type="text"
                        className="inp01 fontLight font-size03"
                        id="phone"
                        // placeholder="Your Phone"
                        placeholder={t('contact_us_form_phone') + ' *'}
                        value={phone}
                        onChange={onChangeInputPhone}
                    />
                </div>
            </div>
            <div className='row mt-3 mt-md-5'>
                <div className='col-12 p-0'>
                    <textarea id="textDetail" className='fontLight font-size03' name="textDetail" rows="5" cols="50" placeholder={t('contact_us_form_message')} value={inputForm.message} onChange={onChangeMessage}></textarea>
                </div>
            </div>
            <div className='row mt-0 mt-md-2'>
                <div className='col-12 p-0'>
                    <Form className="ml-5">
                        <div className="my-2">
                            <Form.Check
                                className='colorGray fontReg font-size02'
                                inline
                                label={t('contact_us_form_accept_text')}
                                name="group1"
                                type="checkbox"
                                id={`inline-checkbox`}
                                checked={clickCheck}
                                onChange={(event) =>
                                    setClickCheck({
                                        ...clickCheck,
                                        update: event.target.checked,
                                    })
                                }
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 p-0 d-flex justify-content-center'>
                    {/* <div class="g-recaptcha" data-sitekey="6LeCNK8pAAAAADermNkS4Ra755AK94gGiDOIGlMB"></div> */}
                    <ReCAPTCHA
                        sitekey="6LeCNK8pAAAAADermNkS4Ra755AK94gGiDOIGlMB"
                        onChange={(value)=>{
                            // console.log('recapcha token',value)
                            if(value){
                                setIsCaptcha(true)
                            }
                        }}
                    />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 p-0 d-flex justify-content-center'>
                    <button type="button" className='btn-send fontMed font-size02' onClick={()=>submitData()}>
                        SUBMIT NOW
                        <img src='/assets/images/ic-arr.png' alt='' className='ic-arr' />
                    </button>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactForm;