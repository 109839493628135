import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './PromoPrivilege.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Button, Col, Row } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import Footer from "../../components/footer/footer";
import { getPromoPriviByID } from '../../api/promo_privi_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link, useNavigate } from 'react-router-dom';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import FacebookShare from '../ShareSocial/FacebookShare';
import TwitterShare from '../ShareSocial/TwitterShare';
import LineShare from '../ShareSocial/LineShare';
import LinkCopy from '../ShareSocial/LinkCopy';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function PromoPriviDetail() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { lang } = useContext(ChangeLanguageContext);

    const [dataId, setDataId] = useState(null);
    const [type, setType] = useState('');
    const [headBanner, setHeadBanner] = useState({
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "PROMOTION & PRIVILAGE",
        isVideo: "image",
    });
    const [listDatasSlider1, setListDatasSlider1] = useState([{
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    }]);
    const renderItem = (item) => {
        return (
            <div className='image-gallery-item'>
                <img src={item.original} alt={item.original} className='img-style-card01' />
            </div>
        );
    };
    const [dataCardDetail, setDataCardDetail] = useState({
        txtDiscount: '',
        txtTitle: '',
        txtDetail: '',
        discountDate: '',
        endDate: '',
        note: '',
        shareFB: '',
        shareX: '',
        shareXtext: 'All Season',
        shareLine: '',
        shareLineText: 'All Season',
        link: '',
    });

    const CustomLeftNav = ({ onClick, disabled }) => (
        <button
            type="button"
            className="custom-arrow custom-left-arrow"
            disabled={disabled}
            onClick={onClick}
            aria-label="Previous Slide"
        />
    );
    
    const CustomRightNav = ({ onClick, disabled }) => (
        <button
            type="button"
            className="custom-arrow custom-right-arrow"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
        />
    );

    const fetchingData = async (language, id) => {
        try {
          //info get 
            const infoDefault = {
                type:'head_banner'
            }
            const result = await getPromoPriviByID(id, {}, language);
            const data = result?.data?.data;
            if (data) {
                // setType(data.type);
                setDataId(data._id);
                await setDataCardDetail({
                    ...dataCardDetail,
                    txtDiscount: data.contentdata.discount,
                    txtTitle: data.contentdata.title,
                    txtDetail: data.contentdata.description,
                    discountDate: language == 'th' ? formatDateTH(data.contentdata.discount_date) : formatDate(data.contentdata.discount_date),
                    endDate: language == 'th' ? formatDateTH(data.contentdata.end_date) : formatDate(data.contentdata.end_date),
                    note: data.contentdata.note,
                    shareFB: data.contentdata.link,
                    shareX: data.contentdata.link,
                    shareLine: data.contentdata.link,
                    shareLineText: data?.contentdata?.shareLineText ,
                    shareXtext: data?.contentdata?.shareXtext ,
                    link: data.contentdata.link,
                })

                const bannerData = result?.data?.data?.banner?.data;
                if (bannerData) {
                    const banners = {
                        original: bannerData.data,
                        thumbnail: bannerData.data,
                        isVideo: bannerData.format || "image",
                    };
                    setHeadBanner(banners);
                } else {
                    console.error('Banner data is undefined');
                }
                
                const galleryImages = [];
                // Check if contentdata is an array
                if (Array.isArray(data.contentdata)) {
                    data.contentdata.forEach(item => {
                        if (Array.isArray(item.imgs)) {
                            item.imgs.forEach(img => {
                                galleryImages.push({
                                    original: img,
                                    thumbnail: img,
                                });
                            });
                        }
                    });
                } else if (typeof data.contentdata === 'object') {
                    // Handle the case when contentdata is an object
                    if (Array.isArray(data.contentdata.imgs)) {
                        data.contentdata.imgs.forEach(img => {
                            galleryImages.push({
                                original: img,
                                thumbnail: img,
                            });
                        });
                    }
                }
            setListDatasSlider1(galleryImages);
        } else {
            console.error("Data is missing in the result.");
        }
            
        } catch (error) {
            console.log('found error', error);
        }
    };

    useEffect(() => {
        
        let id = new URLSearchParams(window.location.search).get("id");
        let type = new URLSearchParams(window.location.search).get("type");
            if (id) {
                fetchingData(lang=='en-US'?'en':lang, id);
                setDataId(id);
                setType(type);
            }
    }, [lang])

    return (
        <div>
            <style>
                {`
                    .bd-bottom01 {
                        border-bottom: 6px solid #3EA33A;
                        width: 9% !important;
                        position: relative;
                        display: block;
                    }
                    .sty-promo1 {
                        display: flex;
                        justify-content: center;
                        align-items: start;
                        background-color: #ffffff;
                        padding: 25px;
                    }
                    .custom-arrow {
                        width: 40px !important;
                        height: 40px !important;
                        margin-bottom: -15px !important;
                    }
                `}
            </style>
            <Header />
            <div className="container-fluid w-100">
                <div className="row">
                    <div className="col-12 px-0">
                        <div className="image-gallery-wrapper">
                            <div className="position-relative">
                                <img src={headBanner.original} alt='banner' className="banner-title" />
                                <div className="txt-banner">
                                <p className="fontBold font-size10 colorWhite mb-0 w-fit-content">PROMOTION & PRIVILAGE</p>
                                <p className="bd-bottom-banner"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-container">
                    <div className="info-data">
                        <div className="mx-10 mt-4">
                            <HeadingSection title={type=='promo'?'PROMOTION':'PRIVILEGE'}
                                position={'center'}
                                customize={{
                                    paddingBottom: '1rem',
                                    paddingTop: '2rem'
                                }}
                                classCss="pdt-hd"
                                subTitleDetail="d-block"
                            />
                            <div className='w-100 d-flex justify-content-center mt-46'><span className="bd-bottom01 mt-6"></span></div>
                        </div>
                    </div>
                </div>
                <div className="info-container">
                    <div className="info-data">
                        <div className="mx-10 my-5">
                            <div className="row">
                                <div className="col-12 col-md-7 px-0">
                                    <div className="image-gallery-wrapper">
                                        <ImageGallery
                                            items={listDatasSlider1}
                                            autoPlay={true}
                                            showPlayButton={false}
                                            showThumbnails={true}
                                            showFullscreenButton={false}
                                            showBullets={false}
                                            slideDuration={5000}
                                            showNav={true}
                                            renderItem={renderItem}
                                            renderLeftNav={(onClick, disabled) => (
                                                <CustomLeftNav onClick={onClick} disabled={disabled} />
                                            )}
                                            renderRightNav={(onClick, disabled) => (
                                                <CustomRightNav onClick={onClick} disabled={disabled} />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 col-md-5 sty-promo1'>
                                    <div width={'100%'}>
                                        <h2 className="fontBold"> <span className="font-size10 fontBold">{dataCardDetail.txtDiscount} </span></h2>
                                        <p className="bd-bottom04"></p>
                                        <p className="fontBold font-size06 mb-0 mb-md-2">{dataCardDetail.txtTitle}</p>
                                        <p className="font-size02 colorBlack01 fontLight mb-0 mb-md-1 text-underline">DETAIL</p>
                                        <p className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: dataCardDetail.txtDetail}}></div></p>
                                        {/* <p className="font-size08 colorBlack01 fontMed">{dataCardDetail.discountDate} - {dataCardDetail.endDate}</p> */}
                                        <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                            {dataCardDetail.discountDate == dataCardDetail.endDate
                                                ? dataCardDetail.discountDate
                                                : `${dataCardDetail.discountDate} - ${dataCardDetail.endDate}`}
                                        </p>
                                        <p className="font-size03 colorBlack01 fontLight">{dataCardDetail.note}</p>
                                        <div className="d-flex justify-content-start w-100">
                                            <div className='d-flex flex-row align-items-center'>
                                                <font className="font-size02 fontMed colorBlack text-decoration-none">
                                                    SHARE
                                                </font>
                                                <div className='ms-3 w-100'>
                                                    <FacebookShare url={dataCardDetail.shareFB} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <TwitterShare url={dataCardDetail.shareX} text={dataCardDetail.shareXtext} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <LineShare url={dataCardDetail.shareLine} text={dataCardDetail.shareLineText} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <LinkCopy url={dataCardDetail.link}></LinkCopy>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ScrollToTopButton />
            </div>
            <Footer />
        </div>
    )
}

export default PromoPriviDetail;