import React, { useState } from 'react';

const minDay = 1;
const maxDay = 31;

function DayPicker({ onDayChange }) {
    const thisDay = new Date().getDate();
    const [selectedDay, setSelectedDay] = useState('DD'); // Set default value to 'DD'
  
    const onHandleChange = (evt) => {
      const newSelectedDay = evt.target.value;
      setSelectedDay(newSelectedDay);
      // Call the callback function passed from the parent with the updated state
      onDayChange(newSelectedDay);
    };
  
    const options = [];
    for (let i = minDay; i <= maxDay; i++) {
      options.push(<option key={i} value={i}>{i < 10 ? `0${i}` : i }</option>);
    }
  
    return (
      <div>
        <style>
            {`
                .date-style {
                    width: 100px;
                    background-color: #fff !important;
                    color: #000000 !important;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;
                    height: 42px !important;
                    border: 1px solid #D1D3D4 !important;
                    border-radius: 10px !important;
                    padding: 3px 5px !important;
                    font-family: 'db_heaventregular' !important;
                    font-size: 0.9rem !important;
                    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
                }
            `}
        </style>
        <select value={selectedDay} onChange={onHandleChange} className='date-style'>
          <option value="DD">DD</option>
          {options}
        </select>
      </div>
    );
}

export default DayPicker;