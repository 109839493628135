import React from "react";
import "./Thumbnail.css";

function Thumbnail(props) {
  // console.log("props: props props props : ", props.satusClear);
  //
  const { file, removeFile } = props;

  return (
    <div className="thumb">
      <div className="thumbInner fontReg font-size03">
        <img src={file.src} className="img" alt="" />
      </div>
      <button
        className={
          window.location.pathname === "/backoffice/view&EditInfoManageBanner"
            ? "remove-img-banner"
            : "remove-img"
        }
        onClick={() => removeFile(file.id)}
      >
        <img
          src="assets/icons/ic-add.png"
          alt="ExportFiles Icon"
          className="ic-remove-img"
        />
      </button>
    </div>
  );
}

export default Thumbnail;
