import React from 'react';
import './ShareSocial.css'
import xIcon from '../../assets/images/ic-x.png';

const TwitterShare = ({ url, text }) => {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

    return (
        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
            <img src={xIcon} className='ic-social' alt='ic-x' />
        </a>
    );
};

export default TwitterShare;