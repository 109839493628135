import React, { useContext } from 'react';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';

function LanguageSwitcher() {
  const { changeLanguage } = useContext(ChangeLanguageContext);

  return (
    <div className="language fontReg font-size02 d-flex flex-row align-items-center justify-content-end mr-1">
      <button className="ms-col-2 pd-t1 mx-1 mx-xl-3 btn-lang" onClick={() => changeLanguage('th')}>TH</button>
      <button className="ms-col-3 pd-t1 me-2 me-xl-3 btn-lang" onClick={() => changeLanguage('en')}>EN</button>
    </div>
  );
}

export default LanguageSwitcher;