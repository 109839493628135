import React, { useState } from 'react';

const minOffset = 0;
const maxOffset = 60;

function Year({onYearChange}) {
    const [selectedYear, setSelectedYear] = useState("YYYY"); // Default is "YYYY"
  
    const onHandleChange = (evt) => {
      const newSelectedYear = evt.target.value;
      setSelectedYear(newSelectedYear);
      // Call the callback function passed from the parent with the updated state
      onYearChange(newSelectedYear);
    };
  
    const options = [];
    const thisYear = new Date().getFullYear();
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      options.push(<option key={year} value={year}>{year}</option>);
    }
  
    return (
      <div>
        <style>
            {`
                .date-style {
                    width: 100px;
                    background-color: #fff !important;
                    color: #000000 !important;
                    display: flex !important;
                    align-items: center !important;
                    justify-content: space-between !important;
                    height: 42px !important;
                    border: 1px solid #D1D3D4 !important;
                    border-radius: 10px !important;
                    padding: 3px 5px !important;
                    font-family: 'db_heaventregular' !important;
                    font-size: 0.9rem !important;
                    font-size: clamp(0.6rem, 1vw, 0.9rem) !important;
                }
            `}
        </style>
        <select value={selectedYear} onChange={onHandleChange} className='date-style'>
          <option value="YYYY">YYYY</option> {/* Default option */}
          {options}
        </select>
      </div>
    );
}

export default Year;