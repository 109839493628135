import React, { useEffect, useState } from "react";
import "./Dropdown.css";

function Dropdown(props) {
  const optionsprops = props.options
  const {setinput,inputname,setissolo,valuefromparent} = props

 
  const [options, setOptions] = useState(optionsprops);
  const [selected, setSelected] = useState('ทั้งหมด');
  const [isActive, setIsActive] = useState(false);

  const handleSelectMenuClick = (event) => {
  
    setIsActive((current) => !current);
  };
  const handleSelectItemClick = (event) => {
    setSelected(event.target.outerText);
    setIsActive((current) => !current);   
    if(setinput)
      setinput(inputname, event.target.attributes[1].value)  
    if(setissolo)
      setissolo(event.target.attributes[1].value,event.target.outerText);    
    if (props.onChange) {
      const selected = event.target.attributes[1].value;
      props.onChange(selected);
    }
    if(props.func)//ใช้กรณีเดี่ยวหรือทีม dropdown
    {
      let found = options.find(x => {
        return x.value == event.target.attributes[1].value;
      })
      props.func(found.subType);
    }
  };
  
  useEffect(() => {
    setOptions(props.options)    
  }, [props.options]);

  useEffect(()=>{
    if(valuefromparent!=undefined)
    {
      setSelected(valuefromparent);
    }
  },[valuefromparent])
  return (
    <div className="fontReg font-size02">
      <div className="select" onClick={handleSelectMenuClick}>
        <span className="inp-dropdown ">{selected}</span>
        <i className={`ic-dropdown ${isActive ? "fas fa-angle-up" : "fas fa-angle-down"}`} />
      </div>
      <div className="position-relative">
        <div className={`${isActive ? "options-list active" : "options-list"}`}>
          {options && options.length>0 && options?.map((option) => (
            <div
              className="option"
              key={option.value}
              value={option.value}
              onClick={handleSelectItemClick}
            >
              {option.text}
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
}

export default Dropdown;
