import { useEffect, useState } from "react"

export const useMediaQueryHook = () => {
    const [viewportSize, setViewportSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setViewportSize({ width, height });
            }
        });

        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.unobserve(document.documentElement);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return {
        viewportSize,
        scrollPosition
    }
}