import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ChangeLanguageContext = createContext();

export const ChangeLanguageContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);


    const changeLanguage = (lang) => {
        // if (lang == 'en') {
        //     setLang('th')
        //     i18n.changeLanguage('th');
        // } else {
        //     setLang('en')
        //     i18n.changeLanguage('en');
        // }
        setLang(lang)
        i18n.changeLanguage(lang);
    };

    return (
        <ChangeLanguageContext.Provider value={{ lang, changeLanguage }}>
            {children}
        </ChangeLanguageContext.Provider>
    );
};
