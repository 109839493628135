import React, { useEffect, useState, useContext } from 'react';
import './OurService.css';
import { Col, Row, Button } from "react-bootstrap";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import { getDataClub } from '../../api/our_service_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import CardSlider4 from "../../components/cardSlide/Slick4"
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

function ASPClub() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { t } = useTranslation()

    const { lang } = useContext(ChangeLanguageContext);
    const [titleDesc, setTitleDesc] = useState('');
    const [txtDesc, setTxtDesc] = useState('');
    const [imgSlidDesc1, setImgSlidDesc1] = useState([]);
    const [titleSlidDesc1, setTitleSlidDesc1] = useState('');
    const [txtSlidDesc1, setTxtSlidDesc1] = useState('');


    const fetchingData = async (language) => {
        try {
            const result = await getDataClub({}, language);
            setTxtDesc(result?.data?.data?.content?.club_description);
            setTxtSlidDesc1(result?.data?.data?.content?.diamond_description);
            
            const imgSlidDesc1 = result?.data?.data?.content?.diamond_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc1(imgSlidDesc1);

        } catch (error) {
            console.log('found error', error);
        }
      };
    
      useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang)
      }, [lang])

  return (
                <div className="info-data">
                    <style>
                        {`
                            .promo-item img {
                                width: 100%;
                                height: 450px;
                            }
                        `}
                    </style>
                    <ScrollAnimation animateIn='fadeIn'
                    initiallyVisible={true}>
                    <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                        <div className="col-12 pt-3 px-0">
                            <h1 className='my-4 text-decoration-underline fontBold font-size06'>
                            ASP CLUB LOUNGE
                            </h1>
                        </div>
                    </div>
                    <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                        <div className="col-12 col-md-6 mb-4">
                            <div className="text-left">
                                <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDesc}}></div></font>
                            </div>
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    initiallyVisible={true}>
                    <div className="row w-100 mx-0 mt-6 mb-13 bg-sty01 mt-5">
                        <div className="col-12 col-md-6 p-0">
                            <CardSlider4
                                data={imgSlidDesc1}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInRight" delay={100}>
                            <div className="text-left w-75">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <p className="font-size04 colorBlack01 fontBold">{t('aspDiamond')}</p>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc1}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    </ScrollAnimation>
            </div>
  )
}

export default ASPClub;