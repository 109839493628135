import React, { useState, useEffect } from 'react';
import './ScrollTopButton.css';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);

    // Show button when the user scrolls down 20px from the top of the document
    const handleScroll = () => {
        const scrolledFromTop = window.scrollY;
        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        
        if (scrolledFromTop > 20) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }

        // Check if the user is at the bottom of the page
        if (scrolledFromTop + window.innerHeight >= scrollableHeight) {
            setIsAtBottom(true);
        } else {
            setIsAtBottom(false);
        }
    };

    // Scroll to the top of the document when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <button 
                    onClick={scrollToTop} 
                    id="myBtn"
                    title="Go to top" 
                    type="button" 
                    className={`btn-to-top btn btn-lg ${isAtBottom ? 'at-bottom' : ''}`}
                >
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;