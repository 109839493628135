import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './ShareSocial.css'
import linkIcon from '../../assets/images/ic-link.png';

const LinkCopy = ({ url }) => {
  return (
    <CopyToClipboard text={url}>
      <img 
        src={linkIcon}
        className='ic-social' 
        alt='ic-fb' 
        onClick={() => alert('URL copied to clipboard')}
        style={{ cursor: 'pointer' }} 
      />
    </CopyToClipboard>
  );
};

export default LinkCopy;