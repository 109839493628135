import React from 'react'
import "./view360.css"
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

export default function lading360View({ image, title, subTitle, detail, path, target, alignContentLeft, customLinkName }) {
    return (
        <div className="col-12 w-100 px-0 text-white default-font">
            <div className={'banner-v360'} style={{
                backgroundImage: `url('${image}')`,
            }}>
                <div className={`${alignContentLeft ? 'content-left' : 'content-right'}  w-100 h-100  d-flex align-items-center`}>
                    <div className="container-fluid h-100">
                        {
                            alignContentLeft ?
                                <div className="row h-100 position-relative">
                                    <ScrollAnimation animateIn="fadeInLeft" delay={100}>
                                        <div className="position-relative col-xl-6 col-12 ms-auto d-flex justify-content-center justify-content-xl-start w-100 h-100 align-items-center content-padding">
                                            <div className='px-3 w-35 d-flex justify-content-center align-items-start flex-column'>
                                                <h1 className='font-size10 fontBold lineHeight'>
                                                    {title}
                                                </h1>
                                                <h2 className='font-size13 fontUlLight'>
                                                    {subTitle}
                                                </h2>
                                                <p className='font-size02 fontLight p-0'>
                                                    {detail}
                                                </p>
                                                <div className='link'>
                                                    <Link to={path ?? "/"} target={target ?? "/"} className='text-decoration-none text-white fw-bold'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='fontMed font-size03'>
                                                            {customLinkName ? customLinkName : 'VIEW ALL'}
                                                            </div>
                                                            <div className='mx-2'>
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.0099 30.8401C24.25 30.8401 30.9299 24.16 30.9299 15.9199C30.9299 7.67983 24.25 1 16.0099 1C7.7698 1 1.08984 7.67983 1.08984 15.9199C1.08984 24.16 7.7698 30.8401 16.0099 30.8401Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M13.4492 10.4395L18.6593 15.6494L13.4492 20.8696" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div> :
                                <div className="row h-100 position-relative">
                                    <ScrollAnimation animateIn="fadeInRight" delay={200}>
                                        <div className="position-relative col-xl-6 col-12 ms-auto d-flex justify-content-center justify-content-xl-end w-100 h-100 align-items-center content-padding">
                                            <div className='px-3 w-35 d-flex justify-content-center align-items-start flex-column'>
                                                <h1 className='font-size10 fontBold lineHeight'>
                                                    {title}
                                                </h1>
                                                <h2 className='font-size13 fontUlLight'>
                                                    {subTitle}
                                                </h2>
                                                <p className='font-size02 fontLight p-0'>
                                                    {detail}
                                                </p>
                                                <div className='link'>
                                                    <Link to={path ?? "/"} target={target ?? "/"} className='text-decoration-none text-white fw-bold'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='fontMed font-size03'>
                                                                {customLinkName ? customLinkName : 'VIEW ALL'}
                                                            </div>
                                                            <div className='mx-2'>
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.0099 30.8401C24.25 30.8401 30.9299 24.16 30.9299 15.9199C30.9299 7.67983 24.25 1 16.0099 1C7.7698 1 1.08984 7.67983 1.08984 15.9199C1.08984 24.16 7.7698 30.8401 16.0099 30.8401Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M13.4492 10.4395L18.6593 15.6494L13.4492 20.8696" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
