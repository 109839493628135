export const contacts = [
    {
        icon: <svg width={70} height={68} viewBox="0 0 70 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.886 17.4642V25.8814M55.886 17.4642H42.8056C41.4956 17.4642 40.4398 16.3876 40.4398 15.0956V2M55.886 17.4642L40.4398 2M55.886 51.7791V63.9938C55.886 65.3054 54.8107 66.3624 53.5202 66.3624H16.0385C14.7285 66.3624 13.6727 65.2858 13.6727 63.9938V51.7791M55.886 51.7791H67.5392V26.3904H2V51.7791H13.6532L13.7705 43.4402H55.7492L55.886 51.7791ZM13.6727 26.0772V4.36857C13.6727 3.05705 14.7481 2 16.0385 2H40.4398M21.3372 57.8277H48.202M21.3372 51.3484H48.202" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        ,
        text: "66 (0) 2625-1899",
        path: "tel:+6626251899"
    },
    {
        icon: <svg width={69} height={68} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.6577 61.7036C42.9006 61.0772 47.202 59.5895 50.0958 56.6924C53.6934 53.0906 56.4894 47.0028 56.4894 41.5414V22.9843C56.4894 17.5229 53.6934 12.0028 50.0958 8.40101C46.4982 4.79922 40.9844 2 35.5294 2H32.8116C27.3565 2 21.8428 4.79922 18.2452 8.40101C14.6475 12.0028 11.8516 17.5229 11.8516 22.9843V41.5414" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.2091 41.8155C6.06687 41.424 2 36.8435 2 31.245C2 25.6466 6.06687 21.0661 11.2091 20.6746M57.0983 41.8351C62.2405 41.4436 66.3074 36.863 66.3074 31.2646C66.3074 25.6662 62.2405 21.0856 57.0983 20.6941" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M34.1455 57.26C36.6286 57.26 38.6425 59.2762 38.6425 61.7622C38.6425 64.2483 36.6286 66.2645 34.1455 66.2645C31.6623 66.2645 29.6484 64.2483 29.6484 61.7622C29.6484 59.2762 31.6623 57.26 34.1455 57.26Z" stroke="#3EA33A" strokeWidth="3.44" strokeMiterlimit="22.93" />
        </svg>
        ,
        text: "66 (0) 2625-1800",
        path: "tel:+6626251800"
    },
    {
        icon: <svg width={68} height={68} viewBox="0 0 68 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.2878 2V41.3261H2V2M66.2878 2H2M66.2878 2L34.1439 28.3479L2 2M65.6035 40.3669L42.219 22.632M2.66478 40.3669L26.0493 22.632" stroke="#3EA33A" strokeWidth="3.44" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        text: "info@asp.th.com",
        path: "mailto:info@asp.th.com"
    },
]