import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './OurService.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row, Button } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataCusCare } from '../../api/our_service_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import CardSlider4 from "../../components/cardSlide/Slick4"
import Swal from "sweetalert2";
import ASPCus from './ASPCus';
import ASPClub from './ASPClub';
import ShuttleBus from './ShuttleBus';
import Carpark from './Carpark';
import { useTranslation } from 'react-i18next';
import MultiLangHtml from '../../components/MultiLangeHtml';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function OurService() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    
    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext);

    const [activeTab, setActiveTab] = useState('customer');
    const [titleDesc, setTitleDesc] = useState('');
    const [txtDesc, setTxtDesc] = useState('');
    const [headBanner, setHeadBanner] = useState({
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "OUR SERVICES  & PRIVILEGES",
        isVideo: "image",
    });
    

    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };

    const fetchingData = async (language) => {
        try {
                const result = await getDataCusCare({}, language);
                setTxtDesc(result?.data?.data?.content?.cscare_description);
                const bannerData = result?.data?.data?.banner?.data;
                if (bannerData) {
                    const banners = {
                        original: bannerData.data,
                        thumbnail: bannerData.data,
                        isVideo: bannerData.format || "image",
                    };
                    setHeadBanner(banners);
                } else {
                    console.error('Banner data is undefined');
                }
            
        } catch (error) {
            console.log('found error', error);
        }
    };
    
    useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang)
    }, [lang])

    useEffect(() => {
        
        let type = new URLSearchParams(window.location.search).get("type");
            if (type) {
                setActiveTab(type)
            }
    }, [])

  return (
    <div>
        <style>
            {`
                ul.menu li a#services {
                    color: #3AF823;
                    text-decoration: underline;
                }
                .br-455 {
                    padding: 0 0;
                }
                @media (max-width: 455px) {
                    .br-455 {
                        padding: 25px 0;
                    }
                }
            `}
        </style>
        <Header />
        <div className="container-fluid w-100">
            <div className="row">
                <div className="col-12 px-0">
                    <div className="image-gallery-wrapper">
                    <div className="position-relative">
                        <img src={headBanner.original} alt='banner' className="banner-title" />
                        <div className="txt-banner">
                            <p className="fontBold font-size10 colorWhite mb-0">{t('ourservice_privileges_title')}</p>
                            <p className="bd-bottom-banner"></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="info-container">
                <ScrollAnimation animateIn='fadeIn' initiallyVisible={true}>
                <div className="info-data bg-sty02">
                    <div className="mx-10">
                        <HeadingSection title={t('ourservice_privileges_head_title')} subTitle={t('ourservice_privileges_head_subtitle')}
                            position={'center'}
                            customize={{
                                paddingBottom: '1rem',
                                paddingTop: '2rem'
                            }}
                            classCss="pdt-hd"
                            subTitleDetail="d-block"
                        />
                    </div>
                    <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                        <div className="col-12 col-md-6 pt-3 mb-5">
                            <font className="font-size02 colorBlack01 fontReg">{titleDesc}</font>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <div className="info-data">
                    <ScrollAnimation animateIn='fadeIn'
                    initiallyVisible={true}>
                    <div className='button-container d-flex justify-content-center align-items-center'>
                        <Row className='title-btn p-0 w-90 mx-0'>
                            <Col xs={6} lg={3} className='d-flex justify-content-center'>
                                <Button 
                                    className={`btn-bidInfo br-455 btn-bidding01 fontMed font-size05 ${activeTab === 'customer' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('customer')}>
                                    {/* ASP CUSTOMER<br/>CARE */}
                                    {<MultiLangHtml translationKey={'our_service_cs_care'}/> }
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className='d-flex justify-content-center'>
                                <Button 
                                    className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'club' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('club')}>
                                    {<MultiLangHtml translationKey={'our_service_club_lounge'}/> }
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className='d-flex justify-content-center mt-3 mt-lg-0'>
                                <Button 
                                    className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'shuttle' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('shuttle')}>
                                    {/* SHUTTLE BUS<br/>SERVICE */}
                                    {<MultiLangHtml translationKey={'our_service_shuttle_bus'}/> }
                                </Button>
                            </Col>
                            <Col xs={6} lg={3} className='d-flex justify-content-center mt-3 mt-lg-0'>
                                <Button 
                                    className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'carpark' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('carpark')}>
                                    {/* CARPARK<br/>& SECURITY SERVICE */}
                                    {<MultiLangHtml translationKey={'our_service_carpark'}/> }
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    </ScrollAnimation>
                    {activeTab === 'customer' && (
                        <ASPCus/>
                    )}
                    {activeTab === 'club' && (
                        <ASPClub/>
                    )}
                    {activeTab === 'shuttle' && (
                        <ShuttleBus/>
                    )}
                    {activeTab === 'carpark' && (
                        <Carpark/>
                    )}
                </div>
            </div>
        </div>
        <div>
            <ScrollToTopButton />
        </div>
        <Footer />
    </div>
  )
}

export default OurService;