import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './Business.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row, InputGroup, Form, Button } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataCareers } from '../../api/careers_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import { getDataRetail } from "../../api/business_api"
import { getDataPromoPrivi } from '../../api/promo_privi_api';
import { submitEnquiry } from '../../api/enquiry_api';
import CardSlider2 from "../../components/cardSlide/Slide2"
import CardSlider7 from "../../components/cardSlide/Slick7"
// import CardSlider3 from "../../components/cardSlide/Slick3"
import CardSlider3 from "../../components/cardSlide/Slick3_2"
import CardSlider4 from "../../components/cardSlide/Slick4"
import Swal from "sweetalert2";
import {formatDate, formatDateTH} from "../../utils/formatDate";
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';
import { isVideoFile } from '../../utils/functions';

function RetailCenter() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;

    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext)

    const [txtDesc, setTxtDesc] = useState('');
    const [aspVirtualLink, setAspVirtualLink] = useState();
    const [titleDesc, setTitleDesc] = useState('');
    const [typePromo ,setTypePromo] = useState('');
    const [dataId, setDataId] = useState(null);
    const [descG, setDescG] = useState('');
    const [descF2, setDescF2] = useState('');
    const [descLeaflet, setDescLeaflet] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [vrMode, setVrMode] = useState(false);
    const firstBanner = [{
      original: "https://dev-asp.verkoffice.com/assets/images/retail/banner-retail.png",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
      description1: "RECOGNIZED",
      description2: "AS THE PROMINENT",
      description3: "BUSINESS ADDRESS",
      isVideo: "",
    }]
    const [heroBanner, setHeroBanner] = useState(firstBanner);

  const [listDataPromo, setListDataPromo] = useState([
      {
          idPromo: '',
          typePromo: '',
          images: '/assets/images/offices/Group20.png',
          txtTitle: 'CONRAD BANKOK',
          discount: '20',
          txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          effectiveDate: '13 Jun 24',
          endDate: '17 Jun 24',
          txtDetail: '*According to the specified conditions',
          shareFB: '',
          shareX: '',
          shareXtext: 'All Season',
          shareLine: '',
          shareLineText: 'All Season',
          link: '',
          rank:'',
          coverImgs: ''
      },
      {
          idPromo: '',
          typePromo: '',
          images: '/assets/images/offices/Group21.png',
          txtTitle: 'CONDOMINIUM',
          discount: '20',
          txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          effectiveDate: '13 Jun 24',
          endDate: '17 Jun 24',
          txtDetail: '*According to the specified conditions',
          shareFB: '',
          shareX: '',
          shareXtext: 'All Season',
          shareLine: '',
          shareLineText: 'All Season',
          link: '',
          rank:'',
          coverImgs: ''
      },
      {
          idPromo: '',
          typePromo: '',
          images: '/assets/images/offices/Group22.png',
          txtTitle: 'CONDO FOR RENT',
          discount: '20',
          txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          effectiveDate: '13 Jun 24',
          endDate: '17 Jun 24',
          txtDetail: '*According to the specified conditions',
          shareFB: '',
          shareX: '',
          shareXtext: 'All Season',
          shareLine: '',
          shareLineText: 'All Season',
          link: '',
          rank:'',
          coverImgs: ''
      },
      {
          idPromo: '',
          typePromo: '',
          images: '/assets/images/offices/Group23.png',
          txtTitle: 'CONDO FOR SALE',
          discount: '20',
          txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          effectiveDate: '13 Jun 24',
          endDate: '17 Jun 24',
          txtDetail: '*According to the specified conditions',
          shareText: 'All Season',
          link: '',
          rank:'',
          coverImgs: ''
      },
  ]);

  const [tableParam, setTableParam] = useState({
    page_number:1,
    data_per_page:5,
  }); //กำหนด column เริ่มต้นในการ sort
  const [totalNumber, setTotalNumber] = useState(0)
  useEffect(()=>{
    fetchingDataPromo()
  },[])

    useEffect(() => {
        // Event listener to handle scroll and toggle VR mode
        const handleScroll = () => {
        const clickVrElement = document.querySelector('.click-vr');
        const iframe = document.querySelector('iframe');
        if (clickVrElement && iframe) {
            const { top, bottom } = clickVrElement.getBoundingClientRect();
            if (top >= 0 && bottom <= window.innerHeight) {
            // Click VR element is fully visible in the viewport
            // setVrMode(true);
            } else {
            // Click VR element is not fully visible in the viewport
            setVrMode(false);
            }
        }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);
 
    const handleClickVr = () => {
        // Toggle VR mode on click
        setVrMode(!vrMode);
    };
    
      const [dataSearch, setDataSearch] = useState({
        search: null,
        countSearch: '0',
      });

      const [listDatas, setListDatas] = useState([
        {
          _id: '1',
          txtTitle: '',
          txtName: 'Albedo & Ausfie',
          unit: '128',
          floorShort: 'G',
        },
        {
          _id: '2',
          txtTitle: '',
          txtName: 'ANS International',
          unit: '123A',
          floorShort: 'G',
        },
        {
          _id: '3',
          txtTitle: '',
          txtName: 'Asia Books',
          unit: '129A',
          floorShort: 'G',
        },
        {
          _id: '4',
          txtTitle: '',
          txtName: 'ASP Club Lounge',
          unit: '317A',
          floorShort: 'G',
        },
        {
          _id: '5',
          txtTitle: '',
          txtName: 'Bangkok Bank (All Seasons Branch)',
          unit: '307',
          floorShort: 'G',
        },
        {
          _id: '6',
          txtTitle: '',
          txtName: 'Bangkok Bank (Wittayu Branch)',
          unit: '114',
          floorShort: 'G',
        },
      ]);

    const [listDatasSlider1, setListDatasSlider1] = useState(
        {
            images: ['/assets/images/retail/img1.png'],
            description: '',
            roomNo: '',
            openTime: '',
            tel: '',
            floor: 'Fl.3',
            pin: ''
        });
    const [listDatasSlider2, setListDatasSlider2] = useState([
      {
          images: '/assets/images/retail/img1.png',
      },
      {
          images: '/assets/images/retail/img2.jpg',
      },
      {
          images: '/assets/images/retail/img3.jpg',
      }
    ]);
    const [listDatasSlider3, setListDatasSlider3] = useState([
      {
          images: '/assets/images/retail/img1.png',
      },
      {
          images: '/assets/images/retail/img2.jpg',
      },
      {
          images: '/assets/images/retail/img3.jpg',
      }
    ]);
    const [listDatasSlider4, setListDatasSlider4] = useState([
      {
          images: '/assets/images/retail/img1.png',
      },
      {
          images: '/assets/images/retail/img2.jpg',
      },
      {
          images: '/assets/images/retail/img3.jpg',
      }
    ]);
    const [listPlan, setListPlan] = useState([
      {
          images: ''
      }
    ]);

  const [dataAppForm, setDataAppForm] = useState({
    fullName: '',
    tel: '',
    email: '',
  });

  const handleItemClick = (index) => {
    setActiveIndex(index);
    // const clone = JSON.parse(JSON.stringify(listDatas));
    setListDatasSlider1([])
    const clone = [...listDatas]
    setListDatasSlider1(clone[index])
    
  };
    const renderItem = (item) => {
        return (
          <div className='image-gallery-item'>
            {item.isVideo ? (
              <div className="video-wrapper">
                <video controls className='im-banner'>
                  <source src={item.original} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img src={item.original} alt={item.original} className='im-banner' />
            )}
            <div className='image-gallery-description'>
              <p className='mb-1 mb-md-2 fontBold font-size09 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description1}}></div></p>
              <p className='mb-2 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description2}}></div></p>
              <p className='mb-1 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description3}}></div></p>
              <p className='mb-2 bt-line-green'></p>
            </div>
          </div>
        );
    };   

    const fetchingData = async (language) => {
        try {
          const result = await getDataRetail(language);
          const banners = result?.data?.data?.banner?.data.map((item) => ({
            original: item.data,
            thumbnail: item.data,
            description1: item.supertext,
            description2: item.description,
            description3: '',
            isVideo: isVideoFile(item.data),
        }));

          let bannerConcat = firstBanner.concat(banners);
          setHeroBanner(bannerConcat);
          setTxtDesc(result?.data?.data?.retail_desc);
          setAspVirtualLink(result?.data?.data?.vr_gallery);
          setDescG(result?.data?.data?.event_space_floor_g_description);
          setDescF2(result?.data?.data?.event_space_f2_description);
          setDescLeaflet(result?.data?.data?.event_space_leaflet_description);

          const imgsG = result?.data?.data?.imgs_fg.map((item) => ({
            images: item,
          }));
          setListDatasSlider2(imgsG);

          const imgs2F = result?.data?.data?.imgs_f2.map((item) => ({
            images: item,
          }));
          setListDatasSlider3(imgs2F);

          const imgsLeafletF = result?.data?.data?.imgs_f_leaflet.map((item) => ({
            images: item,
          }));
          setListDatasSlider4(imgsLeafletF);

          const plans = result?.data?.data?.plan_img.map((item) => ({
            images: item,
          }));
          setListPlan(plans);

          const directoryList = result?.data?.data?.directory
            if (directoryList && directoryList.length > 0) {
              let updatedDirectoryListDatas = directoryList.map(detail => {
                  return {
                      _id: detail._id,
                      txtTitle: detail.dir_title,
                      images: detail.gallery?.length>0?detail.gallery:'',
                      txtName: detail.name,
                      unit: detail.unit,
                      floorShort: detail.floor_short,
                      floor: detail.floor,
                      description: detail.description,
                      roomNo: detail.room_no,
                      openTime: detail.dir_open,
                      tel: detail.dir_tel,
                      pin: detail.dir_pin
                  };
              });
              setListDatas(updatedDirectoryListDatas);
              setListDatasSlider1(updatedDirectoryListDatas[0]);

          } else {
                setListDatas([]);
            }

        } catch (error) {
          console.log('found error', error);
        }
    };

    const fetchingDataPromo = async (language) => {
        try {
            let type = 'all'
            // if(typePromo=='promo'){
            //     type = 'promo'
            // }
            // else if(typePromo=='priv'){
            //     type = 'priv'
            // }
                const infoDefault = {
                    type:type,
                    data_per_page: 5
            }
            setTypePromo(infoDefault.type);
            const result = await getDataPromoPrivi(infoDefault, language);
            setDataId(result?.data?.data?._id)
            const infoList = result?.data?.data?.contentdata;
            if (infoList && infoList.length > 0) {
              let updatedInfoListDatas = infoList.map(detail => {
                  return {
                        idPromo: detail._id,
                        images: detail.imgs?.length>0?detail.imgs[0]:'',
                        txtTitle: detail.title,
                        discount: detail.discount,
                        discountDate: language == 'th' ? formatDateTH(detail.discount_date) : formatDate(detail.discount_date),
                        effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                        endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                        txtDesc: detail.description,
                        txtDetail: detail.note,
                        link: detail.link,
                        rank: detail.rank,
                        coverImgs: detail.cover_imgs?.length>0?detail.cover_imgs[0]:'',
                        shareLineText: detail?.shareLineText,
                        shareXtext: detail?.shareXtext,
                        shareText: detail?.shareXtext,
                  };
              });
              // updatedInfoListDatas.sort((a, b) => {
              //   if (a.rank !== b.rank) {
              //     return a.rank - b.rank;
              //   }
              //   return new Date(b.effectiveDate) - new Date(a.effectiveDate);
              // });
              setListDataPromo(updatedInfoListDatas);
              setTotalNumber(5)
          } else {
                // Handle case when detail_list is empty
                // You may want to set an empty array or handle it differently
                setListDataPromo([]);
            }
        } catch (error) {
            console.log('found error', error);
        }
    };
      
    const handleSearch = async (searchTxt ) => {
      try {
        const param = {
            search: searchTxt,
        }
        // let response = await getDataCareers(param);
        let response = await getDataRetail(lang.startsWith('en')?'en':'th', param);

        const directoryList = response?.data?.data?.directory
        if (directoryList && directoryList.length > 0) {
          let updatedDirectoryListDatas = directoryList.map(detail => {
              return {
                  _id: detail._id,
                  txtTitle: detail.dir_title,
                  images: detail.gallery?.length>0?detail.gallery:'',
                  txtName: detail.name,
                  unit: detail.unit,
                  floorShort: detail.floor_short,
                  floor: detail.floor,
                  description: detail.description,
                  roomNo: detail.room_no,
                  openTime: detail.dir_open,
                  tel: detail.dir_tel,
                  pin: detail.dir_pin
              };
          });
          setListDatas(updatedDirectoryListDatas);
          setListDatasSlider1(updatedDirectoryListDatas[0]);
      } else {
            setListDatas([]);
        }

        } catch (error) {
          console.log('found error', error);
        }
    };

    const handleClear = async (searchTxt ) => {
      try {
        const param = {
            search: searchTxt,
        }
        // let response = await getDataCareers(param);
        let response = await getDataRetail(lang.startsWith('en')?'en':'th', param);

        const directoryList = response?.data?.data?.directory
        if (directoryList && directoryList.length > 0) {
          let updatedDirectoryListDatas = directoryList.map(detail => {
              return {
                  _id: detail._id,
                  txtTitle: detail.dir_title,
                  images: detail.gallery?.length>0?detail.gallery:'',
                  txtName: detail.name,
                  unit: detail.unit,
                  floorShort: detail.floor_short,
                  floor: detail.floor,
                  description: detail.description,
                  roomNo: detail.room_no,
                  openTime: detail.dir_open,
                  tel: detail.dir_tel,
                  pin: detail.dir_pin
              };
          });
          setListDatas(updatedDirectoryListDatas);
          setListDatasSlider1(updatedDirectoryListDatas[0]);
          setDataSearch({
            ...dataSearch,
            search: '',
        });
      } else {
            setListDatas([]);
        }

        } catch (error) {
          console.log('found error', error);
        }
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    async function alertValidate(title) {
        const res = await Swal.fire({
            iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
            title: title,
        });
        if (res.isConfirmed) {
            return;
        } else {
        return;
        }
    }

    const submitData = async () =>{
      try {
          if (validateEmail(dataAppForm.email)) {
              const formatedBodyToServer = {
                  type: 'retail_center',
                  fullname: dataAppForm.fullName,
                  phonenumber: dataAppForm.tel,
                  email: dataAppForm.email,
              }
              if (
                  !formatedBodyToServer.fullname ||
                  formatedBodyToServer.phonenumber.length !== 10 ||
                  !formatedBodyToServer.email
              ) {
                  await alertValidate("กรุณากรอกข้อมูลให้ครบถ้วน");
              } else {
                  await Swal.fire({
                      title: "Confirm Data Save",
                      text: "ยืนยันการบันทึกข้อมูล",
                      iconHtml: '<img src="https://img5.pic.in.th/file/secure-sv1/ic-save.png">',
                      showConfirmButton: true,
                      showCancelButton: true,
                      reverseButtons: true,
                      confirmButtonText: "CONFIRM",
                      cancelButtonText: "CANCEL",
                  }).then((result) => {
                      if (result.isConfirmed) {
                          (async () => {
                              const response = await submitEnquiry(null, lang=='en-US'?'en':lang, formatedBodyToServer);
                                  if (response?.status == 200) {
                                      Swal.fire({
                                      icon: "success",
                                      title: "Saved",
                                      text: "บันทึกเรียบร้อยแล้ว",
                                      }).then((result) => {
                                      if (result.isConfirmed) {
                                          window.location.reload();
                                      }
                                      });
                                  }
                                  else{
                                      Swal.fire({
                                          icon: "error",
                                          title: "Error",
                                      }).then((result) => {
                                          if (result.isConfirmed) {
                                          window.location.reload();
                                          }
                                      });
                                  }
                              })();
                      }
                  });
                  }
              }
              else {
                  alertValidate("กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
              }
      } catch (error) {
          console.log('found error ', error)
      }
    }
    
      useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang)
        fetchingDataPromo(lang === 'en-US' ? 'en' : lang);
      }, [lang])

    return (
        <div>
            <style>
                {`
                    ul.menu li button#business {
                    color: #3AF823 !important;
                    text-decoration: underline;
                    }
                    video.im-banner {
                        width: 100% !important;
                        height: 600px !important;
                    }
                    .bd-bottom01 {
                      border-bottom: 6px solid #3EA33A;
                      width: 9% !important;
                      position: relative;
                      display: block;
                    }
                    .list-detail2 {
                        border-bottom: 1px solid #BBBDBF;
                        /* border-bottom: 1px solid #BBBDBF; */
                        padding: 20px 0;
                        cursor: pointer;
                        font-family: 'db_heaventlight' !important;
                    }
                    .row .active .list-detail2  {
                        font-family: 'db_heaventbold' !important;
                    }
                    .pd-title-box {
                      padding: 0 33px 0 55px;
                    }
                    .pd-title-box2 {
                      padding: 0 55px 0 15px;
                    }
                    .mgt-6 {
                      margin-top: -6px !important;
                    }
                    @media(max-width: 768px) {
                      .pd-title-box {
                        padding: 15px;
                      }
                      .pd-title-box2 {
                        padding: 30px;
                      }
                    }
                `}
            </style>
            <Header />
            <div className="container-fluid w-100">
                <div className="row">
                    <div className="col-12 px-0">
                        <div className="image-gallery-wrapper">
                        <ImageGallery
                            items={heroBanner}
                            autoPlay={true}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            showBullets={true}
                            showNav={false}
                            renderItem={renderItem}
                            slideDuration={5000}
                        />
                        </div>
                    </div>
                </div>
                <div className="info-container">
                    <div className="info-data">
                      <ScrollAnimation animateIn='fadeInUp'
                      initiallyVisible={true}>
                        <div className="mx-10 mt-4">
                              <HeadingSection title={t('business_retail_title')} subTitle={t('business_retail_subtitle')}
                                      position={'center'}
                                      customize={{
                                          paddingBottom: '1rem',
                                          paddingTop: '2rem'
                                      }}
                                      classCss="pdt-hd"
                                      subTitleDetail="d-block"
                              />
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 col-md-6 pt-3">
                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDesc}}></div></font>
                                </div>
                        </div>
                        </ScrollAnimation>
                        {aspVirtualLink && <ScrollAnimation animateIn='fadeInUp'
                            initiallyVisible={true}>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 pt-3 px-0">
                                    <h1 className='mt-5 mb-3 fontBold font-size06'>
                                    {t('business_retail_virtual')}
                                    </h1>
                                </div>
                                <div className="col-12 pt-3 px-0 position-relative click-vr" onClick={handleClickVr}>
                                    {vrMode ? null : <div className='box-bg'></div>}
                                    {vrMode ? null : <img src="assets/images/cursor-custom.png" className='cursor-click' alt=''/>}
                                    <iframe src={aspVirtualLink} title="Iframe Example" className='w-100 h-800'></iframe>
                                </div>
                        </div>
                        </ScrollAnimation>}
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                              <HeadingSection title={t('business_retail_directory')}
                                      position={'center'}
                                      customize={{
                                          paddingBottom: '0',
                                          paddingTop: '2rem'
                                      }}
                                      classCss="pdt-hd"
                                      subTitleDetail="d-none"
                              />
                              <div className='w-100 d-flex justify-content-center'><span className="bd-bottom01"></span></div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 col-md-6 pt-3">
                                    <font className="font-size02 colorBlack01 fontReg">{titleDesc}</font>
                                </div>
                        </div>
                        <div className="row mt-4">
                          <div className='col-12 col-lg-5'>
                            <div>
                              <div className="w-100 d-flex justify-content-end align-items-end position-relative">
                                <input
                                  type="text"
                                  className="inpSearch fontLight font-size02"
                                  id="search"
                                  placeholder="Search Shopping Dining"
                                  value={dataSearch.search}
                                  onChange={(event) =>
                                    setDataSearch({
                                      ...dataSearch,
                                      search: event.target.value,
                                    })
                                  }
                                />
                                {dataSearch.search && (
                                  <button
                                    className="clearButton"
                                    onClick={() =>handleClear()}
                                  >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20.7891 0.729492H3.28906C1.90835 0.729492 0.789062 1.84878 0.789062 3.22949V20.7295C0.789062 22.1102 1.90835 23.2295 3.28906 23.2295H20.7891C22.1698 23.2295 23.2891 22.1102 23.2891 20.7295V3.22949C23.2891 1.84878 22.1698 0.729492 20.7891 0.729492Z" fill="#D0D2D3"/>
                                      <path d="M17.4307 6.58984L6.64062 17.3699" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M6.64062 6.58984L17.4307 17.3699" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </button>
                                )}
                                <Button className="btn-job-search" onClick={() => handleSearch(dataSearch.search)}>
                                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3982 21.3197C16.8216 21.3197 21.2181 16.9231 21.2181 11.4996C21.2181 6.0762 16.8216 1.67969 11.3982 1.67969C5.97476 1.67969 1.57812 6.0762 1.57812 11.4996C1.57812 16.9231 5.97476 21.3197 11.3982 21.3197Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.3477 18.4492L26.8376 26.9393" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </Button>
                              </div>
                            </div>
                            <div>
                              <div className='w-100 box-unit01 mt-4'>
                                <div className='row'>
                                    <div className='col-7 p-0'>
                                      <div className='text-right d-flex align-items-end justify-content-start'>
                                        <font className="font-size02 colorGreen01 fontBold text-decoration-underline">Shop Name</font>
                                      </div>                                    
                                    </div>
                                    <div className="col-3 p-0">
                                      <div className='text-right d-flex align-items-end justify-content-start'>
                                        <font className="font-size02 colorGreen01 fontBold text-decoration-underline">Unit No.</font>
                                      </div>
                                    </div>
                                    <div className="col-2 p-0">
                                      <div className='mx-0 mx-lg-3 text-right d-flex align-items-end justify-content-end'>
                                        <font className="font-size02 colorGreen01 fontBold text-decoration-underline">Floor</font>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              {/* <div className='d-flex align-items-end mt-4'>
                                <div className='d-flex justify-content-end box-unit01'>
                                  <div className='text-right col-unit d-flex align-items-end justify-content-start'>
                                    <font className="font-size02 colorGreen01 fontBold text-decoration-underline">Unit No.</font>
                                  </div>
                                  <div className='text-right mx-3'>
                                    <font className="font-size02 colorGreen01 fontBold text-decoration-underline">Floor</font>
                                  </div>
                                </div>
                              </div> */}
                              <div className='box-unit'>
                                {listDatas.map((data, index) => (
                                  <div
                                    key={index}
                                    className={`row ${index == activeIndex ? 'active' : ''}`}
                                    onClick={() => handleItemClick(index)}
                                  >
                                      <div className="list-detail2">
                                        <div className='row'>
                                            <div className='col-7 p-0'>
                                              <div className='ms-4'>
                                                <font className="font-size02 colorBlack01">{data.txtName}</font>
                                              </div>
                                            </div>
                                            <div className="col-4 d-flex justify-content-start align-items-center p-0">
                                              <div className=''>
                                                <font className="font-size02 colorBlack01">{data.unit}</font>
                                              </div>
                                            </div>
                                            <div className="col-1 d-flex justify-content-end align-items-center">
                                              <div className='me-4'>
                                                <font className="font-size02 colorBlack01">{data.floorShort}</font>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-lg-7 ps-3 ps-md-5'>
                            <div className='pd-title-box2'>
                              {/* <div className='d-flex align-items-end mt-4'>
                                <div className='text-right w-100'>
                                    <font className="font-size02 colorBlack fontBold"> {listDatasSlider1?.txtName} <span className="fontReg">{listDatasSlider1?.images?.length}/10</span></font>
                                </div>
                              </div> */}
                              <div className="col-12 px-0">
                                  <CardSlider3
                                      data={listDatasSlider1}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                              <HeadingSection title={t('business_retail_eventspace_rent_title')} subTitle={t('business_retail_eventspace_rent_subtitle')}
                                      position={'center'}
                                      customize={{
                                          paddingBottom: '1rem',
                                          paddingTop: '2rem'
                                      }}
                                      classCss="pdt-hd pdb-hd"
                                      subTitleDetail="d-block"
                              />
                        </div>
                        <div className="row w-100 mx-0 bg-sty01 d-flex">
                                <div className="col-12 col-md-6 ps-0 pe-0 pe-lg-2 order-img01">
                                  <CardSlider4
                                        data={listDatasSlider2}
                                  />
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01">
                                  <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-75'>
                                    <div className="text-left my-3 my-lg-0">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{t('business_retail_g_floor')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_g_atrium1')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_g_atrium2')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_g_atrium3')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: descG}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </ScrollAnimation>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row w-100 m-0 bg-sty01 d-flex">
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02">
                                  <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-75'>
                                    <div className="text-left my-3 my-lg-0">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{t('business_retail_2f')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_2f_promo')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: descF2}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </ScrollAnimation>
                                </div>
                                <div className="col-12 col-md-6 p-0 mgt-6 order-img02">
                                  <CardSlider4
                                          data={listDatasSlider3}
                                  />
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row w-100 m-0 bg-sty01 d-flex">
                                <div className="mgt-6 col-12 col-md-6 ps-0 pe-0 pe-lg-2 order-img01">
                                  <CardSlider4
                                        data={listDatasSlider4}
                                  />
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01">
                                  <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-75'>
                                    <div className="text-left my-3 my-lg-0">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{t('business_retail_leaflet_title')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_leaflet_entrance_a')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size08 colorBlack01 fontReg text-decoration-underline">{t('business_retail_leaflet_entrance_b')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: descLeaflet}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </ScrollAnimation>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                                <HeadingSection title={t('business_retail_plan_title')} subTitle={t('business_retail_plan_subtitle')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd pdb-hd"
                                    subTitleDetail="d-block"
                                />
                            </div>
                            <div className="row p-0 w-100 m-0">
                                <div className="col-12 px-0">
                                    <div className="position-relative">
                                        <CardSlider7
                                            data={listPlan}
                                        />
                                    </div>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div>
                                <div className="mx-10 mt-4">
                                    <HeadingSection title={t('business_retail_promo_title')} subTitle={t('business_retail_promo_subtitle')}
                                        position={'center'}
                                        customize={{
                                            paddingBottom: '1rem',
                                            paddingTop: '2rem'
                                        }}
                                        classCss="pdt-hd pdb-hd"
                                        subTitleDetail="d-block"
                                    />
                                </div>
                                <div className="row p-0 w-100 m-0">
                                    <div className="col-12 px-0">
                                        <CardSlider2
                                            data={listDataPromo}
                                        />
                                    </div>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                            <div className="row py-5 px-0 w-100 m-0">
                                <div className="col-12 col-lg-6 px-0">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="text-left">
                                            <h2 className="colorBlack01 fontBold bd-bottom02 font-size14">{t('business_retail_enquiry')}</h2>
                                            <br/><font className="font-size02 colorBlack01 fontReg">Tel. 02-625-1869-74</font>
                                            <br/><font className="font-size02 colorBlack01 fontReg">Service/Assistance time to 9.00 AM – 17.30 PM</font>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-3 mt-md-0">
                                    <div className="d-flex justify-content-center align-items-center  align-items-lg-startflex-column">
                                        <div className='box-form'>
                                            <div className='row '>
                                                <div className='col-12'>
                                                    <h3 className="colorBlack01 fontBold">{t('business_retail_form_title')}</h3>
                                                    <span className='bd-bottom03'></span>
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t("business_retail_form_name")}
                                                        value={dataAppForm.fullName}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                fullName: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t('business_retail_form_email')}
                                                        value={dataAppForm.email}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                email: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t("business_retail_form_tel")}
                                                        value={dataAppForm.tel}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                tel: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <Link as={Link} onClick={() => submitData()} className='text-decoration-none text-dark fw-bold mt-2'>
                                                        <div className="btn-bidding">
                                                            <div className='d-flex align-items-center'>
                                                            <div className='fontReg font-size02 mx-3'>
                                                                {t("business_building_form_submit")}
                                                            </div>
                                                            <div className='mx-2'>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9678 25.9199C20.1254 25.9199 25.9278 20.1176 25.9278 12.96C25.9278 5.80235 20.1254 0 12.9678 0C5.81016 0 0.0078125 5.80235 0.0078125 12.96C0.0078125 20.1176 5.81016 25.9199 12.9678 25.9199Z" fill="white"/>
                                                                <path d="M12.3164 9.01953L16.2564 12.9595L12.3164 16.8994" stroke="#3EA33A" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div>
              <ScrollToTopButton />
            </div>
            <Footer />
        </div>
    )
}

export default RetailCenter;