import React, { useRef, useMemo ,useState} from "react";
import Slider from "react-slick";
import "./slick.css";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import { useEffect } from "react";

const CenterMode = React.memo(({ data }) => {
    const sliderRef = useRef();
    const { viewportSize } = useMediaQueryHook();
    const [currentImgIdx, setCurrentImgIdx] = useState(1) 
    const [totalImgIdx, setTotalImgIdx] = useState(data?.images.length ?? 0) 

    const settings = useMemo(() => ({
        dots: false,
        infinite: data?.image?.length > 1 ,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: viewportSize?.width > 1204,
        centerPadding: viewportSize?.width > 1204 ? 0 : 0,
        afterChange: current => setCurrentImgIdx(current + 1)
    }), [data, viewportSize]);

    const next = () => {
        sliderRef.current.slickNext();
    };

     const prev = () => {
        sliderRef.current.slickPrev();
    };

    const resetSlider = () => {
        setCurrentImgIdx(1);
      };

    useEffect(() => {
        resetSlider()
        if (sliderRef.current) {
            setTimeout(() => {
                setCurrentImgIdx(1);
                sliderRef.current.slickGoTo(0);
            }, 100); // 100ms delay to ensure rendering
        }
    }, [data]);

    return (
        <div className="slider-container default-font">
            <style>
                {`
                    .test .slick-list .slick-track .slick-slide {
                        padding: 0px !important;
                    }
                    .img-slider {
                        width: auto !important;
                        height: auto !important;
                    }
                    .promo-item img.slide3 {
                        width: 100% !important;
                        height: 500px !important;
                        background-repeat: no-repeat !important;
                        background-position: center !important;
                        object-fit: contain !important;
                        background-size: 100% 100% !important;
                    }
                    .slick3-2 div.box-next {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(50%, -50%) !important;
                        margin-left: -55px;
                        margin-top: -250px;
                    }
                    .slick3-2 div.box-pev {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0%, -50%) !important;
                        margin-right: -25px;
                        margin-top: -250px;
                    }
                    @media(max-width: 768px) {
                        .img-slider {
                            height: auto !important;
                        }
                        .promo-item img.slide3 {
                            height: 300px !important;
                        }
                        .slick3-2 div.box-pev, .slick3-2 div.box-next {
                            margin-top: -150px;
                        }
                    }
                `}
            </style>
            {data? (
                    <>
                        {/* <font className="font-size02 colorBlack fontBold"> asdasd{listDatasSlider1?.txtName} <span className="fontReg">{listDatasSlider1?.images?.length}/10</span></font> */}
                        <div className='d-flex align-items-end mt-4'>
                            <div className='text-right w-100'>
                                <font className="font-size02 colorBlack fontBold"> {data?.txtName}  <span className="fontReg">{currentImgIdx} /{data?.images?.length}</span></font>
                            </div>
                        </div>
                        <Slider ref={sliderRef} {...settings} className="test">
                            {data.images?.map((item, index) => (
                                <>  
                                <div key={index} className="w-100 slide-item" style={{ position: 'relative', userSelect: 'none' }}>
                                    <div className="slide">
                                        <div className="promo-item position-relative">
                                            <div className="img-slider">
                                                    <img src={item} alt="img" className="slide3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            ))} 
                        </Slider>
                        <div className="slick3-2 position-relative">
                            <div className="box-next">
                                <svg onClick={prev} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                    <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                                    <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="box-pev">
                                <svg onClick={next} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                    <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                                    <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="promo-content1 mt-3 w-100">
                                <div className="d-sm-flex justify-content-between d-block">
                                    <div className="me-2"><font className="fontBold font-size06">{data.txtName}</font></div>
                                    <div className="d-flex justify-content-sm-between justify-content-start">
                                        <div className="sty-color01"><font className="fontBold font-size05">{data.floor}</font></div>
                                        <div className="sty-color02"><font className="fontBold font-size05">No. {data.roomNo}</font></div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                        <div className="col-12 col-sm-4 mb-3 mb-sm-0">
                                            <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8617 26.3796C10.6217 26.3796 10.3717 26.2996 10.1717 26.1296C9.13169 25.2396 0.0117188 17.2996 0.0117188 11.5696C0.0117188 5.83958 4.88169 0.719727 10.8617 0.719727C16.8417 0.719727 21.7117 5.58958 21.7117 11.5696C21.7117 17.5496 12.5917 25.2396 11.5517 26.1296C11.3517 26.2996 11.1117 26.3796 10.8617 26.3796ZM10.8617 2.8396C6.05169 2.8396 2.13171 6.75958 2.13171 11.5696C2.13171 15.2896 7.91169 21.2497 10.8617 23.9097C13.8217 21.2497 19.5917 15.2796 19.5917 11.5696C19.5917 6.75958 15.6717 2.8396 10.8617 2.8396Z" fill="#3EA33A"/>
                                                <path d="M10.8603 16.8591C7.94029 16.8591 5.57031 14.4891 5.57031 11.5691C5.57031 8.64909 7.94029 6.2793 10.8603 6.2793C13.7803 6.2793 16.1503 8.64909 16.1503 11.5691C16.1503 14.4891 13.7803 16.8591 10.8603 16.8591ZM10.8603 8.38916C9.11029 8.38916 7.69031 9.80908 7.69031 11.5591C7.69031 13.3091 9.11029 14.7292 10.8603 14.7292C12.6103 14.7292 14.0303 13.3091 14.0303 11.5591C14.0303 9.80908 12.6103 8.38916 10.8603 8.38916Z" fill="#3EA33A"/>
                                            </svg>
                                            <font className="fontLight font-size02 ms-2">{data.pin}</font>
                                        </div>
                                        <div className="col-12 col-sm-4 mb-3 mb-sm-0">
                                            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.9516 26.9297L22.5816 26.9097C21.4016 26.8497 15.2016 26.1796 8.40161 19.3796C1.60161 12.5796 0.941582 6.36971 0.871582 5.19971L0.851562 4.82959L1.15161 4.61963C3.26161 3.17963 4.9816 2.60975 6.3916 2.23975L6.7616 2.13965L7.02161 2.40967C8.55161 3.97967 10.0016 6.16962 10.8216 8.10962L10.9916 8.50977L8.67157 10.8296C8.95157 11.5896 9.80158 13.4397 12.0616 15.6997C14.3216 17.9597 16.1716 18.8096 16.9316 19.0896L19.2516 16.7698L19.6516 16.9397C21.6016 17.7597 23.7916 19.2097 25.3516 20.7397L25.6216 20.9998L25.5216 21.3696C25.1516 22.7796 24.5816 24.4996 23.1416 26.6096L22.9316 26.9097L22.9516 26.9297ZM2.21161 5.49976C2.38161 7.03976 3.3916 12.5397 9.3316 18.4697C15.2616 24.4097 20.7716 25.4196 22.3016 25.5896C23.3616 23.9596 23.8716 22.5897 24.1916 21.4297C22.8816 20.2197 21.1316 19.0496 19.5616 18.3296L17.3216 20.5696L16.9616 20.4697C16.5116 20.3497 14.1316 19.5996 11.1716 16.6296C8.20157 13.6596 7.4516 11.2796 7.3316 10.8396L7.23157 10.4797L9.47156 8.23975C8.75156 6.66975 7.58158 4.91962 6.37158 3.60962C5.21158 3.93962 3.84161 4.43976 2.21161 5.49976Z" fill="#3EA33A"/>
                                                <path d="M21.0314 12.6497H19.7314C19.7314 10.1497 17.6914 8.10962 15.1914 8.10962V6.80957C18.4114 6.80957 21.0314 9.42966 21.0314 12.6497Z" fill="#3EA33A"/>
                                                <path d="M24.2714 12.6494H22.9714C22.9714 8.35941 19.4814 4.86938 15.1914 4.86938V3.56934C20.2014 3.56934 24.2714 7.63941 24.2714 12.6494Z" fill="#3EA33A"/>
                                                <path d="M27.5114 12.6494H26.2114C26.2114 6.56941 21.2614 1.61938 15.1914 1.61938V0.319336C21.9814 0.319336 27.5114 5.8494 27.5114 12.6394V12.6494Z" fill="#3EA33A"/>
                                            </svg>
                                            <font className="fontLight font-size02 ms-2">{data.tel}</font>
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.051 25.4094C6.24103 25.4094 0.710938 19.8693 0.710938 13.0693C0.710938 6.26934 6.25103 0.729492 13.051 0.729492C19.851 0.729492 25.391 6.26934 25.391 13.0693C25.391 19.8693 19.851 25.4094 13.051 25.4094ZM13.051 2.83936C7.41103 2.83936 2.82092 7.42934 2.82092 13.0693C2.82092 18.7093 7.41103 23.2993 13.051 23.2993C18.691 23.2993 23.271 18.7093 23.271 13.0693C23.271 7.42934 18.681 2.83936 13.051 2.83936Z" fill="#3EA33A"/>
                                                <path d="M13.0483 14.1191C12.4683 14.1191 11.9883 13.6491 11.9883 13.0591V5.4292C11.9883 4.8492 12.4583 4.36914 13.0483 4.36914C13.6383 4.36914 14.1083 4.8392 14.1083 5.4292V13.0591C14.1083 13.6391 13.6383 14.1191 13.0483 14.1191Z" fill="#3EA33A"/>
                                                <path d="M13.0483 3.52979C12.4683 3.52979 11.9883 3.05973 11.9883 2.46973V1.77979C11.9883 1.19979 12.4583 0.719727 13.0483 0.719727C13.6383 0.719727 14.1083 1.18979 14.1083 1.77979V2.46973C14.1083 3.04973 13.6383 3.52979 13.0483 3.52979Z" fill="#3EA33A"/>
                                                <path d="M13.0483 25.4097C12.4683 25.4097 11.9883 24.9396 11.9883 24.3496V23.6597C11.9883 23.0797 12.4583 22.5996 13.0483 22.5996C13.6383 22.5996 14.1083 23.0697 14.1083 23.6597V24.3496C14.1083 24.9296 13.6383 25.4097 13.0483 25.4097Z" fill="#3EA33A"/>
                                                <path d="M24.3398 14.1199H23.6499C23.0699 14.1199 22.5898 13.6498 22.5898 13.0598C22.5898 12.4698 23.0599 12 23.6499 12H24.3398C24.9198 12 25.3999 12.4698 25.3999 13.0598C25.3999 13.6498 24.9298 14.1199 24.3398 14.1199Z" fill="#3EA33A"/>
                                                <path d="M2.44922 14.1199H1.75928C1.17928 14.1199 0.699219 13.6498 0.699219 13.0598C0.699219 12.4698 1.16928 12 1.75928 12H2.44922C3.02922 12 3.50928 12.4698 3.50928 13.0598C3.50928 13.6498 3.03922 14.1199 2.44922 14.1199Z" fill="#3EA33A"/>
                                                <path d="M17.8619 5.25902C17.7719 5.25902 17.6819 5.23895 17.6019 5.18895C17.3519 5.03895 17.262 4.71898 17.402 4.46898L17.7119 3.92894C17.8619 3.67894 18.182 3.589 18.432 3.739C18.682 3.889 18.772 4.20897 18.622 4.45897L18.312 4.99901C18.212 5.16901 18.0419 5.26903 17.8519 5.26903L17.8619 5.25902Z" fill="#3EA33A"/>
                                                <path d="M7.93235 22.4693C7.84235 22.4693 7.75234 22.4492 7.67234 22.3992C7.42234 22.2492 7.33227 21.9293 7.47227 21.6793L7.78233 21.1392C7.93233 20.8792 8.2523 20.7993 8.5023 20.9393C8.7523 21.0893 8.84224 21.4092 8.69224 21.6592L8.3823 22.1993C8.2823 22.3693 8.11234 22.4693 7.92234 22.4693H7.93235Z" fill="#3EA33A"/>
                                                <path d="M21.9332 18.7186C21.8432 18.7186 21.7532 18.6986 21.6732 18.6486L21.1332 18.3385C20.8832 18.1885 20.7932 17.8685 20.9332 17.6185C21.0832 17.3685 21.4032 17.2786 21.6532 17.4186L22.1931 17.7286C22.4431 17.8786 22.5332 18.1986 22.3832 18.4486C22.2832 18.6186 22.1132 18.7186 21.9232 18.7186H21.9332Z" fill="#3EA33A"/>
                                                <path d="M4.72227 8.77918C4.63227 8.77918 4.54226 8.75912 4.46226 8.70912L3.92234 8.39906C3.67234 8.24906 3.58227 7.92909 3.72227 7.67909C3.86227 7.42909 4.19224 7.33914 4.44224 7.47914L4.98228 7.78895C5.23228 7.93895 5.32235 8.25916 5.18235 8.50916C5.08235 8.67916 4.91227 8.77918 4.72227 8.77918Z" fill="#3EA33A"/>
                                                <path d="M8.24051 5.25926C8.06051 5.25926 7.88055 5.15924 7.78055 4.98924L7.47049 4.4492C7.33049 4.1992 7.41056 3.86923 7.67056 3.72923C7.92056 3.57923 8.24054 3.66917 8.39054 3.91917L8.70047 4.45921C8.85047 4.70921 8.76053 5.03918 8.51053 5.17918C8.43053 5.22918 8.34052 5.24925 8.25052 5.24925L8.24051 5.25926Z" fill="#3EA33A"/>
                                                <path d="M18.1789 22.469C17.9989 22.469 17.8189 22.379 17.7189 22.209L17.4089 21.6689C17.2589 21.4189 17.3489 21.089 17.5989 20.949C17.8489 20.799 18.1688 20.8889 18.3188 21.1389L18.6289 21.6789C18.7789 21.9289 18.6888 22.2589 18.4388 22.3989C18.3588 22.4489 18.2589 22.469 18.1689 22.469H18.1789Z" fill="#3EA33A"/>
                                                <path d="M4.18057 18.7198C4.00057 18.7198 3.82049 18.6198 3.72049 18.4498C3.58049 18.1998 3.66056 17.8698 3.92056 17.7298L4.46048 17.4197C4.71048 17.2797 5.03057 17.3597 5.18057 17.6197C5.32057 17.8697 5.2405 18.1997 4.9805 18.3397L4.44046 18.6497C4.36046 18.6997 4.27057 18.7198 4.18057 18.7198Z" fill="#3EA33A"/>
                                                <path d="M21.3923 8.77904C21.2123 8.77904 21.0324 8.68903 20.9324 8.51903C20.7824 8.26903 20.8723 7.93881 21.1223 7.79881L21.6623 7.489C21.9123 7.339 22.2423 7.42894 22.3823 7.67894C22.5323 7.92894 22.4422 8.25891 22.1922 8.39891L21.6523 8.70897C21.5723 8.75897 21.4823 8.77904 21.3923 8.77904Z" fill="#3EA33A"/>
                                                <path d="M9.85828 17.3192C9.58828 17.3192 9.31828 17.2194 9.10828 17.0094C8.69828 16.5994 8.69828 15.9294 9.10828 15.5094L12.1783 12.4393C12.5883 12.0293 13.2583 12.0293 13.6783 12.4393C14.0883 12.8493 14.0883 13.5193 13.6783 13.9393L10.6083 17.0094C10.3983 17.2194 10.1283 17.3192 9.85828 17.3192Z" fill="#3EA33A"/>
                                            </svg>
                                            <font className="fontLight font-size02 ms-2">{data.openTime}</font>
                                        </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <font className="fontLight font-size02"><div dangerouslySetInnerHTML={{ __html: data.description}}></div></font>
                                    </div>
                                </div>
                        </div>
                        
                    </>
                ) : ( ''
            )}
        </div>
    );
})

export default CenterMode;