import React, { useContext, useEffect } from "react"
import "./footer.css"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ChangeLanguageContext } from "../../contexts/changeLanguageContext";
import { ConfigContext } from "../../contexts/configContext";
import { useState } from "react";
import { subscribe } from "../../api/news_event_api";
import Swal from "sweetalert2";

export default function Footer() {

    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext);
    const { config } = useContext(ConfigContext);
    const [ email, setEmail ] = useState('');
    const [usefulLinkKey, setKeymap] = useState([
        '/home',
        '/offices',
        '/ourServices',
        '/newsEvent',
        '/careers',
        '/aboutUs',
        '/contactUs',
    ])
    
    const businessKey = [
        { url: '/offices', targetB: '', label: 'footer_business_office' },
        { url: '/retailCenter', targetB: '', label: 'footer_business_retail' },
        { url: '/condominium', targetB: '', label: 'footer_business_condo' },
        { url: 'https://www.hilton.com/en/hotels/bkkcici-conrad-bangkok/', targetB: '_blank', label: 'footer_business_conrad' }
    ];
    
    const subscribeNews = async () => {
        const response = await subscribe({email})
        if (response?.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Your email has been successfully stored.",
              text: "อีเมลของคุณได้รับการจัดเก็บเรียบร้อยแล้ว",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
    }

    return (
        <div className='container-fluid container-footer default-font'>
            <div className="container">
                <div className="row contact w-100 m-0 d-flex justify-content-center align-items-start">
                    <div className="col-4 d-flex align-items-center py-3 center-footer flex-column flex-lg-row">
                        <svg width={33} height={23} viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.1875 22.85H2.92749C1.82749 22.85 0.9375 21.96 0.9375 20.86V2.53004C0.9375 1.43004 1.82749 0.540039 2.92749 0.540039H30.1875C31.2875 0.540039 32.1775 1.43004 32.1775 2.53004V20.86C32.1775 21.96 31.2875 22.85 30.1875 22.85ZM2.92749 2.03004C2.64749 2.03004 2.42749 2.26004 2.42749 2.53004V20.86C2.42749 21.14 2.65749 21.36 2.92749 21.36H30.1875C30.4675 21.36 30.6875 21.13 30.6875 20.86V2.53004C30.6875 2.25004 30.4575 2.03004 30.1875 2.03004H2.92749Z" fill="#3EA33A" />
                            <path d="M16.5571 13.4101L4.21714 5.25008C3.87714 5.02008 3.77715 4.56008 4.00715 4.22008C4.23715 3.88008 4.69715 3.78008 5.03715 4.01008L16.5571 11.6301L28.0771 4.01008C28.4171 3.78008 28.8771 3.88008 29.1071 4.22008C29.3371 4.56008 29.2371 5.02008 28.8971 5.25008L16.5571 13.4101Z" fill="#3EA33A" />
                        </svg>
                        <div className='ms-0 ms-lg-3 text-center text-lg-start'>
                            <p className='mb-0 text-white'>
                                E-mail :
                            </p>
                            <a className='text-decoration-none text-white' href="mailto:info@asp.th.com">
                                info@asp.th.com
                            </a>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center py-3 contact-section center-footer flex-column flex-lg-row px-0 px-lg-2">
                        <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.9111 29.0201L25.4911 29.0001C24.1311 28.9301 16.9511 28.1601 9.09113 20.3001C1.23113 12.4401 0.461113 5.26012 0.391113 3.90012L0.371094 3.48012L0.721069 3.24012C3.16107 1.57012 5.15113 0.910116 6.78113 0.480116L7.20111 0.370117L7.51111 0.680117C9.28111 2.49012 10.9611 5.02011 11.9111 7.28012L12.1011 7.74012L9.42108 10.4201C9.74108 11.3001 10.7311 13.4401 13.3411 16.0501C15.9511 18.6601 18.0911 19.6401 18.9711 19.9701L21.6511 17.2901L22.1111 17.4801C24.3611 18.4301 26.8911 20.1101 28.7111 21.8801L29.0211 22.1901L28.9111 22.6101C28.4811 24.2401 27.8211 26.2301 26.1511 28.6701L25.9111 29.0201ZM1.92108 4.24012C2.12108 6.02012 3.29112 12.3801 10.1511 19.2401C17.0111 26.1001 23.3711 27.2701 25.1511 27.4701C26.3811 25.5901 26.9611 24.0001 27.3411 22.6601C25.8311 21.2601 23.8111 19.9101 21.9911 19.0701L19.4011 21.6601L18.9811 21.5501C18.4611 21.4101 15.7111 20.5501 12.2811 17.1101C8.85113 13.6801 7.98113 10.9301 7.84113 10.4101L7.73108 9.99012L10.3311 7.40012C9.49112 5.58012 8.14109 3.56012 6.74109 2.05012C5.40109 2.43012 3.81109 3.01012 1.93109 4.24012H1.92108Z" fill="#3EA33A"/>
                        </svg>
                        <div className='ms-0 ms-lg-3 text-center text-lg-start'>
                            <p className='mb-0 text-white'>
                                Office :
                            </p>
                            <a className='text-decoration-none text-white' href="tel:6626251888">
                                66 (0) 2625-1888
                            </a>
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-center py-3 center-footer flex-column flex-lg-row ps-0 ps-lg-2">
                        <svg width="23" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.9111 29.0201L25.4911 29.0001C24.1311 28.9301 16.9511 28.1601 9.09113 20.3001C1.23113 12.4401 0.461113 5.26012 0.391113 3.90012L0.371094 3.48012L0.721069 3.24012C3.16107 1.57012 5.15113 0.910116 6.78113 0.480116L7.20111 0.370117L7.51111 0.680117C9.28111 2.49012 10.9611 5.02011 11.9111 7.28012L12.1011 7.74012L9.42108 10.4201C9.74108 11.3001 10.7311 13.4401 13.3411 16.0501C15.9511 18.6601 18.0911 19.6401 18.9711 19.9701L21.6511 17.2901L22.1111 17.4801C24.3611 18.4301 26.8911 20.1101 28.7111 21.8801L29.0211 22.1901L28.9111 22.6101C28.4811 24.2401 27.8211 26.2301 26.1511 28.6701L25.9111 29.0201ZM1.92108 4.24012C2.12108 6.02012 3.29112 12.3801 10.1511 19.2401C17.0111 26.1001 23.3711 27.2701 25.1511 27.4701C26.3811 25.5901 26.9611 24.0001 27.3411 22.6601C25.8311 21.2601 23.8111 19.9101 21.9911 19.0701L19.4011 21.6601L18.9811 21.5501C18.4611 21.4101 15.7111 20.5501 12.2811 17.1101C8.85113 13.6801 7.98113 10.9301 7.84113 10.4101L7.73108 9.99012L10.3311 7.40012C9.49112 5.58012 8.14109 3.56012 6.74109 2.05012C5.40109 2.43012 3.81109 3.01012 1.93109 4.24012H1.92108Z" fill="#3EA33A"/>
                        </svg>
                        <div className='ms-0 ms-lg-3 text-center text-lg-start'>
                            <p className='mb-0 text-white'>
                                Customer Hotline :
                            </p>
                            <a className='text-decoration-none text-white' href="tel:6626251800">
                                66 (0) 2625-1800
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom border-section-color mb-3"></div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="logo d-flex align-items-center justify-content-center justify-content-lg-start">
                            <img className="logo-asp-ft" src="assets/images/asp-logo-color.png" alt="asp" />
                        </div>
                        <div className='mt-4 px-3 px-lg-0'>
                            <p>{t('footer_description')}</p>
                        </div>
                    </div>
                    <div className="col-6 col-xl-2 d-flex flex-column justify-content-between custom-list center-footer">
                        <h5 className="mt-3 fontBold text-white mgl-18">{t('footer_useful_link')}</h5>
                        <div className="h-100 custom-list d-flex flex-column">
                            {/* footer_menu_home
                            footer_menu_business
                            footer_menu_services_privileges
                            footer_menu_about_us
                            footer_menu_careers
                            footer_menu_contact_us
                            footer_menu_news_event */}
                            {
                                [
                                    t('footer_menu_home'), 
                                    t('footer_menu_business'), 
                                    t('footer_menu_services_privileges'), 
                                    t('footer_menu_news_event'), 
                                    t('footer_menu_careers'), 
                                    t('footer_menu_about_us'), 
                                    t('footer_menu_contact_us')].map((item, index) => {
                                    return (
                                        <Link to={usefulLinkKey[index]}
                                            className="text-decoration-none"
                                            key={index}
                                        >{item}
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-6 col-xl-2 d-flex flex-column justify-content-between center-footer">
                        <h5 className="mt-3 fontBold text-white mgl-18">{t('footer_business')}</h5>
                        <div className="h-100 custom-list d-flex flex-column">
                            {businessKey.map((item, index) => (
                                <Link
                                to={item.url}
                                className="text-decoration-none"
                                key={index}
                                target={item.targetB}
                                >
                                {t(item.label)}
                                </Link>
                            ))}
                        </div>
                    </div>
               
                    <div className="col-xl-4 center-footer flex-column">
                        <h5 className="mt-3 fontBold text-white">{t('footer_newsletter')}</h5>
                        <p>{t("footer_newsletter_description")}</p>
                        <div className="d-flex mb-5">
                            <input type="email" className="subscribe" onChange={e => setEmail(e.currentTarget.value)}placeholder={t("footer_newsletter_placeholder")} />
                            <button className="btn bg-main-green ms-2" onClick={() => subscribeNews()}>
                                <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M28.5535 11.0198L0.523438 0.529785L11.8534 11.0198L0.523438 21.4998L28.5535 11.0198Z" fill="#FEFEFE" />
                                </svg>
                            </button>
                        </div>
                        {/* social icon*/}
                        <div className="d-flex">
                            {config?.link_social_1 &&  config?.img_social_1 && 
                                <div className="me-2">
                                    <a href={config?.link_social_1?.startsWith('http') ? config?.link_social_1 : `//${config?.link_social_1}`  } target="_blank" rel="noreferrer">
                                        <img src={config?.img_social_1} width="38" height="37" alt=""/>
                                    </a>
                                </div>
                            }

                            {config?.link_social_2 && config?.img_social_2 && 
                                <div className="me-2">
                                    <a href={config?.link_social_2?.startsWith('http') ? config?.link_social_2 : `//${config?.link_social_2}`} target="_blank" rel="noreferrer">
                                        <img src={config?.img_social_2} width="38" height="37" alt=""/>
                                    </a>
                                </div>
                            }

                            {config?.link_social_3 &&  config?.img_social_3 && 
                                <div className="me-2">
                                    <a href={config?.link_social_3?.startsWith('http') ? config?.link_social_3 : `//${config?.link_social_3}` } target="_blank" rel="noreferrer">
                                        <img src={config?.img_social_3} width="38" height="37" alt=""/>
                                    </a>
                                </div>
                            }

                            {config?.link_social_4 &&  config?.img_social_4 && 
                                <div className="me-2">
                                    <a href={config?.link_social_4?.startsWith('http') ? config?.link_social_4: `//${config?.link_social_4}`  } target="_blank" rel="noreferrer">
                                        <img src={config?.img_social_4} width="38" height="37" alt=""/>
                                    </a>
                                </div>
                            }

                            {config?.link_social_5 &&  config?.img_social_5 && 
                                <div className="me-2">
                                    <a href={config?.link_social_5?.startsWith('http') ? config?.link_social_5 : `//${config?.link_social_5}` } target="_blank" rel="noreferrer">
                                        <img src={config?.img_social_5} width="38" height="37" alt=""/>
                                    </a>
                                </div>
                            }
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom border-section-color mt-4"></div>
            <div className="container mt-4 center-footer">
                <div className="row py-3">
                    <div className="col-12">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.08 8.86C8.13 8.53 8.24 8.24 8.38 7.99C8.52 7.74 8.72 7.53 8.97 7.37C9.21 7.22 9.51 7.15 9.88 7.14C10.11 7.15 10.32 7.19 10.51 7.27C10.71 7.36 10.89 7.48 11.03 7.63C11.17 7.78 11.28 7.96 11.37 8.16C11.46 8.36 11.5 8.58 11.51 8.8H13.3C13.28 8.33 13.19 7.9 13.02 7.51C12.85 7.12 12.62 6.78 12.32 6.5C12.02 6.22 11.66 6 11.24 5.84C10.82 5.68 10.36 5.61 9.85 5.61C9.2 5.61 8.63 5.72 8.15 5.95C7.67 6.18 7.27 6.48 6.95 6.87C6.63 7.26 6.39 7.71 6.24 8.23C6.09 8.75 6 9.29 6 9.87V10.14C6 10.72 6.08 11.26 6.23 11.78C6.38 12.3 6.62 12.75 6.94 13.13C7.26 13.51 7.66 13.82 8.14 14.04C8.62 14.26 9.19 14.38 9.84 14.38C10.31 14.38 10.75 14.3 11.16 14.15C11.57 14 11.93 13.79 12.24 13.52C12.55 13.25 12.8 12.94 12.98 12.58C13.16 12.22 13.27 11.84 13.28 11.43H11.49C11.48 11.64 11.43 11.83 11.34 12.01C11.25 12.19 11.13 12.34 10.98 12.47C10.83 12.6 10.66 12.7 10.46 12.77C10.27 12.84 10.07 12.86 9.86 12.87C9.5 12.86 9.2 12.79 8.97 12.64C8.72 12.48 8.52 12.27 8.38 12.02C8.24 11.77 8.13 11.47 8.08 11.14C8.03 10.81 8 10.47 8 10.14V9.87C8 9.52 8.03 9.19 8.08 8.86ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="currentColor"/>
                        </svg> 2024 All Seasons Place
                    </div>
                </div>
            </div>
        </div>
    )
}
