import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Thumbnail from "./Thumbnail";
import "./UploadIMG.css";
import "../swalStyle/customStyle.css";

const UploadProfile = (props) => {
  const dropzoneRef = useRef();
  const Swal = require("sweetalert2");
  const imgCount = useRef(0);
  const [images, setImages] = useState([]);
  const [isImageFull, setIsImageFull] = useState(false);

  let imgBoxs = useRef([]);
  let isboxInit = useRef(false);
  if (!isboxInit.current) {
    for (let i = 0; i < props.limit; i++) {
      imgBoxs.current.push("box");
      isboxInit.current = true;
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  var imgindex = 0;

  const onDrop = useCallback((acceptedFiles) => {
    handleFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ref: dropzoneRef, // Attach the ref here
  });

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    if (props.images && props.images.length > 0) {
      handleFiles(props.images);
    }
  }, [props.images]);

  const removeFile = (id) => {
    const removeimageIndex = images.findIndex((e) => e.id === id);
    if (removeimageIndex !== -1) {
      imgCount.current--;
      images.splice(removeimageIndex, 1);
      setImages([...images]);
      imgBoxs.current.push("box");
      setIsImageFull(false);
    }
  };

  const thumbs = images.map((file, index) => (
    <Thumbnail key={index} file={file} removeFile={removeFile} />
  ));

  const handleFiles = (files) => {
    if (!files || !files[0].file && !files[0].path) {
      return;
    }

    if (!props.limit) {
      props.limit = 1;
    }

    let filesArray = [...images];
    let errormsg = "";
    let slots = props.limit - imgCount.current;
    if (files.length <= slots) {
      slots = files.length;
    }

    if (slots + imgCount.current >= props.limit) {
      setIsImageFull(true);
    } else {
      setIsImageFull(false);
    }

    for (let i = 0; i < slots; i++) {
      if (validateFile(files[i]) && files[i].size <= 1024 * 1024 * 5) {
        imgCount.current++;
        filesArray.push(files[i]);
        imgBoxs.current.pop();
      } else {
        setIsImageFull(false);
        files[i]["invalid"] = true;
        if (files[i].size > 1024 * 1024 * 5) {
          errormsg += files[i].name + props.txtDetail;
          setErrorMessage(files[i].name + props.txtDetail);
        } else {
          errormsg += files[i].name + props.txtDetail;
          setErrorMessage(files[i].name + props.txtDetail);
        }
      }
      if (errormsg !== "") {
        Swal.fire({
          title: "Error!",
          text: errormsg,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }

    try {
      filesArray.map((file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            {
              id: imgindex++,
              src: e.target.result,
              file: file,
            },
          ]);
        };
        if (file?.file) {
          reader.readAsDataURL(file?.file);
        } else if (file) {
          reader.readAsDataURL(file);
        }
        return file;
      });
    } catch (err) {
      console.log(err);
    }

    return filesArray.length;
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    props.onSelectedImages(images);
    return () => images.forEach((file) => URL.revokeObjectURL(file.src));
  }, [images]);

  return (
    <section className="upload fontReg font-size09">
      <style>
        {`
          .btn-upload {
            width: fit-content !important;
          }
          @media (max-width: 768px) {
            .w-35 {
              width: 100% !important;
            }
          }
        `}
      </style>
      <div className="row w-100">
        <div className={`col-12 align-items-center ${isImageFull ? "d-none" : "d-flex"}`}>
          <div className="row w-35">
            <div
              {...getRootProps({ className: `'dropzone' row` })}
            >
              <input {...getInputProps()} />
              <div className="col-6 col-xl-6">
                <aside className="thumbsContainer">
                  {thumbs}
                  <div className="boxthumb01">
                    <svg width="75" height="82" viewBox="0 0 75 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M37.8109 45.5701C50.0164 45.5701 59.9109 35.6756 59.9109 23.4701C59.9109 11.2646 50.0164 1.37012 37.8109 1.37012C25.6054 1.37012 15.7109 11.2646 15.7109 23.4701C15.7109 35.6756 25.6054 45.5701 37.8109 45.5701Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                      <path d="M1.96094 81.7502C1.96094 61.9602 18.0109 45.9102 37.8009 45.9102C57.5909 45.9102 73.6409 61.9602 73.6409 81.7502" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                    </svg>
                  </div>
                </aside>
              </div>
              <div className="col-6 col-xl-6 d-flex flex-column justify-content-end align-items-start">
                <button type="button" className="btn-upload fontReg font-size03 ms-0" onClick={openDialog}>
                  Upload Profile
                </button>
                <span
                  className={
                    images.length > 0
                      ? "d-none"
                      : "fontReg font-size03 mt-2"
                  }
                >
                  {props.txtDetail}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${isImageFull ? "d-flex" : "d-none"}`}>
          <div className="col-12 col-md-2 mt-4 mt-md-0 px-0">
            <aside className="thumbsContainer">
              {thumbs}
              {imgBoxs.current.map((count, index) => {
                return (
                  <div className="boxthumb" key={index}>
                    <span className="countimg fontReg font-size03">
                      {imgCount.current + "/" + props.limit}
                    </span>
                  </div>
                );
              })}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UploadProfile;
