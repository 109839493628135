import React from 'react';
import { Link } from 'react-router-dom';
import "./landing.css";
import LinesEllipsis from 'react-lines-ellipsis'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

export default function LandingContent({
    image,
    title,
    detail,
    path,
    showLink,
    alignContentLeft,
    ellipsTxt,
    onButtonClick
}) {
    if (alignContentLeft) {
        return (
            <div className="row bg-light-white container-landing-content">
                <div className="content col-lg-6 d-flex align-items-center justify-content-center order-text02">
                    <div className="d-flex flex-column justify-content-center h-100 w-landing">
                        <ScrollAnimation animateIn="fadeInLeft" delay={100}>
                        <h2 className='fontBold text-dark font-size04'>{title}</h2>
                        {ellipsTxt == true?
                            <>
                                <div className="multi-line-ellipsis font-size02 fontLight mt-3">
                                    <div dangerouslySetInnerHTML={{ __html: detail}}></div>
                                </div>
                                <button className='btn-seeBG fontMed font-size02' onClick={onButtonClick}>
                                    See More
                                </button>
                            </>
                        :
                            <p className='mb-0 font-size02 fontLight mt-3'>{detail}</p>
                        }
                        {showLink && (
                            <Link to={path ?? "/"} className='text-decoration-none text-dark fw-bold mt-5'>
                                <div className='d-flex align-items-center'>
                                    <div className='fontMed font-size03'>
                                        SEE MORE
                                    </div>
                                    <div className='mx-2'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.0099 30.8401C24.25 30.8401 30.9299 24.16 30.9299 15.9199C30.9299 7.67983 24.25 1 16.0099 1C7.7698 1 1.08984 7.67983 1.08984 15.9199C1.08984 24.16 7.7698 30.8401 16.0099 30.8401Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13.4492 10.4395L18.6593 15.6494L13.4492 20.8696" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        )}
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="col-lg-6 p-0 order-img02">
                    <img src={`${image}`} className='img-style-card04' alt={title} />
                </div>
            </div>
        );
    }

    // If alignContentLeft is false or not provided
    return (
        <div className="row bg-light-white">
            <div className="col-lg-6 p-0 order-img01">
                <img src={`${image}`} className='img-style-card04' alt={title} />
            </div>
            <div className="content col-lg-6 d-flex align-items-center justify-content-center order-text01">
                <div className="d-flex flex-column justify-content-center h-100 w-landing">
                <ScrollAnimation animateIn="fadeInRight" delay={200}>
                <h2 className='fontBold text-dark font-size04'>{title}</h2>
                {ellipsTxt == true?
                    <>
                        <div className="multi-line-ellipsis font-size02 fontLight mt-3">
                            <div dangerouslySetInnerHTML={{ __html: detail}}></div>
                        </div>
                        <button className='btn-seeBG fontMed font-size02' onClick={onButtonClick}>
                            See More
                        </button>
                    </>
                    :
                        <p className='mb-0 font-size02 fontLight mt-3'>{detail}</p>
                }                    
                {showLink ? (
                        <Link to={path ?? "/"} className='text-decoration-none text-dark fw-bold mt-5'>
                            <div className='d-flex align-items-center'>
                                <div className='fontMed font-size03'>
                                    SEE MORE
                                </div>
                                <div className='mx-2'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0099 30.8401C24.25 30.8401 30.9299 24.16 30.9299 15.9199C30.9299 7.67983 24.25 1 16.0099 1C7.7698 1 1.08984 7.67983 1.08984 15.9199C1.08984 24.16 7.7698 30.8401 16.0099 30.8401Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.4492 10.4395L18.6593 15.6494L13.4492 20.8696" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </Link>
                    ) : null}
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
}