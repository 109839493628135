import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import ShowTextFile from "./ShowTextFile";
import "./UploadIMG.css";
import "../swalStyle/customStyle.css";

function UploadIMG(props) {
  const dropzoneRef = useRef();
  const Swal = require("sweetalert2");
  const imgCount = useRef(0);
  const [filesPdf, setfilesPdf] = useState([]);
  const [isFileFull, setIsFileFull] = useState(false);

  let imgBoxs = useRef([]);
  let isboxInit = useRef(false);
  if (!isboxInit.current) {
    for (let i = 0; i < props.limit; i++) {
      imgBoxs.current.push("box");
      isboxInit.current = true;
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  var imgindex = 0;
  const onDrop = useCallback((acceptedFiles) => {

    handleFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ref: dropzoneRef,
  });
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  useEffect(() => {
    if(props.selectFiles){
      if(props.selectFiles.length>0)
      {
   
        handleFiles(props.selectFiles);
      }
    }
  }, [props.selectFiles])

  const removeFile = (id) => {
    const removefileIndex = filesPdf.findIndex((e) => e.id === id);
    if (removefileIndex !== -1) {
      imgCount.current--;
      filesPdf.splice(removefileIndex, 1);
      // update unsupportedFiles array
      setfilesPdf([...filesPdf]);

      imgBoxs.current.push("box");
      setIsFileFull(false);
    }
  };

  const thumbs = filesPdf.map((file, index) => (
    <ShowTextFile key={index} file={file} removeFile={removeFile} />
  ));
 
  const handleFiles = (files) => {
 
                
    if(files){
      if(!files[0].file && !files[0].path){
        return
      }
    }
       
    if (!props.limit) {
      props.limit = 1;
    }

    let filesArray = [...filesPdf];

    let errormsg = "";
    let slots = 0;
    slots = props.limit - imgCount.current;
    if (files.length <= slots) {
      slots = files.length;
    }

    if (slots + imgCount.current >= props.limit) {
      setIsFileFull(true);
    } else {
      setIsFileFull(false);
    }

    for (let i = 0; i < slots; i++) {
      if (validateFile(files[i]) && files[i].size <= 1024 * 1024 * 5) {
        imgCount.current++;

        filesArray.push(files[i]);
        imgBoxs.current.pop();
      } else {
        // add a new property called invalid
        setIsFileFull(false);
        files[i]["invalid"] = true;
        if (files[i].size > 1024 * 1024 * 5) {
          errormsg += files[i].name + props.txtDetail;
          setErrorMessage(files[i].name + props.txtDetail);
        } else {
          errormsg += files[i].name + props.txtDetail;
          setErrorMessage(files[i].name + props.txtDetail);
        }
      }
      if (errormsg !== "") {
        Swal.fire({
          title: "Error!",
          text: errormsg,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }
    try{
    filesArray.map((file) => {
      const reader = new FileReader();

      reader.onload = function (e) {

        setfilesPdf((prevState) => [
          ...prevState,
          {
            id: imgindex++,
            src: e.target.result,
            file: file,
          },
        ]);
      };
      if(file?.file)
      {
        reader.readAsDataURL(file?.file);
      }
      else if(file){
        reader.readAsDataURL(file);
      }
      return file;
    });
    }
    catch(err){
      console.log(err)
    }

    return filesArray.length;
  };

  const validateFile = (file) => {
    const validTypes = ["application/pdf", "image/png", "image/jpeg"];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    props.onSelectedFile(filesPdf);
    return () => filesPdf.forEach((file) => URL.revokeObjectURL(file.src));
  }, [filesPdf]);

  return (
    <section className="upload fontReg font-size09">
      <div className="row w-100">
        <div
          className={`col-12 col-md-10 align-items-center  ${isFileFull ? "d-none" : "d-flex"} `}
        >
          <div
            {...getRootProps({ className: `'dropzone' card-upload` })}
            //onDrop={fileDrop}
          >
            <input {...getInputProps()} />
            <p className="mb-0 fontReg font-size03">Upload File</p>
            <button type="button" className="btn-upload fontReg font-size03" onClick={openDialog}>
              Choose File
            </button>
            <span
              className={
                filesPdf.length > 0
                ? "d-none"
                : "fontReg font-size03"
                }
              >
              {props.txtDetail}
            </span>
          </div>
        </div>
        <div className="col-12 col-md-2 mt-4 mt-md-0 px-0">
          <aside className="thumbsContainer">
            {thumbs}
            {imgBoxs.current.map((count, index) => {
              return (
                <div className="boxthumb d-flex align-items-center" key={index}>
                  <font className="fontReg font-size03 colorGray01">
                    file name
                  </font>
                  <span className="countimg fontReg font-size03">
                    {/* {index + 1 + "/" + props.limit} */}
                    {imgCount.current + "/" + props.limit}
                  </span>
                </div>
              );
            })}
          </aside>
        </div>
      </div>
    </section>
  );
}

export default UploadIMG;