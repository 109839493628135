import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './NewsEvent.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Button, Col, Row } from "react-bootstrap";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataNewsEvent } from '../../api/news_event_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import { useTranslation } from "react-i18next"
import FacebookShare from '../ShareSocial/FacebookShare';
import TwitterShare from '../ShareSocial/TwitterShare';
import LineShare from '../ShareSocial/LineShare';
import LinkCopy from '../ShareSocial/LinkCopy';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function NewsEvent() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { t } = useTranslation()
    const { lang } = useContext(ChangeLanguageContext);

    const [dataId, setDataId] = useState(null);
    const [type, setType] = useState('');
    const [activeTab, setActiveTab] = useState('news');

    const [headBanner, setHeadBanner] = useState({
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "NEWS & EVENT",
        isVideo: "image",
    });

    const navigateToDetail = (id, type) => {
        return `/newsEventDetail?id=${id}&type=${type}`;
    };

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const [listDatas, setListDatas] = useState([
      {
        _id: '',
        imgItem: '',
        txtTitle: '',
        effectiveDate: '',
        endDate: '',
        txtDetail: "",
        shareFB: '',
        shareX: '',
        shareXtext: 'All Season',
        shareLine: '',
        shareLineText: 'All Season',
        link: '',
      },
      {
        _id: '',
        imgItem: '',
        txtTitle: '',
        effectiveDate: '',
        endDate: '',
        txtDetail: "",
        shareFB: '',
        shareX: '',
        shareXtext: 'All Season',
        shareLine: '',
        shareLineText: 'All Season',
        link: '',
      },
      {
        _id: '',
        imgItem: '',
        txtTitle: '',
        effectiveDate: '',
        endDate: '',
        txtDetail: "",
        shareFB: '',
        shareX: '',
        shareXtext: 'All Season',
        shareLine: '',
        shareLineText: 'All Season',
        link: '',
      },
      {
        _id: '',
        imgItem: '',
        txtTitle: '',
        effectiveDate: '',
        endDate: '',
        txtDetail: "",
        shareFB: '',
        shareX: '',
        shareXtext: 'All Season',
        shareLine: '',
        shareLineText: 'All Season',
        link: '',
      },
    ]);

    const [tableParam, setTableParam] = useState({
      page_number:1,
      data_per_page:4,
    }); //กำหนด column เริ่มต้นในการ sort
    const [tableParamInfo, setTableParamInfo] = useState({
      page_number:1,
      data_per_page:4,
    }); //กำหนด column เริ่มต้นในการ sort
    const [totalNumber, setTotalNumber] = useState(0)
    useEffect(()=>{
      fetchingData()
    },[])

    const fetchingData = async (language) => {
        try {
            let type = 'news'
            if(activeTab=='news'){
                type = 'news'
            }
            else if(activeTab=='event'){
                type = 'event'
            }
            const infoDefault = {
                type:type,
                data_per_page: 5
            }
            setType(infoDefault.type);
            const result = await getDataNewsEvent(infoDefault, language);
            const bannerData = result?.data?.data?.banner?.data;
            if (bannerData) {
                const banners = {
                    original: bannerData.data,
                    thumbnail: bannerData.data,
                    isVideo: bannerData.format || "image",
                };
                setHeadBanner(banners);
            } else {
                console.error('Banner data is undefined');
            }
            const infoList = result?.data?.data?.contentdata;
            if (infoList && infoList.length > 0) {
              const updatedInfoListDatas = infoList.map(detail => {
                  return {
                      _id: detail._id,
                      imgItem: detail.col1_imgs?.length>0?detail.col1_imgs[0]:'',
                      txtTitle: detail.col1_title,
                      effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                      endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                      txtDetail: detail.col1_description,
                      shareFB: detail.link,
                      shareLine: detail.link,
                      shareX: detail.link,
                      link: detail.link,
                      shareLineText: detail?.shareLineText ,
                      shareXtext: detail?.shareXtext ,
                  };
              });
              setListDatas(updatedInfoListDatas);
          } else {
                // Handle case when detail_list is empty
                // You may want to set an empty array or handle it differently
                setListDatas([]);
            }
        } catch (error) {
            console.log('found error', error);
        }
    };
    
    const handleSeeMore = async (type,language) => {
      try {
          // Determine type based on activeTab
          let paramType = 'news';
          if (activeTab === 'event') {
              paramType = 'event';
          }
  
          let param = {};
          if (paramType === 'event') {
              param = {
                  page_number: tableParam.page_number, // Assuming page_number is incremented elsewhere if needed
                  data_per_page: tableParam.data_per_page + 5,
                  type: paramType
              };
              setTableParam((prevTableParam) => ({
                  ...prevTableParam,
                  data_per_page: prevTableParam.data_per_page + 4
              }));
          } else {
   
              param = {
                  page_number: tableParamInfo.page_number, // Assuming page_number is incremented elsewhere if needed
                  data_per_page: tableParamInfo.data_per_page + 5,
                  type: paramType
              };
              setTableParamInfo((prevTableParamInfo) => ({
                  ...prevTableParamInfo,
                  data_per_page: prevTableParamInfo.data_per_page + 4
              }));
          }
    
          // Now call getDataNewsEvent with the correct param
          const result = await getDataNewsEvent(param);

          setTotalNumber(result.data.data.meta.total);
  
          // Assuming the result contains additional data to append to the existing list
          const infoList = result?.data?.data?.contentdata;
          if (infoList && infoList.length > 0) {
              const updatedInfoListDatas = infoList.map(detail => ({
                  _id: detail._id,
                  imgItem: detail.col1_imgs?.length>0?detail.col1_imgs[0]:'',
                  txtTitle: detail.col1_title, // Assuming txtName maps to col1_title
                  effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                  endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                  txtDetail: detail.col1_description, // Assuming txtDetail maps to col1_description
                  shareFB: detail.link,
                  shareLine: detail.link,
                  shareX: detail.link,
                  link: detail.link,
              }));
              // Append the new data to the existing list
              // setListDatas(prevList => [...prevList, ...updatedInfoListDatas]);
              setListDatas(updatedInfoListDatas);
          } else {
              // Handle case when contentdata is empty
              // You may want to set an empty array or handle it differently
              // Here, we are not modifying the existing list
          }
      } catch (error) {
          console.log('found error', error);
      }
  };

    useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang);
    }, [lang])

    useEffect(() => {
        
        fetchingData(lang === 'en-US' ? 'en' : lang);
    }, [activeTab]);

  return (
    <div>
        <style>
            {`
                ul.menu li a#news_event {
                    color: #3AF823;
                    text-decoration: underline;
                }
                .bd-bottom01 {
                    border-bottom: 6px solid #3EA33A;
                    width: 9% !important;
                    position: relative;
                    display: block;
                }
                .detail {
                    height: auto !important;
                    margin-left: -155px !important;
                    width: 117% !important;
                }
                .sty-promo1 {
                    display: block !important;
                    background-color: #F4F7FA;
                    padding: 25px;
                    width: 100% !important;
                }

                @media screen and (max-width: 1550px) {
                    .sty-promo1 {
                        padding: 15px;
                    }
                }

                @media screen and (max-width: 1400px) {
                    .sty-promo1 {
                        padding: 25px;
                    }
                }

                @media (max-width: 1200px) {
                    .detail {
                            width: 100% !important;
                            margin-left: 0px !important;
                            min-height: 310px;
                    }
                }
            `}
        </style>
        <Header />
        <div className="container-fluid w-100">
            
            <div className="row">
                <div className="col-12 px-0">
                    <div className="image-gallery-wrapper">
                    <div className="position-relative">
                        <img src={headBanner.original} alt='banner' className="banner-title" />
                        <div className="txt-banner">
                        <p className="fontBold font-size10 colorWhite mb-0">{t('news_event')}</p>
                        <p className="bd-bottom-banner"></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn='fadeInUp'
            initiallyVisible={true}>
            <div className='button-container'>
                    <Row className='title-btn2 p-0 w-100 mx-0 mt-5'>
                        <Col xs={6} className='d-flex justify-content-end'>
                            <Button 
                                className={`btn-bidInfo btn-bidding01 fontMed font-size05 ${activeTab === 'news' ? 'active' : ''}`}
                                onClick={() => handleTabClick('news')}>
                                {t('news')}
                            </Button>
                        </Col>
                        <Col xs={6} className='d-flex justify-content-start'>
                            <Button 
                                className={`btn-bidWinn btn-bidding01 fontMed font-size05 py-2 ${activeTab === 'event' ? 'active' : ''}`}
                                onClick={() => handleTabClick('event')}>
                                {t('event')}
                            </Button>
                        </Col>
                    </Row>
            </div>
            </ScrollAnimation>
            {/* start */}
           
            <ScrollAnimation animateIn='fadeInUp'
            initiallyVisible={true}>
            <div className="info-container">
                    <div className="info-data">
                        <div className="mx-10 mt-4">
                            <HeadingSection
                                title={activeTab === 'news' ? t('news') : t('event')}
                                position='center'
                                customize={{
                                    paddingBottom: '1rem',
                                    paddingTop: '2rem'
                                }}
                                classCss="pdt-hd"
                                subTitleDetail="d-block"
                            />
                            <div className='w-100 d-flex justify-content-center mt-46'><span className="bd-bottom01 mt-6"></span></div>
                        </div>
                    </div>
            </div>
            </ScrollAnimation>
            {
                listDatas.length <= 0 ? (
                <>
                    {<ScrollAnimation animateIn='fadeIn'
                    initiallyVisible={true}>
                    <div className='sty-col1 m-5 pb-5'>
                        <div className="col-12 col-xl-12">
                            <div className='row justify-content-center align-items-center' >
                                {/* <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{t('news_news_empty_data')}</p> */}
                                <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{activeTab === 'news' ? t('news_news_empty_data') : t('news_event_empty_data')}</p>
                            </div>
                        </div>
                    </div>
                    </ScrollAnimation>}
                </>
                ) : 
                <>
                    <ScrollAnimation animateIn='fadeIn'
                    initiallyVisible={true}>
                    <div className="sty-col1 m-5">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-xl-6">
                                    <img src={listDatas[0].imgItem} className='img-style-card04' alt=''/>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <ScrollAnimation initiallyVisible={true} animateIn="fadeInRight" delay={100}>
                                    <div className='detail d-flex flex-column align-items-start text-dark bg-light-white p-4'>
                                        <p className="fontLight font-size02 colorGray01 mb-3">{activeTab === 'news' ? t('news') : t('event')}</p>
                                        <p className="fontMed font-size04 colorBlack01 mb-3">{listDatas[0].txtTitle}</p>
                                        <p className="font-size02 fontLight colorBlack01 mb-0 mb-md-1 text-underline">{t('detail')}</p>
                                        <p className="font-size02 fontLight colorBlack01 mb-0 mb-md-3">
                                        <div dangerouslySetInnerHTML={{ __html: listDatas[0].txtDetail}}></div>
                                        </p>
                                        <p className="font-size02 colorBlack01 fontReg mb-0 mb-md-3">{listDatas[0].effectiveDate} - {listDatas[0].endDate}</p>
                                        <div className="d-flex justify-content-start w-100">
                                            <div className="d-flex justify-content-start justify-content-md-between flex-column flex-md-row w-100">
                                                <div className='d-flex flex-row align-items-center w-fit-content'>
                                                    <font className="font-size02 fontMed colorBlack01 text-decoration-none">
                                                        SHARE
                                                    </font>
                                                    <div className='ms-3 w-100'>
                                                        <FacebookShare url={listDatas[0].shareFB} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <TwitterShare url={listDatas[0].shareX} text={listDatas[0].shareXtext} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <LineShare url={listDatas[0].shareLine} text={listDatas[0].shareLineText} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <LinkCopy url={listDatas[0].link}></LinkCopy>
                                                    </div>
                                                </div>
                                                <div className='align-items-end'>
                                                    <Link to={navigateToDetail(listDatas[0]._id, activeTab=='news'?'news':'event')} className="fontReg font-size02 readmore">{t('news_event_continue_reading')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn'
                        animatePreScroll={false}>
                    <div className="m-5">
                        <div className='row justify-content-center align-items-start'>
                            {listDatas.map((item, index) => {
                                if(index>0)
                                return(
                                    <div key={index} className='col-12 col-lg-6 col-xxl-3'>
                                        <div width={'100%'}>
                                            <img src={item.imgItem} className='img-style-card05' alt=''/>
                                        </div>
                                        <div className="sty-promo1">
                                            <ScrollAnimation animateIn="fadeInUp" delay={100}>
                                            <div className='w-card01'>
                                                <p className="fontLight font-size02 colorGray01 mb-2">{activeTab === 'news' ? t('news') : t('event')}</p>
                                                <p className="fontBold font-size05 colorBlack01 mb-2">{item.txtTitle}</p>
                                                <p className="font-size02 colorBlack01 fontLight mb-0 mb-md-1 multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: item.txtDetail}}></div></p>
                                                {/* <p className="font-size02 colorBlack01 fontLight mb-1 mb-md-1">{item.effectiveDate}</p> */}
                                                <p className="font-size02 colorBlack01 fontLight mb-1 mb-md-1">{`${item.effectiveDate} - ${item.endDate}`}</p>
                                                <font className="colorBlack text-underline font-size03 fontMed">
                                                    SHARE
                                                </font>
                                                <div className="d-flex justify-content-start justify-content-md-between flex-column flex-md-row align-items-start align-items-md-center w-100 mt-1">
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <div className='w-100'>
                                                            <FacebookShare url={item.shareFB} />
                                                        </div>
                                                        <div className='ms-2 w-100'>
                                                            <TwitterShare url={item.shareX} text={item.shareXtext} />
                                                        </div>
                                                        <div className='ms-2 w-100'>
                                                            <LineShare url={item.shareLine} text={item.shareLineText} />
                                                        </div>
                                                        <div className='ms-2 w-100'>
                                                            <LinkCopy url={item.link}></LinkCopy>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mt-md-0 d-flex justify-content-end">
                                                        <Link to={navigateToDetail(item._id, activeTab=='news'?'news':'event')} className="fontReg font-size03 readmore">
                                                        {t('news_event_continue_reading')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                )
                                else
                                return null
                            })}
                        </div>
                    </div>
                    <Row className="my-4">
                        <Col xs={12} className="d-flex justify-content-center align-items-center">
                            <Button className="btn-apply font-size03 fontBold" onClick={() => handleSeeMore()}>
                            {/* SEE MORE NEWS&nbsp; */}
                            {t('see_more_news_event')}&nbsp;
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7491 26.8896C20.9067 26.8896 26.709 21.0873 26.709 13.9297C26.709 6.77208 20.9067 0.969727 13.7491 0.969727C6.59147 0.969727 0.789062 6.77208 0.789062 13.9297C0.789062 21.0873 6.59147 26.8896 13.7491 26.8896Z" fill="black"/>
                                <path d="M13.1094 9.99023L17.0494 13.9302L13.1094 17.8704" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </Button>
                        </Col>
                    </Row>
                    </ScrollAnimation>
                </>
            }
            {/* <p className="font-size02 fontLight colorBlack01 mb-0 mb-md-1 text-underline">{t('detail')}</p> */}
            {/* <p>asdasd</p> */}
            {/* end */}
        </div>
        <div>
            <ScrollToTopButton />
        </div>
        <Footer />
    </div>
  )
}

export default NewsEvent;