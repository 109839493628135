import React, { useEffect, useState, useContext } from 'react';
import './OurService.css';
import { Col, Row, Button } from "react-bootstrap";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import CardSlider4 from "../../components/cardSlide/Slick4"
import Swal from "sweetalert2";
import { getDataCarpark } from '../../api/our_service_api';
import { useTranslation } from "react-i18next"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

function Carpark() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { t } = useTranslation()

    const { lang } = useContext(ChangeLanguageContext);
    const [titleDesc, setTitleDesc] = useState('');
    const [txtDesc, setTxtDesc] = useState('');
    const [imgSlidDesc1, setImgSlidDesc1] = useState([]);
    const [titleSlidDesc1, setTitleSlidDesc1] = useState('');
    const [txtSlidDesc1, setTxtSlidDesc1] = useState('');
    const [imgSlidDesc2, setImgSlidDesc2] = useState([]);
    const [titleSlidDesc2, setTitleSlidDesc2] = useState('');
    const [txtSlidDesc2, setTxtSlidDesc2] = useState('');
    const [imgSlidDesc3, setImgSlidDesc3] = useState([]);
    const [titleSlidDesc3, setTitleSlidDesc3] = useState('');
    const [txtSlidDesc3, setTxtSlidDesc3] = useState('');
    const [imgSlidDesc4, setImgSlidDesc4] = useState([]);
    const [titleSlidDesc4, setTitleSlidDesc4] = useState('');
    const [txtSlidDesc4, setTxtSlidDesc4] = useState('');
    const [imgSlidDesc5, setImgSlidDesc5] = useState([]);
    const [titleSlidDesc5, setTitleSlidDesc5] = useState('');
    const [txtSlidDesc5, setTxtSlidDesc5] = useState('');
    const [imgSlidDesc6, setImgSlidDesc6] = useState([]);
    const [titleSlidDesc6, setTitleSlidDesc6] = useState('');
    const [txtSlidDesc6, setTxtSlidDesc6] = useState('');

    const fetchingData = async (language) => {
        try {
            const result = await getDataCarpark({}, language);
            setTxtDesc(result?.data?.data?.content?.carpark_description);
            setTxtSlidDesc1(result?.data?.data?.content?.lpr_description);
            setTxtSlidDesc2(result?.data?.data?.content?.lady_parking_description);
            setTxtSlidDesc3(result?.data?.data?.content?.callpoint_description);
            setTxtSlidDesc6(result?.data?.data?.content?.shuttle_description);
            setTxtSlidDesc4(result?.data?.data?.content?.driver_description);
            setTxtSlidDesc5(result?.data?.data?.content?.facial_recog_description);
            const imgLPR = result?.data?.data?.content?.lpr_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc1(imgLPR);

            const imgLady = result?.data?.data?.content?.lady_parking_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc2(imgLady);

            const imgCallpoint = result?.data?.data?.content?.callpoint_description_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc3(imgCallpoint);
            
            const imgDriver = result?.data?.data?.content?.driver_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc4(imgDriver);

            const imgFacial = result?.data?.data?.content?.facial_recog_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc5(imgFacial);

            const imgShuttle = result?.data?.data?.content?.shuttle_image.map((item) => ({
                images: item,
            }));
            setImgSlidDesc6(imgShuttle);

        } catch (error) {
            console.log('found error', error);
        }
      };
    
      useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang)
      }, [lang])

  return (
            <div className="info-data">
                    <style>
                        {`
                            .promo-item img {
                                width: 100%;
                                height: 450px;
                            }
                        `}
                    </style>
                    <ScrollAnimation animateIn='fadeIn'
                    initiallyVisible={true}>
                    <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                        <div className="col-12 pt-3 px-0">
                            <h1 className='my-4 text-decoration-underline fontBold font-size06'>
                            CARPARK & SECURITY SERVICE
                            </h1>
                        </div>
                    </div>
                    <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                        <div className="col-12 col-md-6">
                            <div className="text-left">
                                <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDesc}}></div></font>
                            </div>
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    initiallyVisible={true}>
                    <div className="row w-100 mx-0 bg-sty01 mt-5 d-flex">
                        <div className="col-12 col-md-6 pe-0 pe-md-2 order-img01">
                            <CardSlider4
                                data={imgSlidDesc1}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInRight" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <p className="font-size04 colorBlack01 fontBold">{t('lprSmart')}</p>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc1}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}>
                    <div className="row w-100 mx-0 mt-6 bg-sty01 d-flex">
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInLeft" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <p className="font-size04 colorBlack01 fontBold">{t('ladyParking')}</p>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc2}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 p-0 mgt-6 order-img02">
                            <CardSlider4
                                data={imgSlidDesc2}
                            />
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}>
                    <div className="row w-100 mx-0 mt-6 bg-sty01 d-flex">
                        <div className="col-12 col-md-6 pe-0 pe-md-2 order-img01">
                            <CardSlider4
                                data={imgSlidDesc3}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInRight" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <p className="font-size04 colorBlack01 fontBold">{t('callPoint')}</p>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc3}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}>
                    <div className="row w-100 mx-0 mt-6 bg-sty01 d-flex">
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInLeft" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <p className="font-size04 colorBlack01 fontBold">{t('golfShuttle')}</p>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc6}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 p-0 mgt-6 order-img02">
                            <CardSlider4
                                data={imgSlidDesc6}
                            />
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}>
                    <div className="row w-100 mx-0 mt-6 bg-sty01 d-flex">
                        <div className="col-12 col-md-6 ps-0 pe-0 pe-md-2 order-img01">
                            <CardSlider4
                                data={imgSlidDesc4}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInRight" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <font className="font-size04 colorBlack01 fontBold">{t('driverRoom')}</font>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc4}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'
                    animatePreScroll={false}>
                    <div className="row w-100 mx-0 mt-6 mb-13 bg-sty01 d-flex">
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02 pb-4 pb-md-0">
                            <ScrollAnimation animateIn="fadeInLeft" delay={100} className="text-left w-75">
                            <div className="text-left w-100">
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-3 mt-lg-0">
                                        <font className="font-size04 colorBlack01 fontBold">{t('facialRecog')}</font>
                                    </div>
                                </div>
                                <div className="row w-100 m-0">
                                    <div className="col-12 mt-0 mt-md-3">
                                        <div className="text-left">
                                            <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtSlidDesc5}}></div></font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 p-0 mgt-6 order-img02">
                            <CardSlider4
                                data={imgSlidDesc5}
                            />
                        </div>
                    </div>
                    </ScrollAnimation>
            </div>
  )
}

export default Carpark;