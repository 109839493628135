import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './AboutUs.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataAbout } from '../../api/about_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { viewportSize } = useMediaQueryHook();
  const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;

  const [txtTitleProfile, setTxtTitleProfile] = useState('');
  const [txtTitleBackground, setTxtTitleBackground] = useState('');
  const [txtTitleVission, setTxtTitleVission] = useState('');
  const [imgBackground, setImgBackground] = useState('');
  const [imgVission, setImgVission] = useState('');
  const [privacyFile, setPrivacyFile] = useState('');
  const { t } = useTranslation();
  const { lang } = useContext(ChangeLanguageContext);

  const [headBanner, setHeadBanner] = useState({
    original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    description1: "ABOUT US",
    isVideo: "image",
  });

  const [showBG, setShowBG] = useState(false);
  const [showVM, setShowVM] = useState(false);

  const handleCloseBG = () => setShowBG(false);
  const handleShowBG = () => setShowBG(true);
  const handleCloseVM = () => setShowVM(false);
  const handleShowVM = () => setShowVM(true);


  const [listDatas, setListDatas] = useState([
    {
      txtTitleList: '',
      txtDescList: '',
      isOpen: false // Initially set to false for all items
    },
    {
      txtTitleList: '',
      txtDescList: '',
      isOpen: false // Initially set to false for all items
    },
  ]);
  const toggleDescription = (index) => {
    const newListDatas = [...listDatas];
    // Update the isOpen property of the item at the specified index
    newListDatas[index] = {
      ...newListDatas[index],
      isOpen: !newListDatas[index].isOpen // Set isOpen to true for the item at the specified index
    };
    setListDatas(newListDatas)
  };

  const fetchingData = async (language) => {
    try {
        const result = await getDataAbout({}, language);
        setTxtTitleProfile(result?.data?.data?.contentdata?.company_profile);
        setTxtTitleBackground(result?.data?.data?.contentdata?.company_background);
        setImgBackground(result?.data?.data?.contentdata?.image_company_background);
        setImgVission(result?.data?.data?.contentdata?.image_vission);
        setTxtTitleVission(result?.data?.data?.contentdata?.company_vission);
        setPrivacyFile(result?.data?.data?.contentdata?.privacy_file);

        const bannerData = result?.data?.data?.banner?.data;
            if (bannerData) {
                const banners = {
                    original: bannerData.data,
                    thumbnail: bannerData.data,
                    isVideo: bannerData.format || "image",
                };
                setHeadBanner(banners);
            } else {
                console.error('Banner data is undefined');
            }

        // Assuming detail_list is an array with at least one element
        const detailList = result?.data?.data?.contentdata?.detail_list;

        if (detailList && detailList.length > 0) {
            const updatedListDatas = detailList.map(detail => ({
                txtTitleList: detail.title,
                txtDescList: detail.description,
                isOpen: false // Assuming you want isOpen to be false for all items
            }));
            setListDatas(updatedListDatas);
        } else {
            // Handle case when detail_list is empty
            // You may want to set an empty array or handle it differently
            setListDatas([]);
        }
    } catch (error) {
        console.log('found error', error);
    }
  };

  useEffect(() => {
    
    fetchingData(lang=='en-US'?'en':lang)
  }, [lang])

  return (
    <div>
      <style>
        {`
            ul.menu li a#about_us {
              color: #2D8929;
              text-decoration: underline;
            }
        `}
      </style>
      <Header />
      <div className="container-fluid w-100">
        <div className="row">
          <div className="col-12 px-0">
            <div className="image-gallery-wrapper">
              <div className="position-relative">
                <img src={headBanner.original} alt="Head Banner" className="banner-title" />
                <div className="txt-banner">
                  <p className="fontBold font-size10 colorWhite mb-0">{t('aboutus_title')}</p>
                  <p className="bd-bottom-banner"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollAnimation animateIn='fadeInUp'
        initiallyVisible={true}>
        <HeadingSection 
          title={t('aboutus_comp_profile_title')} 
          subTitle={t('aboutus_comp_profile_subtitle')} 
          customize={{ paddingTop: '3rem' }}
          classCss="pdt-hd"
          subTitleDetail="d-block"
        />
        <div className="row text-center d-flex justify-content-center align-items-center w-100 mx-0 mb-3">
          <div className="col-12 col-md-6 pt-3 font-size02 fontLight">
            <div dangerouslySetInnerHTML={{ __html: txtTitleProfile}}></div>
          </div>
        </div>
        <div className="mt-4">
          <LandingContent
            image={imgBackground}
            title={t('aboutus_background')} 
            detail={txtTitleBackground}
            ellipsTxt={true}
            onButtonClick={handleShowBG}
          />
          <LandingContent
            image={imgVission}
            title={t('aboutus_vission')} 
            detail={txtTitleVission}            
            alignContentLeft={true}
            ellipsTxt={true}
            onButtonClick={handleShowVM}
          />
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp'
        animatePreScroll={false}>
        <div className="mx-10">
          <HeadingSection title={t('aboutus_privacy_title')}  subTitle={t('aboutus_privacy_subtitle')}
              position={'start'}
              customize={{
                paddingBottom: '2rem',
                paddingTop: '2rem'
              }}
              classCss="pdt-hd pdb-hd"
              subTitleDetail="d-block"
          />
        </div>
        <div className="mx-10 mb-5">
            {listDatas.map((data, index) => (
                <div key={index}>
                    <Row className="list-detail">
                        <Col xs={9} sm={10} md={11}>
                            <font className="font-size02 colorBlack01 fontLight">
                                {index + 1 < 10 ? `0${index + 1}` : index + 1}. 
                                <span className="fontBold font-size05"> {data.txtTitleList}</span>
                            </font>
                        </Col>
                        <Col xs={3} sm={2} md={1} className="d-flex justify-content-end">
                          <div id="toggle-button" className="inner-circle font-size08" onClick={() => toggleDescription(index)}>
                            {data.isOpen ? '-' : '+'}
                          </div>
                        </Col>
                        {data.isOpen && (
                          <Row className="ms-3 w-95 mt-3">
                            <Col xs={12} className="font-size02 fontLight"><div dangerouslySetInnerHTML={{ __html: data.txtDescList}}></div></Col>
                          </Row>
                        )}
                    </Row>
                </div>
            ))}
            <div className="d-flex justify-content-end mt-2">
              <Link as={Link} to={privacyFile} target='_blank' className="font-size05 fontBold">{t('aboutus_privacy_download')}</Link>
            </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp'
        animatePreScroll={false}>
        <div className="sec-bidding">
          <div className="row bg-light-white container-landing-content">
                <div className="col-lg-6 content mx-5 mx-lg-0">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <HeadingSection title={t('aboutus_tendering_title')} subTitle={t('aboutus_tendering_subtile')}
                            position={'start'}
                            customize={{
                              paddingBottom: '2rem',
                              paddingTop: '2rem'
                            }}
                            classCss="pdb-hd"
                            subTitleDetail="d-block"
                        />
                        <div className="txt-detail">
                          <font className="colorBlack fontReg font-size02">If you are interested in joining the tender program in the future, 
                            <br/>Please provide information about your company to us at 
                            <br/><Link as={Link} to="mailto:bidding@asp.th.com" className="colorBlack fontReg font-size02">bidding@asp.th.com</Link>
                          </font>
                        </div>
                        <Link as={Link} to="/bidding" className='text-decoration-none mt-4'>
                          <div className="btn-bidding">
                            <div className='d-flex align-items-center'>
                              <div className='fontMed font-size02'>
                                {t('aboutus_tendering_title')}
                              </div>
                              <div className='mx-2'>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.9678 25.9199C20.1254 25.9199 25.9278 20.1176 25.9278 12.96C25.9278 5.80235 20.1254 0 12.9678 0C5.81016 0 0.0078125 5.80235 0.0078125 12.96C0.0078125 20.1176 5.81016 25.9199 12.9678 25.9199Z" fill="white"/>
                                  <path d="M12.3164 9.01953L16.2564 12.9595L12.3164 16.8994" stroke="#3EA33A" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 p-0">
                    <img src="/assets/images/about/bidding.png" className='img-style-card04' alt="" />
                </div>
                <Modal show={showBG} onHide={handleCloseBG}>
                    <Modal.Body className="p-4">
                      <div className='d-flex flex-column'>
                        <h3 className='mb-0 text-center'>Background</h3>
                        <div className='w-100 d-flex justify-content-center mb-4'>
                          <div className='bd-bottom06'></div>
                        </div>
                        <div className='font-size12 fontLight'>
                          <div dangerouslySetInnerHTML={{ __html: txtTitleBackground}}></div>
                        </div>
                        
                        <div className='w-100 d-flex justify-content-center mt-4'>
                          <button type="button" class="btn-cancel01 btn btn-primary" onClick={handleCloseBG}>CLOSE</button>
                        </div>
                      </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showVM} onHide={handleCloseVM}>
                    <Modal.Body className="p-4">
                    <div className='d-flex flex-column'>
                        <h3 className='mb-0 text-center'>Vision and Mission</h3>
                        <div className='w-100 d-flex justify-content-center mb-4'>
                          <div className='bd-bottom06'></div>
                        </div>
                        <div className='font-size12 fontLight'>
                          <div dangerouslySetInnerHTML={{ __html: txtTitleVission}}></div>
                        </div>
                        <div className='w-100 d-flex justify-content-center mt-4'>
                          <button type="button" class="btn-cancel01 btn btn-primary" onClick={handleCloseVM}>CLOSE</button>
                        </div>
                      </div>
                      </Modal.Body>
                </Modal>
            </div>
        </div>
        </ScrollAnimation>
      </div>
      <div>
        <ScrollToTopButton />
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs;