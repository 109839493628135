import React, { useState, useContext, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import { ChangeLanguageContext } from '../contexts/changeLanguageContext';
import LanguageSwitcher from '../components/changeLanguage/LanguageSwitcher';

function Header() {
    const { lang, changeLanguage } = useContext(ChangeLanguageContext);
    const { t } = useTranslation();

    const [isLinksVisible, setIsLinksVisible] = useState(false);
    const [isSubMenu1Visible, setIsSubMenu1Visible] = useState(false);
    const [isSubMenu2Visible, setIsSubMenu2Visible] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false);

    const toggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };

    const toggleLinks = () => {
        setIsLinksVisible(!isLinksVisible);
    };

    const toggleSubMenu1 = () => {
        setIsSubMenu1Visible(!isSubMenu1Visible);
    };

    const toggleSubMenu2 = () => {
        setIsSubMenu2Visible(!isSubMenu2Visible);
    };

    const [scrolled, setScrolled] = useState(window.scrollY > 20);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id="header" className={scrolled ? 'scrolled' : ''}>
            <nav>
                <div className="container01">
                    <div className="d-desktop-menu">
                        <div className="nav-grid">
                            <div className="logo d-flex align-items-center justify-content-center">
                                <Link as={Link} to="/home" id="home">
                                    <img className="logo-asp" src="assets/images/logo-asp-new.png" alt="Logo" />
                                </Link>
                            </div>
                            <ul className="menu ps-0">
                                <li className="ms-col-1 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/home" id="home">{t('home')}</Link>
                                </li>
                                <li className="ms-col-2">
                                    <div className="subnav">
                                        <button className="subnavbtn fontReg p-0" id="business" type="button">{t('business')}</button>
                                        <div className="subnav-content">
                                            <div className="ms-col-1"></div>
                                            <Link as={Link} to="/offices" className="ms-col-2 pd-t1 fontMed">{t('officeBuilding')}</Link>
                                            <Link as={Link} to="/retailCenter" className="ms-col-3 pd-t1 fontMed">{t('retailCenter')}</Link>
                                            <Link as={Link} to="/condominium" className="ms-col-4 pd-t1 fontMed">{t('condo')}</Link>
                                            <Link as={Link} to="https://www.hilton.com/en/hotels/bkkcici-conrad-bangkok/" target='_blank' className="ms-col-5 pd-t1 fontMed">{t('conrad')}</Link>
                                            <div className="ms-col-6"></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="ms-col-3 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/ourServices" id="services">{t('services_privileges')}</Link>
                                </li>
                                <li className="ms-col-4 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/newsEvent" id="news_event">{t('news_event')}</Link>
                                </li>
                                <li className="ms-col-5 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/careers" id="careers">{t('careers')}</Link>
                                </li>
                                <li className="ms-col-6 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/aboutUs" id="about_us">{t('about_us')}</Link>
                                </li>
                                <li className="ms-col-7 d-flex align-items-center justify-content-center fontReg">
                                    <Link as={Link} to="/contactUs" id="contact_us">{t('contact_us')}</Link>
                                </li>
                            </ul>
                            <div className="d-flex flex-row">
                                <div className="d-flex align-items-center me-0 me-lg-1 me-xl-2">
                                    <img className="stl-ic-tel" src="assets/images/ic-tel.png" alt="Telephone" />
                                </div>
                                <div className="d-flex flex-column align-items-start colorBlack">
                                    <span className="fontReg font-size03">{t('cust_care')}</span>
                                    <span className="fontReg font-size02">66 (0) 2625-1800</span>
                                </div>
                            </div>
                            <LanguageSwitcher />
                        </div>
                    </div>
                    <div className="d-mobile-menu">
                        <div className="topnav">
                            <div className="topnav-grid">
                                <Link as={Link} to="/home" className="active">
                                    <img className="logo-asp" src="assets/images/logo-asp-new.png" alt="Logo" />
                                </Link>
                                <LanguageSwitcher />
                            </div>
                            <Link href="#" className="icon" onClick={toggleContent}>
                                <i className="fa fa-bars"></i>
                            </Link>
                            {isContentVisible && (
                                <nav id="myLinks">
                                    <div className="primary-nav nav-slidedown">
                                        <ul className="menu-mobile">
                                            <li><Link as={Link} to="/home">{t('home')}</Link></li>
                                            <li onClick={toggleSubMenu1}>
                                                <div id="expand_stat1" className="expand_stat expand">+</div>
                                                <span>{t('business')}</span>
                                            </li>
                                            <ul id="subMenu" style={{ display: isSubMenu1Visible ? 'block' : 'none' }}>
                                                <li><Link as={Link} to="/offices" className="fontReg">{t('officeBuilding')}</Link></li>
                                                <li><Link as={Link} to="/retailCenter" className="fontReg">{t('retailCenter')}</Link></li>
                                                <li><Link as={Link} to="/condominium" className="fontReg">{t('condo')}</Link></li>
                                                <li><Link as={Link} to="https://www.hilton.com/en/hotels/bkkcici-conrad-bangkok/" target='_blank' className="fontReg">{t('conrad')}</Link></li>
                                            </ul>
                                            <li><Link as={Link} to="/ourServices">{t('services_privileges')}</Link></li>
                                            <li><Link as={Link} to="/newsEvent">{t('news_event')}</Link></li>
                                            <li><Link as={Link} to="/careers">{t('careers')}</Link></li>
                                            <li><Link as={Link} to="/aboutUs">{t('about_us')}</Link></li>
                                            <li><Link as={Link} to="/contactUs">{t('contact_us')}</Link></li>
                                        </ul>
                                    </div>
                                </nav>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;