export const DataDropdownTest = [
  { value: 1, text: "Office Building" },
  { value: 2, text: "Retail Center" },
  { value: 3, text: "Condominium" },
  { value: 4, text: "Conrad Bangkok" },
  { value: 5, text: "Contact Center" }
];

export const DataDropdownDegree = [
  { value: "Lower than High School", text: "Lower than High School" },
  { value: "Junior High School", text: "Junior High School" },
  { value: "Senior High School", text: "Senior High School" },
  { value: "Vocational certificate", text: "Vocational certificate" },
  { value: "Technical certificate", text: "Technical certificate" },
  { value: "High vocational certificate", text: "High vocational certificate" },
  { value: "Diploma", text: "Diploma" },
  { value: "Bachelor’s Degree", text: "Bachelor’s Degree" },
  { value: "Master’s Degree", text: "Master’s Degree" },
  { value: "Doctoral Degree", text: "Doctoral Degree" },
];

export const DataDropdownDegreeTH = [
  { value: "ต่ำกว่าระดับมัธยมศึกษาตอนต้น", text: "ต่ำกว่าระดับมัธยมศึกษาตอนต้น" },
  { value: "ระดับมัธยมศึกษาตอนต้น", text: "ระดับมัธยมศึกษาตอนต้น" },
  { value: "ระดับมัธยมศึกษาตอนปลาย", text: "ระดับมัธยมศึกษาตอนปลาย" },
  { value: "ระดับประกาศนียบัตรวิชาชีพ", text: "ระดับประกาศนียบัตรวิชาชีพ" },
  { value: "ระดับประกาศนียบัตรวิชาชีพเทคนิค", text: "ระดับประกาศนียบัตรวิชาชีพเทคนิค" },
  { value: "ระดับประกาศนียบัตรวิชาชีพชั้นสูง", text: "ระดับประกาศนียบัตรวิชาชีพชั้นสูง" },
  { value: "ระดับประกาศนียบัตร", text: "ระดับประกาศนียบัตร" },
  { value: "ระดับปริญญาตรี", text: "ระดับปริญญาตรี" },
  { value: "ระดับปริญญาโท", text: "ระดับปริญญาโท" },
  { value: "ระดับปริญญาเอก", text: "ระดับปริญญาเอก" },
];