import React from 'react'
import "./headingSection.css"
const HeadingSection = React.memo(({ title, subTitle, customize, classCss, position, subTitleDetail, titleBorder = false, subTitleBorder = true }) => {
    return (
        <div className={`col-12 ${classCss} d-flex main-heading justify-content-center align-items-${position ?? 'center'} flex-column`}
        >
            <h1 className={`mb-0 font-size10 fontBold ${titleBorder ? 'title-border ' :''}`}>
                {title}
            </h1>
            <font className={`m-0 font-size13 fontUlLight ${subTitleBorder ? 'title-border ': ''} ${subTitleDetail}`}>
                {subTitle}
            </font>
        </div>
    )
})
export default HeadingSection;