import React, { createContext, useEffect, useState } from 'react';
import { fetchConfig } from '../api/config_api';

export const ConfigContext = createContext();

export const ConfigContextProvider = ({ children }) => {
    
    const [config, setConfig] = useState({})


    const getConfig = async () => {
        const response = await fetchConfig()
        if(response.status == 200) {
            setConfig(response?.data?.data)
            

        }
    };

    useEffect(() => {
        getConfig()
    }, [])

    return (
        <ConfigContext.Provider value={{config, getConfig}}>
            {children}
        </ConfigContext.Provider>
    );
};
